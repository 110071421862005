import { createContext, useState } from "react";
import { jsonConfig } from '../Config';

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {

    const [signed, setSigned] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isEdit, setIsEdit] = useState(false)

    const [email, setEmail] = useState('')
    const [senha, setSenha] = useState('')

    const [modalMsg, setModalMsg] = useState(false)
    const [msgModalMsg, setMsgModalMsg] = useState('')
    const [descModalMsg, setDescModalMsg] = useState('')
    const [titleButtonModalMsg, setTitleButtonModalMsg] = useState('Retornar')
    const [titleButtonCloseModalMsg, setTitleButtonCloseModalMsg] = useState('Fechar')
    const [perigoModalMsg, setPerigoModalMsg] = useState(false)
    const [functionModalMsg, setFunctionModalMsg] = useState(() => { })

    const [sideBar, setSideBar] = useState(false)

    const [dataEstatisticaPlanos, setDataEstatisticaPlanos] = useState([])
    const [dataPlanos, setDataPlanos] = useState([])
    const [Planos, setPlanos] = useState({})
    const [offsetPlanos, setOffsetPlanos] = useState(0)
    const [limitePlanos, setLimitePlanos] = useState(jsonConfig.limitDefault)
    const [limiteBuscaPlanos, setLimiteBuscaPlanos] = useState(false)
    const [modalPlanos, setModalPlanos] = useState(false)
    const [modalPlanosShow, setModalPlanosShow] = useState(false)

    const [dataEstatisticaAssinaturas, setDataEstatisticaAssinaturas] = useState([])
    const [dataAssinaturas, setDataAssinaturas] = useState([])
    const [Assinaturas, setAssinaturas] = useState({})
    const [offsetAssinaturas, setOffsetAssinaturas] = useState(0)
    const [limiteAssinaturas, setLimiteAssinaturas] = useState(jsonConfig.limitDefault)
    const [limiteBuscaAssinaturas, setLimiteBuscaAssinaturas] = useState(false)
    const [modalAssinaturas, setModalAssinaturas] = useState(false)
    const [modalAssinaturasShow, setModalAssinaturasShow] = useState(false)

    const [dataEstatisticaRecuperacaoSenha, setDataEstatisticaRecuperacaoSenha] = useState([])
    const [dataRecuperacaoSenha, setDataRecuperacaoSenha] = useState([])
    const [RecuperacaoSenha, setRecuperacaoSenha] = useState({})
    const [offsetRecuperacaoSenha, setOffsetRecuperacaoSenha] = useState(0)
    const [limiteRecuperacaoSenha, setLimiteRecuperacaoSenha] = useState(jsonConfig.limitDefault)
    const [limiteBuscaRecuperacaoSenha, setLimiteBuscaRecuperacaoSenha] = useState(false)

    const [dataEstatisticaUsuarios, setDataEstatisticaUsuarios] = useState([])
    const [dataUsuarios, setDataUsuarios] = useState([])
    const [Usuarios, setUsuarios] = useState({})
    const [offsetUsuarios, setOffsetUsuarios] = useState(0)
    const [limiteUsuarios, setLimiteUsuarios] = useState(jsonConfig.limitDefault)
    const [limiteBuscaUsuarios, setLimiteBuscaUsuarios] = useState(false)
    const [modalUsuarios, setModalUsuarios] = useState(false)

    const [dataEstatisticaLogs, setDataEstatisticaLogs] = useState([])
    const [dataLogs, setDataLogs] = useState([])
    const [Logs, setLogs] = useState({})
    const [offsetLogs, setOffsetLogs] = useState(0)
    const [limiteLogs, setLimiteLogs] = useState(jsonConfig.limitDefault)
    const [limiteBuscaLogs, setLimiteBuscaLogs] = useState(false)
    const [modalLogs, setModalLogs] = useState(false)

    const [dataEstatisticaCrons, setDataEstatisticaCrons] = useState([])
    const [dataCrons, setDataCrons] = useState([])
    const [Crons, setCrons] = useState({})
    const [offsetCrons, setOffsetCrons] = useState(0)
    const [limiteCrons, setLimiteCrons] = useState(jsonConfig.limitDefault)
    const [limiteBuscaCrons, setLimiteBuscaCrons] = useState(false)
    const [modalCrons, setModalCrons] = useState(false)

    const [dataEstatisticaFaturas, setDataEstatisticaFaturas] = useState([])
    const [dataFaturas, setDataFaturas] = useState([])
    const [Faturas, setFaturas] = useState({})
    const [offsetFaturas, setOffsetFaturas] = useState(0)
    const [limiteFaturas, setLimiteFaturas] = useState(jsonConfig.limitDefault)
    const [limiteBuscaFaturas, setLimiteBuscaFaturas] = useState(false)
    const [modalFaturas, setModalFaturas] = useState(false)
    const [modalShowFaturas, setModalShowFaturas] = useState(false)
    const [modalDateFaturas, setModalDateFaturas] = useState(false)
    const [modalBaixaFaturas, setModalBaixaFaturas] = useState(false)

    const [dataEstatisticaClientes, setDataEstatisticaClientes] = useState([])
    const [dataClientes, setDataClientes] = useState([])
    const [Clientes, setClientes] = useState({})
    const [offsetClientes, setOffsetClientes] = useState(0)
    const [limiteClientes, setLimiteClientes] = useState(jsonConfig.limitDefault)
    const [limiteBuscaClientes, setLimiteBuscaClientes] = useState(false)
    const [modalClientes, setModalClientes] = useState(false)
    const [modalClientesShow, setModalClientesShow] = useState(false)

    const [dataEstatisticaPainelCliente, setDataEstatisticaPainelCliente] = useState([])


    const [dataEstatisticaUsuariosCliente, setDataEstatisticaUsuariosCliente] = useState([])
    const [dataUsuariosCliente, setDataUsuariosCliente] = useState([])
    const [UsuariosCliente, setUsuariosCliente] = useState({})
    const [offsetUsuariosCliente, setOffsetUsuariosCliente] = useState(0)
    const [limiteUsuariosCliente, setLimiteUsuariosCliente] = useState(jsonConfig.limitDefault)
    const [limiteBuscaUsuariosCliente, setLimiteBuscaUsuariosCliente] = useState(false)


    const [dataEstatisticaRecuperacaoSenhaCliente, setDataEstatisticaRecuperacaoSenhaCliente] = useState([])
    const [dataRecuperacaoSenhaCliente, setDataRecuperacaoSenhaCliente] = useState([])
    const [RecuperacaoSenhaCliente, setRecuperacaoSenhaCliente] = useState({})
    const [offsetRecuperacaoSenhaCliente, setOffsetRecuperacaoSenhaCliente] = useState(0)
    const [limiteRecuperacaoSenhaCliente, setLimiteRecuperacaoSenhaCliente] = useState(jsonConfig.limitDefault)
    const [limiteBuscaRecuperacaoSenhaCliente, setLimiteBuscaRecuperacaoSenhaCliente] = useState(false)

    const [dataEstatisticaServicosCliente, setDataEstatisticaServicosCliente] = useState([])
    const [dataServicosCliente, setDataServicosCliente] = useState([])
    const [ServicosCliente, setServicosCliente] = useState({})
    const [offsetServicosCliente, setOffsetServicosCliente] = useState(0)
    const [limiteServicosCliente, setLimiteServicosCliente] = useState(jsonConfig.limitDefault)
    const [limiteBuscaServicosCliente, setLimiteBuscaServicosCliente] = useState(false)

    const [dataEstatisticaCategoriasServicosCliente, setDataEstatisticaCategoriasServicosCliente] = useState([])
    const [dataCategoriasServicosCliente, setDataCategoriasServicosCliente] = useState([])
    const [CategoriasServicosCliente, setCategoriasServicosCliente] = useState({})
    const [offsetCategoriasServicosCliente, setOffsetCategoriasServicosCliente] = useState(0)
    const [limiteCategoriasServicosCliente, setLimiteCategoriasServicosCliente] = useState(jsonConfig.limitDefault)
    const [limiteBuscaCategoriasServicosCliente, setLimiteBuscaCategoriasServicosCliente] = useState(false)

    const [configuracaoCliente, setConfiguracaoCliente] = useState({})
    const [configuracaoContabilidadeCliente, setConfiguracaoContabilidadeCliente] = useState({})

    const [dataEstatisticaPrestadoresCliente, setDataEstatisticaPrestadoresCliente] = useState([])
    const [dataPrestadoresCliente, setDataPrestadoresCliente] = useState([])
    const [PrestadoresCliente, setPrestadoresCliente] = useState({})
    const [offsetPrestadoresCliente, setOffsetPrestadoresCliente] = useState(0)
    const [limitePrestadoresCliente, setLimitePrestadoresCliente] = useState(jsonConfig.limitDefault)
    const [limiteBuscaPrestadoresCliente, setLimiteBuscaPrestadoresCliente] = useState(false)

    const [dataEstatisticaComprovantesClientes, setDataEstatisticaComprovantesClientes] = useState([])
    const [dataComprovantesClientes, setDataComprovantesClientes] = useState([])
    const [ComprovantesClientes, setComprovantesClientes] = useState({})
    const [offsetComprovantesClientes, setOffsetComprovantesClientes] = useState(0)
    const [limiteComprovantesClientes, setLimiteComprovantesClientes] = useState(jsonConfig.limitDefault)
    const [limiteBuscaComprovantesClientes, setLimiteBuscaComprovantesClientes] = useState(false)

    const [dataEstatisticaCarneLeaoCliente, setDataEstatisticaCarneLeaoCliente] = useState([])
    const [dataCarneLeaoCliente, setDataCarneLeaoCliente] = useState([])
    const [CarneLeaoCliente, setCarneLeaoCliente] = useState({})
    const [offsetCarneLeaoCliente, setOffsetCarneLeaoCliente] = useState(0)
    const [limiteCarneLeaoCliente, setLimiteCarneLeaoCliente] = useState(jsonConfig.limitDefault)
    const [limiteBuscaCarneLeaoCliente, setLimiteBuscaCarneLeaoCliente] = useState(false)

    const [dataEstatisticaFaturasCliente, setDataEstatisticaFaturasCliente] = useState([])
    const [dataFaturasCliente, setDataFaturasCliente] = useState([])
    const [FaturasCliente, setFaturasCliente] = useState({})
    const [offsetFaturasCliente, setOffsetFaturasCliente] = useState(0)
    const [limiteFaturasCliente, setLimiteFaturasCliente] = useState(jsonConfig.limitDefault)
    const [limiteBuscaFaturasCliente, setLimiteBuscaFaturasCliente] = useState(false)

    const [dataEstatisticaDashBoard, setDataEstatisticaDashBoard] = useState([])


    const states = {
        dataEstatisticaDashBoard,
        dataEstatisticaFaturasCliente,
        dataFaturasCliente,
        FaturasCliente,
        offsetFaturasCliente,
        limiteFaturasCliente,
        limiteBuscaFaturasCliente,
        dataEstatisticaCarneLeaoCliente,
        dataCarneLeaoCliente,
        CarneLeaoCliente,
        offsetCarneLeaoCliente,
        limiteCarneLeaoCliente,
        limiteBuscaCarneLeaoCliente,
        configuracaoContabilidadeCliente,
        dataEstatisticaComprovantesClientes,
        dataComprovantesClientes,
        ComprovantesClientes,
        offsetComprovantesClientes,
        limiteComprovantesClientes,
        limiteBuscaComprovantesClientes,
        dataEstatisticaPrestadoresCliente,
        dataPrestadoresCliente,
        PrestadoresCliente,
        offsetPrestadoresCliente,
        limitePrestadoresCliente,
        limiteBuscaPrestadoresCliente,
        configuracaoCliente,
        dataEstatisticaCategoriasServicosCliente,
        dataCategoriasServicosCliente,
        CategoriasServicosCliente,
        offsetCategoriasServicosCliente,
        limiteCategoriasServicosCliente,
        limiteBuscaCategoriasServicosCliente,
        dataEstatisticaServicosCliente,
        dataServicosCliente,
        ServicosCliente,
        offsetServicosCliente,
        limiteServicosCliente,
        limiteBuscaServicosCliente,
        dataEstatisticaRecuperacaoSenhaCliente,
        dataRecuperacaoSenhaCliente,
        RecuperacaoSenhaCliente,
        offsetRecuperacaoSenhaCliente,
        limiteRecuperacaoSenhaCliente,
        limiteBuscaRecuperacaoSenhaCliente,
        dataEstatisticaUsuariosCliente,
        dataUsuariosCliente,
        UsuariosCliente,
        offsetUsuariosCliente,
        limiteUsuariosCliente,
        limiteBuscaUsuariosCliente,
        dataEstatisticaPainelCliente,
        modalClientesShow,
        modalBaixaFaturas,
        modalDateFaturas,
        dataEstatisticaClientes,
        dataClientes,
        Clientes,
        offsetClientes,
        limiteClientes,
        limiteBuscaClientes,
        modalClientes,
        dataEstatisticaFaturas,
        dataFaturas,
        Faturas,
        offsetFaturas,
        limiteFaturas,
        limiteBuscaFaturas,
        modalFaturas,
        modalShowFaturas,
        dataEstatisticaLogs,
        dataLogs,
        Logs,
        offsetLogs,
        limiteLogs,
        limiteBuscaLogs,
        modalLogs,
        dataEstatisticaCrons,
        dataCrons,
        Crons,
        offsetCrons,
        limiteCrons,
        limiteBuscaCrons,
        modalCrons,
        dataEstatisticaUsuarios,
        dataUsuarios,
        Usuarios,
        offsetUsuarios,
        limiteUsuarios,
        limiteBuscaUsuarios,
        modalUsuarios,
        dataEstatisticaRecuperacaoSenha,
        dataRecuperacaoSenha,
        RecuperacaoSenha,
        offsetRecuperacaoSenha,
        limiteRecuperacaoSenha,
        limiteBuscaRecuperacaoSenha,
        dataEstatisticaAssinaturas,
        dataAssinaturas,
        Assinaturas,
        offsetAssinaturas,
        limiteAssinaturas,
        limiteBuscaAssinaturas,
        modalAssinaturasShow,
        modalAssinaturas,
        modalPlanosShow,
        modalPlanos,
        dataEstatisticaPlanos,
        dataPlanos,
        Planos,
        offsetPlanos,
        limitePlanos,
        limiteBuscaPlanos,
        signed,
        isLoading,
        email,
        senha,
        modalMsg,
        msgModalMsg,
        descModalMsg,
        perigoModalMsg,
        titleButtonModalMsg,
        functionModalMsg,
        titleButtonCloseModalMsg,
        sideBar,
        isEdit
    }

    const setters = {
        setDataEstatisticaDashBoard,
        setDataEstatisticaFaturasCliente,
        setDataFaturasCliente,
        setFaturasCliente,
        setOffsetFaturasCliente,
        setLimiteFaturasCliente,
        setLimiteBuscaFaturasCliente,
        setDataEstatisticaCarneLeaoCliente,
        setDataCarneLeaoCliente,
        setCarneLeaoCliente,
        setOffsetCarneLeaoCliente,
        setLimiteCarneLeaoCliente,
        setLimiteBuscaCarneLeaoCliente,
        setConfiguracaoContabilidadeCliente,
        setDataEstatisticaComprovantesClientes,
        setDataComprovantesClientes,
        setComprovantesClientes,
        setOffsetComprovantesClientes,
        setLimiteComprovantesClientes,
        setLimiteBuscaComprovantesClientes,
        setDataEstatisticaPrestadoresCliente,
        setDataPrestadoresCliente,
        setPrestadoresCliente,
        setOffsetPrestadoresCliente,
        setLimitePrestadoresCliente,
        setLimiteBuscaPrestadoresCliente,
        setConfiguracaoCliente,
        setDataEstatisticaCategoriasServicosCliente,
        setDataCategoriasServicosCliente,
        setCategoriasServicosCliente,
        setOffsetCategoriasServicosCliente,
        setLimiteCategoriasServicosCliente,
        setLimiteBuscaCategoriasServicosCliente,
        setDataEstatisticaServicosCliente,
        setDataServicosCliente,
        setServicosCliente,
        setOffsetServicosCliente,
        setLimiteServicosCliente,
        setLimiteBuscaServicosCliente,
        setDataEstatisticaRecuperacaoSenhaCliente,
        setDataRecuperacaoSenhaCliente,
        setRecuperacaoSenhaCliente,
        setOffsetRecuperacaoSenhaCliente,
        setLimiteRecuperacaoSenhaCliente,
        setLimiteBuscaRecuperacaoSenhaCliente,
        setDataEstatisticaUsuariosCliente,
        setDataUsuariosCliente,
        setUsuariosCliente,
        setOffsetUsuariosCliente,
        setLimiteUsuariosCliente,
        setLimiteBuscaUsuariosCliente,
        setDataEstatisticaPainelCliente,
        setModalClientesShow,
        setModalBaixaFaturas,
        setModalDateFaturas,
        setDataEstatisticaClientes,
        setDataClientes,
        setClientes,
        setOffsetClientes,
        setLimiteClientes,
        setLimiteBuscaClientes,
        setModalClientes,
        setDataEstatisticaFaturas,
        setDataFaturas,
        setFaturas,
        setOffsetFaturas,
        setLimiteFaturas,
        setLimiteBuscaFaturas,
        setModalFaturas,
        setModalShowFaturas,
        setDataEstatisticaLogs,
        setDataLogs,
        setLogs,
        setOffsetLogs,
        setLimiteLogs,
        setLimiteBuscaLogs,
        setModalLogs,
        setDataEstatisticaCrons,
        setDataCrons,
        setCrons,
        setOffsetCrons,
        setLimiteCrons,
        setLimiteBuscaCrons,
        setModalCrons,
        setDataEstatisticaUsuarios,
        setDataUsuarios,
        setUsuarios,
        setOffsetUsuarios,
        setLimiteUsuarios,
        setLimiteBuscaUsuarios,
        setModalUsuarios,
        setDataEstatisticaRecuperacaoSenha,
        setDataRecuperacaoSenha,
        setRecuperacaoSenha,
        setOffsetRecuperacaoSenha,
        setLimiteRecuperacaoSenha,
        setLimiteBuscaRecuperacaoSenha,
        setDataEstatisticaAssinaturas,
        setDataAssinaturas,
        setAssinaturas,
        setOffsetAssinaturas,
        setLimiteAssinaturas,
        setLimiteBuscaAssinaturas,
        setModalAssinaturasShow,
        setModalAssinaturas,
        setModalPlanosShow,
        setModalPlanos,
        setDataEstatisticaPlanos,
        setDataPlanos,
        setPlanos,
        setOffsetPlanos,
        setLimitePlanos,
        setLimiteBuscaPlanos,
        setSigned,
        setIsLoading,
        setEmail,
        setSenha,
        setModalMsg,
        setMsgModalMsg,
        setDescModalMsg,
        setPerigoModalMsg,
        setTitleButtonModalMsg,
        setFunctionModalMsg,
        setTitleButtonCloseModalMsg,
        setSideBar,
        setIsEdit
    }




    return (
        <AuthContext.Provider
            value={{
                states,
                setters
            }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthProvider;
