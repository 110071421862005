import { useContext, useEffect } from 'react';
import { AuthContext } from '../../Context';
import { AuthContextApi } from '../../Context/api';
import { Link } from "react-router-dom";
import {  CommandLineIcon, ExclamationTriangleIcon} from '@heroicons/react/24/outline'


const UsuariosIndex = () => {
    const { states, setters } = useContext(AuthContext)
    const { checkLogin } = useContext(AuthContextApi)




    const primeiroLoad = async () => {
        await checkLogin()
    }

    useEffect(() => {
        primeiroLoad()

        return () => {
            primeiroLoad()
        }

    }, [])


    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <div className="mb-4 border flex-col flex w-full md:w-[100%] items-center justify-center p-4 border border-1 border-gray-200 rounded-md overflow-hidden bg-white">
                <h1 className="text-3xl text-center font-bold">Painel Técnico</h1>
                <p className="text-[18px] mt-1 text-center">Aqui você terá acesso as áreas de técnica do sistema</p>
            </div>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-2">
                <div className="flex items-center justify-center">
                    <Link to="/tecnicoIndex/logs" className="w-full h-[100%] text-black border p-4 flex items-center justify-center flex-col border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                        <ExclamationTriangleIcon className="h-[30%] w-[30%] text-blue-600" />
                        <h2 className="text-xl font-bold">Logs</h2>
                        <p className="text-center">Clique nesse cartão para acessar os logs do sistema</p>
                    </Link>
                </div>
                <div className="flex items-center justify-center">
                    <Link to="/tecnicoIndex/cron" className="w-full h-[100%] text-black border p-4 flex items-center justify-center flex-col border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                        <CommandLineIcon className="h-[30%] w-[30%] text-blue-600" />
                        <h2 className="text-xl font-bold">Cron</h2>
                        <p className="text-center">Clique nesse cartão para acessar o painel de crons do sistema</p>
                    </Link>
                </div>
            </div>
        </div>
    )

}



export default UsuariosIndex;