import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../../Context';
import { AuthContextApi } from '../../../../Context/api';
import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment'


const ConfiguracaoContabilidade = () => {

    const { states, setters } = useContext(AuthContext)
    const { checkLogin,
        getConfiguracaoCliente,
        getConfiguracaoContabilidadeCliente,
        getClienteById
    } = useContext(AuthContextApi)
    const navigate = useNavigate()
    const { id } = useParams()



    const primeiroLoad = async () => {
        if (id === undefined) {
            navigate(-1)
            return;
        }
        await getConfiguracaoContabilidadeCliente(id)
        await getConfiguracaoCliente(id)
        await checkLogin()
        await getClienteById(id)
    }


    const formatToCpfOrCnpj = (value) => {
        if (value?.length === 11) {
            return value?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
        } else {
            return value?.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
        }
    }

    const formatToPhoneBrl = (value) => {
        return value?.replace(/^(\d{2})(\d{5})(\d{4})/, '($1) $2-$3')
    }


    useEffect(() => {
        primeiroLoad()

        return () => {
            setters.setConfiguracaoContabilidadeCliente({})

        }

    }, [])

    return (
        <>
            <div className="flex flex-col">

                <div className="flex p-4 mb-4  border  hover:shadow-md">
                    <div className='flex flex items-center justify-between'>
                        <button onClick={() => navigate(`/clientes/cliente/${id}`)} className="flex h-[50%] items-center jutify-center text-sm font-medium hover:shadow text-blue-500 hover:text-blue-700 border p-2 rounded-[15px]">
                            <ArrowLeftIcon className="w-5 h-5 mr-2" aria-hidden="true" />
                        </button>
                    </div>
                    <div className='mt-5 ml-5'>
                        <h1 className="text-base font-semibold leading-6 text-gray-900">Cliente</h1>
                        <p className="mt-2 text-sm text-gray-700">
                            {states.Clientes.nome} - {formatToCpfOrCnpj(states.Clientes.cpf_cnpj)} - {states.Clientes.email}
                        </p>
                    </div>
                </div>

                <div className="flex-1 p-4 border  hover:shadow-md">
                    <h1 className="text-base font-semibold leading-6 text-gray-900">Configuração de contabilidade do cliente</h1>
                    <p className="mt-2 text-sm text-gray-700">
                        Informações sobre a configuração de contabilidade do cliente: {states.Clientes.nome}
                    </p>
                </div>
                {
                    Object.keys(states.configuracaoContabilidadeCliente).length > 0 ? (
                        <>
                            <div className="flex flex-col sm:flex-row  p-4 border hover:shadow-md mt-5">
                                {/* corPrimaria, corSecundaria, email, facebook, instagram, wpp, horarioAbertura, horarioFechamento */}
                                Informacoes de contabilidade:
                                <div className='flex sm:flex-row flex-col items-between justify-between w-full'>
                                    <div className='flex flex-col mt-2'>
                                        <h1 className="text-base font-semibold leading-6 text-gray-900">Nome da empresa</h1>
                                        <div className='flex items-center'>
                                            <p className="mt-2 text-sm text-gray-700">
                                                {states.configuracaoContabilidadeCliente.nome_empresa}
                                            </p>
                                        </div>
                                    </div>
                                    <div className='flex flex-col mt-2'>
                                        <h1 className="text-base font-semibold leading-6 text-gray-900">Data de criação</h1>
                                        <div className='flex items-center'>
                                            <p className="mt-2 text-sm text-gray-700">
                                                {moment(states.configuracaoContabilidadeCliente.data_criacao).format('DD/MM/YYYY')}
                                            </p>
                                        </div>
                                    </div>
                                    <div className='flex flex-col mt-2'>
                                        <h1 className="text-base font-semibold leading-6 text-gray-900">Data de atualização</h1>
                                        <div className='flex items-center'>
                                            <p className="mt-2 text-sm text-gray-700">
                                                {moment(states.configuracaoContabilidadeCliente.data_atualizacao).format('DD/MM/YYYY')}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="flex-1 p-12 border  hover:shadow-md mt-5">
                            <h1 className="text-base font-semibold leading-6 text-gray-900">Sem configuração cadastrada</h1>
                        </div>
                    )
                }
            </div>
        </>
    )

}



export default ConfiguracaoContabilidade;