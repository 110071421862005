import { Navigate, useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import { AuthContext } from "../Context";
import { AuthContextApi } from "../Context/api";
import Sidebar from "../Components/SideBar";
import Navbar from "../Components/NavBar";
import Footer from "../Components/Footer";

const RouterWrapper = ({ component: Component, isPrivate }) => {
    const { states, setters } = useContext(AuthContext)
    const { checkLogin } = useContext(AuthContextApi)
    const navigate = useNavigate()


    const isSigned = async () => {
        const token = localStorage.getItem('token')
        if (token === null) {
            setters.setSigned(false)
        } else {
            const response = await checkLogin()
            if (response === true) {
                setters.setSigned(true)
            } else {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                navigate('/plataforma')
            }

        }
    }

    useEffect(() => {
        isSigned()
    }, [localStorage.getItem('token')])


    if (isPrivate) {
        if (states.signed) {
            return (
                <>
                    <Sidebar />
                    <Navbar />
                    <div className="flex flex-1 flex-col pb-16 bg-white">
                        <div className='flex flex-1 flex-col md:pl-64 mb-4'>
                            <div className="p-4">
                                <Component />
                            </div>
                        </div>
                    <Footer />
                    </div>
                </>
            )
        } else {
            if (localStorage.getItem('token') === null) {
                return (
                    <Navigate to='/plataforma' />
                )
            } else {
                // isSigned()
                return (
                    <>
                        <Sidebar />
                        <Navbar />
                        <div className="flex flex-1 flex-col pb-16 bg-white">
                            <div className='flex flex-1 flex-col md:pl-64 mb-4'>
                                <div className="p-4">
                                    <Component />
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </>
                )
            }
        }
    }

    if (isPrivate && !states.signed) {
        return (
            <Navigate to='/plataforma' />
        )
    }

    if (isPrivate && states.signed) {
        return (
            <Navigate to='/dashboard' />
        )
    }

    if (!isPrivate) {
        return (
            <Component />
        )
    }
    return (
        <Component />
    )



}

export default RouterWrapper;