import { useContext, useEffect } from 'react';
import { AuthContext } from '../../../../Context';
import { AuthContextApi } from '../../../../Context/api';
import { Link } from "react-router-dom";
import { UserGroupIcon, ArrowPathIcon, ArrowLeftIcon } from '@heroicons/react/24/outline'
import { useParams, useNavigate } from 'react-router-dom';


const UsuariosIndex = () => {
    const { states, setters } = useContext(AuthContext)
    const { checkLogin, getClienteById } = useContext(AuthContextApi)
    const navigate = useNavigate()
    const { id } = useParams()




    const primeiroLoad = async () => {
        if (id === undefined) {
            navigate(-1)
            return;
        }
        await getClienteById(id)
        await checkLogin()
    }

    const formatToCpfOrCnpj = (value) => {
        if (value?.length === 11) {
            return value?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
        } else {
            return value?.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
        }
    }

    useEffect(() => {
        primeiroLoad()

        return () => {
            primeiroLoad()
        }

    }, [])


    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <div className="flex p-4  border  hover:shadow-md">
                <div className='flex flex items-center justify-between'>
                    <button onClick={() => navigate(`/clientes/cliente/${id}`)} className="flex h-[50%] items-center jutify-center text-sm font-medium hover:shadow text-blue-500 hover:text-blue-700 border p-2 rounded-[15px]">
                        <ArrowLeftIcon className="w-5 h-5 mr-2" aria-hidden="true" />
                    </button>
                </div>
                <div className='mt-5 ml-5'>
                    <h1 className="text-base font-semibold leading-6 text-gray-900">Cliente</h1>
                    <p className="mt-2 text-sm text-gray-700">
                        {states.Clientes.nome} - {formatToCpfOrCnpj(states.Clientes.cpf_cnpj)} - {states.Clientes.email}
                    </p>
                </div>
            </div>

            <div className="mb-4 mt-8 border flex-col flex w-full md:w-[100%] items-center justify-center p-4 border border-1 border-gray-200 rounded-md overflow-hidden bg-white">
                <h1 className="text-3xl text-center font-bold">Painel Usuários</h1>
                <p className="text-[18px] mt-1 text-center">Aqui você terá acesso as áreas de usuários do cliente {states.Clientes.nome}</p>
            </div>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-2">
                <div className="flex items-center justify-center">
                    <Link to={`/clientes/cliente/${id}/usuarios/usuarios`} className="w-full h-[100%] text-black border p-4 flex items-center justify-center flex-col border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                        <UserGroupIcon className="h-[30%] w-[30%] text-blue-600" />
                        <h2 className="text-xl font-bold">Usuários</h2>
                        <p className="text-center">Clique nesse cartão para acessar os usuários.</p>
                    </Link>
                </div>
                <div className="flex items-center justify-center">
                    <Link to={`/clientes/cliente/${id}/usuarios/recuperacaoDeSenha`} className="w-full h-[100%] text-black border p-4 flex items-center justify-center flex-col border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                        <ArrowPathIcon className="h-[30%] w-[30%] text-blue-600" />
                        <h2 className="text-xl font-bold">Solicitações de recuperação de senha</h2>
                        <p className="text-center">Clique nesse cartão para acessar o painel de recuperação de senha</p>
                    </Link>
                </div>
            </div>
        </div>
    )

}



export default UsuariosIndex;