
import { AuthContext } from "../../Context"
import { useNavigate, useParams } from 'react-router-dom'
import { AuthContextApi } from "../../Context/api"
import { useContext,  useState } from "react"
import Footer from "../../Components/Footer"
import { jsonConfig } from "../../Config"
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid'
import './index.css'
// import Cookies from 'js-cookie'


const Forgot = () => {
    const { states, setters } = useContext(AuthContext)
    const { forgotChangePass } = useContext(AuthContextApi)
    const navigate = useNavigate()

    const token = useParams().token

    const [senha, setSenha] = useState('')
    const [senhaConfirm, setSenhaConfirm] = useState('')
    const [errorSenha, setErrorSenha] = useState(false)
    const [errorSenhaConfirm, setErrorSenhaConfirm] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false)
    const [msgErrorSenhaConfirm, setMsgErrorSenhaConfirm] = useState('O campo confirmação de senha é obrigatório')



    const actionForgot = async () => {
        let error;
        if (senha === '') {
            setErrorSenha(true)
            error = true
        } else {
            setErrorSenha(false)
        }
        if (senhaConfirm === '') {
            setErrorSenhaConfirm(true)
            setMsgErrorSenhaConfirm('O campo confirmação de senha é obrigatório')
            error = true
        } else if (senha !== senhaConfirm) {
            setErrorSenhaConfirm(true)
            setMsgErrorSenhaConfirm('As senhas não conferem')
            error = true
        } else {
            setErrorSenhaConfirm(false)
        }

        const isStrong = senha.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/)
        if (!isStrong) {
            setErrorSenhaConfirm(true)
            setMsgErrorSenhaConfirm('A senha deve conter no mínimo 8 caracteres, uma letra maiúscula, uma letra minúscula, um número e um caractere especial')
            error = true
        } else {
            setErrorSenhaConfirm(false)
            setMsgErrorSenhaConfirm('O campo confirmação de senha é obrigatório')
        }

        if (error) {
            error = false;
            return
        }

        const response = await forgotChangePass({ senha, token })
        if (response) {
            navigate('plataforma')
            setters.setModalMsg(true)
            setters.setMsgModalMsg('Recuperação de senha')
            setters.setDescModalMsg('Sua senha foi alterada com sucesso! Faça login para continuar.')
            setters.setPerigoModalMsg(false)
            setters.setTitleButtonModalMsg('Retornar para login')
            setters.setFunctionModalMsg(() => () => {
                setters.setModalMsg(false)
                setErrorSenha(false)
                setErrorSenhaConfirm(false)
                setSenha('')
                setSenhaConfirm('')
                setters.setEmail('')
                setters.setSenha('')
                localStorage.removeItem('userEmail')
                localStorage.removeItem('userPassword')
            })
        }else{
            setters.setModalMsg(true)
            setters.setMsgModalMsg('Recuperação de senha')
            setters.setDescModalMsg('Parece que houve um erro ao tentar recuperar sua senha. Tente novamente mais tarde.')
            setters.setPerigoModalMsg(false)
            setters.setTitleButtonModalMsg('Retornar para login')
            setters.setFunctionModalMsg(() => () => {
                setters.setModalMsg(false)
                setErrorSenha(false)
                setErrorSenhaConfirm(false)
                setSenha('')
                setSenhaConfirm('')
                setters.setEmail('')
                setters.setSenha('')
                localStorage.removeItem('userEmail')
                localStorage.removeItem('userPassword')
            })
        }

    }

    const returnLogin = () => {
        navigate('plataforma')
    }







    return (
        <>
            <div className="h-screen">
                <div className="flex min-h-full">
                    <div
                        className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24 ">
                        {/* border-2 border-inherit */}
                        <div className="mx-auto w-full max-w-sm lg:w-96 items-center jutify-center">
                            <div className="flex flex-col justify-center items-center">
                                <img
                                    className="h-[5em] w-auto"
                                    src={`${jsonConfig.urlReact}/logo.png`}
                                    alt="Sinprev logo"
                                />
                                <h3 className="mt-6 text-2xl text-center font-bold tracking-tight text-gray-900">
                                    Redefinição de senha ao psicólogo ERP
                                </h3>
                            </div>


                            <div className="mt-8">
                                <div className="mt-6">
                                    <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                        Senha
                                    </label>
                                    <div className="mt-1">
                                        <div
                                            className={states.isLoading ? "flex flex-row items-between justify-between bg-gray-200 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm" :
                                                errorSenha ? "flex flex-row items-between justify-between bg-red-100 appearance-none block w-full px-3 py-2 border border-red-300 rounded-md shadow-sm placeholder-red-400 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm" : "flex flex-row items-between justify-between appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400  focus-within:ring-blue-500 focus-within:border-blue-500 sm:text-sm"
                                            }
                                        >
                                            <input
                                                type={showPassword ? 'text' : 'password'}
                                                name="password"
                                                id="password"
                                                value={senha}
                                                onChange={(e) => {
                                                    const trimString = e.target.value.trim()
                                                    if (trimString !== '') {
                                                        setSenha(trimString)
                                                        setErrorSenha(false)
                                                    } else {
                                                        setSenha(trimString)
                                                        setErrorSenha(true)
                                                    }
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        actionForgot()
                                                    }
                                                }}
                                                className={`${states.isLoading ? "bg-gray-200 text-gray-400" :
                                                    errorSenha ? "bg-red-100 text-red-400" : "text-gray-600 bg-transparent"
                                                    } w-[90%] border-none focus:ring-0  focus:border-0 input-no-focus`}
                                                autoComplete="current-password"
                                                placeholder="Senha"
                                            />
                                            <div className=" inset-y-0 right-0 flex items-center">
                                                <button type="button" onClick={() => setShowPassword(!showPassword)} className=''>
                                                    {
                                                        showPassword === true ? (
                                                            <EyeIcon className="h-5 w-5 text-gray-400 " aria-hidden="true" />
                                                        ) : (
                                                            <EyeSlashIcon className="h-5 w-5 text-gray-400 " aria-hidden="true" />
                                                        )
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                        {
                                            errorSenha && <p className="text-red-500 text-xs mt-1">O campo senha é obrigatório</p>
                                        }
                                    </div>

                                </div>

                                <div className="mt-6">
                                    <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                        Confirme sua nova senha
                                    </label>
                                    <div className="mt-1">
                                        <div
                                            className={states.isLoading ? "flex flex-row items-between justify-between bg-gray-200 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm" :
                                                errorSenhaConfirm ? "flex flex-row items-between justify-between bg-red-100 appearance-none block w-full px-3 py-2 border border-red-300 rounded-md shadow-sm placeholder-red-400 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm" : "flex flex-row items-between justify-between appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400  focus-within:ring-blue-500 focus-within:border-blue-500 sm:text-sm"
                                            }
                                        >
                                            <input
                                                type={showPasswordConfirm ? 'text' : 'password'}
                                                name="password"
                                                id="password"
                                                value={senhaConfirm}
                                                onChange={(e) => {
                                                    const trimString = e.target.value.trim()
                                                    if (trimString !== '') {
                                                        setSenhaConfirm(trimString)
                                                        setErrorSenhaConfirm(false)
                                                    } else {
                                                        setSenhaConfirm(trimString)
                                                        setErrorSenhaConfirm(true)
                                                    }
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        actionForgot()
                                                    }
                                                }}
                                                className={`${states.isLoading ? "bg-gray-200 text-gray-400" :
                                                    errorSenhaConfirm ? "bg-red-100 text-red-400" : "text-gray-600 bg-transparent"
                                                    } w-[90%] border-none focus:ring-0  focus:border-0 input-no-focus`}
                                                autoComplete="current-password"
                                                placeholder="Digite sua senha novamente"
                                            />
                                            <div className=" inset-y-0 right-0 flex items-center">
                                                <button type="button" onClick={() => setShowPasswordConfirm(!showPasswordConfirm)} className=''>
                                                    {
                                                        showPasswordConfirm === true ? (
                                                            <EyeIcon className="h-5 w-5 text-gray-400 " aria-hidden="true" />
                                                        ) : (
                                                            <EyeSlashIcon className="h-5 w-5 text-gray-400 " aria-hidden="true" />
                                                        )
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                        {
                                            errorSenhaConfirm && <p className="text-red-500 text-xs mt-1">{msgErrorSenhaConfirm}</p>
                                        }
                                    </div>

                                </div>

                                <div className="mt-6">
                                    <div className="flex flex-row">
                                        <button
                                            onClick={actionForgot}
                                            type="button"
                                            disabled={states.isLoading}
                                            className={states.isLoading ? "w-full flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600   focus:ring-2 focus:ring-offset-2 focus:ring-blue-500" : "w-full flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"}>
                                            Redefinir senha
                                        </button>
                                        {
                                            states.isLoading &&
                                            <div className="ml-2">
                                                <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-blue-500 border-solid"></div>
                                            </div>
                                        }
                                    </div>
                                    <div className="flex flex-row mt-4">
                                        <button
                                            onClick={returnLogin}
                                            type="button"
                                            disabled={states.isLoading}
                                            className={states.isLoading ? "w-full flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600   focus:ring-2 focus:ring-offset-2 focus:ring-green-500" : "w-full flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"}>
                                            Voltar para login
                                        </button>
                                        {
                                            states.isLoading &&
                                            <div className="ml-2">
                                                <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-blue-500 border-solid"></div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="mt-8 flex items-center justify-center">
                                <Footer />
                            </div>
                        </div>
                    </div>
                    <div className="relative hidden w-0 flex-1 lg:block">
                        <img
                            className="absolute inset-0 h-full w-full object-cover"
                            src={jsonConfig.urlReact + '/background.jpeg'}
                            alt="" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Forgot