import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../Context';
import { AuthContextApi } from '../../../Context/api';
import { InformationCircleIcon } from '@heroicons/react/20/solid'
import Table from '../../../Components/Table/Logs';
import NavMenu from '../../../Components/NavMenu/Tecnico';
import Modal from '../../../Components/Modal/Logs';


const Logs = () => {

    const { states, setters } = useContext(AuthContext)
    const { checkLogin,
        getLogsDataSistema,
        getLogs
    } = useContext(AuthContextApi)
    const [query, setQuery] = useState('')



    const primeiroLoad = async () => {
        await getLogsDataSistema()
        await getLogs()
        await checkLogin()
    }

    const loadLogs = async () => {
        await getLogs()

    }

    const next = async () => {
        if (states.limiteBuscaLogs) {
            if (states.offsetLogs + states.limiteLogs > 0) return
            setters.setLimiteBuscaLogs(true)
        }
        setters.setOffsetLogs(states.offsetLogs + states.limiteLogs)
    }

    const previous = async () => {
        if (states.offsetLogs - states.limiteLogs < 0) return
        if (states.limiteBuscaLogs) {
            setters.setLimiteBuscaLogs(false)
        }
        setters.setOffsetLogs(states.offsetLogs - states.limiteLogs)
    }


    const openModal = async (data) => {
        setters.setLogs(data)
        setters.setModalLogs(true)

    }




    useEffect(() => {
        primeiroLoad()

        return () => {
            setQuery('')
            setters.setDataEstatisticaLogs([])
            setters.setDataLogs([])
            setters.setLogs({})
            setters.setOffsetLogs(0)
            setters.setLimiteBuscaLogs(false)
            setters.setModalLogs(false)
        }

    }, [])



    useEffect(() => {
        if (states.dataLogs?.length === 0) return
        loadLogs()
    }, [states.offsetLogs])

    const headers = ['', 'id', 'Linha do erro', 'Data de Criação', 'Data de Atualização']
    return (
        <>
            <div className="flex flex-col">
                <NavMenu />
                <div className="flex-1 p-4 border  hover:shadow-md">
                    <h1 className="text-base font-semibold leading-6 text-gray-900">Logs do sistema</h1>
                    <p className="mt-2 text-sm text-gray-700">
                        Informações sobre os logs do sistema
                    </p>
                </div>
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-1 lg:grid-cols-1 mt-4">
                    {states.dataEstatisticaLogs?.length > 0 && states.dataEstatisticaLogs.map((card, index) => (
                        <div key={card.title} className={card.value > 0 && index === -1 ? "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md" : "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md"}>
                            <div className="p-5">
                                <div className="flex items-center">
                                    <div className="flex-shrink-0">
                                        {
                                            card.value > 0 && index === -1 ? (<InformationCircleIcon className="w-6 h-6 text-red-400" aria-hidden="true" />) : (<InformationCircleIcon className="w-6 h-6 text-gray-400" aria-hidden="true" />)
                                        }
                                    </div>
                                    <div className="ml-5 w-0 flex-1">
                                        <dl>
                                            {
                                                card.value > 0 && index === -1 ? (<dt className="truncate text-sm font-medium text-red-500">{card.title}</dt>) : (<dt className="truncate text-sm font-medium text-gray-500">{card.title}</dt>)
                                            }

                                            <dd>
                                                {
                                                    card.value > 0 && index === -1 ? (<div className="text-lg font-medium text-red-500">{card.value}</div>) : (<div className="text-lg font-medium text-gray-900">{card.value}</div>)
                                                }
                                            </dd>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                <div className=" p-4 border hover:shadow-md mt-5">
                    <Table title='Logs' description='Registro de logs gerados no sistema'
                        next={next} add={console.log} show={openModal}
                        previous={previous} states={states} setters={setters} headers={headers} edit={console.log}
                        infoTable={{ offset: states.offsetLogs, limit: states.limiteLogs }} dataTable={states.dataLogs} query={query} />

                </div>
            </div>
            <Modal states={states} setters={setters} />
        </>
    )

}



export default Logs;