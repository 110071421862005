import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../../Context';
import { AuthContextApi } from '../../../../Context/api';
import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import { useParams, useNavigate } from 'react-router-dom';


const ConfiguracaoCliente = () => {

    const { states, setters } = useContext(AuthContext)
    const { checkLogin,
        getConfiguracaoCliente,

        getClienteById
    } = useContext(AuthContextApi)
    const navigate = useNavigate()
    const { id } = useParams()



    const primeiroLoad = async () => {
        if (id === undefined) {
            navigate(-1)
            return;
        }
        await getConfiguracaoCliente(id)
        await checkLogin()
        await getClienteById(id)
    }


    const formatToCpfOrCnpj = (value) => {
        if (value?.length === 11) {
            return value?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
        } else {
            return value?.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
        }
    }

    const formatToPhoneBrl = (value) => {
        return value?.replace(/^(\d{2})(\d{5})(\d{4})/, '($1) $2-$3')
    }


    useEffect(() => {
        primeiroLoad()

        return () => {
            setters.setConfiguracaoCliente({})

        }

    }, [])

    return (
        <>
            <div className="flex flex-col">

                <div className="flex p-4 mb-4  border  hover:shadow-md">
                    <div className='flex flex items-center justify-between'>
                        <button onClick={() => navigate(`/clientes/cliente/${id}`)} className="flex h-[50%] items-center jutify-center text-sm font-medium hover:shadow text-blue-500 hover:text-blue-700 border p-2 rounded-[15px]">
                            <ArrowLeftIcon className="w-5 h-5 mr-2" aria-hidden="true" />
                        </button>
                    </div>
                    <div className='mt-5 ml-5'>
                        <h1 className="text-base font-semibold leading-6 text-gray-900">Cliente</h1>
                        <p className="mt-2 text-sm text-gray-700">
                            {states.Clientes.nome} - {formatToCpfOrCnpj(states.Clientes.cpf_cnpj)} - {states.Clientes.email}
                        </p>
                    </div>
                </div>

                <div className="flex-1 p-4 border  hover:shadow-md">
                    <h1 className="text-base font-semibold leading-6 text-gray-900">Configuração do cliente</h1>
                    <p className="mt-2 text-sm text-gray-700">
                        Informações sobre a configuração cadastrada pelo cliente: {states.Clientes.nome}
                    </p>
                </div>
                {
                    Object.keys(states.configuracaoCliente).length > 0 ? (
                        <>
                            <div className="flex flex-col sm:flex-row  p-4 border hover:shadow-md mt-5">
                                <div className='flex border flex-col sm:w-[15%] p-3 items-between justify-between hover:shadow'>
                                    <h1 className="text-base font-semibold leading-6 text-gray-900">Logo</h1>
                                    <img
                                        className="inline-block mt-4 sm:mt-0 h-32 w-32 rounded-[20px] object-cover"
                                        src={`data:image/webp;base64,${states.configuracaoCliente.logo}`}
                                        alt=""
                                    />
                                </div>
                                <div className='flex mt-4 sm:ml-2 flex-col sm:flex-row sm:mt-0 sm:w-[85%] border p-3 hover:shadow'>
                                    {/* corPrimaria, corSecundaria, email, facebook, instagram, wpp, horarioAbertura, horarioFechamento */}
                                    Informacoes do negocio:
                                    <div className='flex flex-col sm:flex-row w-full justify-between mt-2 sm:w-[70%] sm:ml-8'>
                                        <div className='flex flex-col'>
                                            <div className='flex flex-col mt-2'>
                                                <h1 className="text-base font-semibold leading-6 text-gray-900">Cor primária</h1>
                                                <div className='flex items-center'>
                                                    <p className="mt-2 text-sm text-gray-700">
                                                        {states.configuracaoCliente.corPrimaria}
                                                    </p>
                                                    <div className="ml-4 w-10 h-10 rounded-full" style={{ backgroundColor: states.configuracaoCliente.corPrimaria }}></div>
                                                </div>
                                            </div>
                                            <div className='flex flex-col mt-2'>
                                                <h1 className="text-base font-semibold leading-6 text-gray-900">Cor secundária</h1>
                                                <div className='flex items-center'>
                                                    <p className="mt-2 text-sm text-gray-700">
                                                        {states.configuracaoCliente.corSecundaria}
                                                    </p>
                                                    <div className="ml-4 w-10 h-10 rounded-full" style={{ backgroundColor: states.configuracaoCliente.corSecundaria }}></div>
                                                </div>
                                            </div>


                                            <div className='flex flex-col mt-2'>
                                                <h1 className="text-base font-semibold leading-6 text-gray-900">Email</h1>
                                                <a className="mt-2 text-sm text-gray-700 hover:text-blue-500" href={`mailto:${states.configuracaoCliente.email}`}>
                                                    {states.configuracaoCliente.email}
                                                </a>
                                            </div>
                                            <div className='flex flex-col mt-2'>
                                                <h1 className="text-base font-semibold leading-6 text-gray-900">Facebook</h1>
                                                <a className="mt-2 text-sm text-gray-700 hover:text-blue-500" href={states.configuracaoCliente.facebook} target="_blank" rel="noopener noreferrer">
                                                    {states.configuracaoCliente.facebook}
                                                </a>
                                            </div>
                                        </div>
                                        <div className='flex flex-col'>

                                            <div className='flex flex-col mt-2'>
                                                <h1 className="text-base font-semibold leading-6 text-gray-900">Instagram</h1>
                                                <a className="mt-2 text-sm text-gray-700 hover:text-blue-500" href={states.configuracaoCliente.instagram} target="_blank" rel="noopener noreferrer">
                                                    {states.configuracaoCliente.instagram}
                                                </a>
                                            </div>
                                            <div className='flex flex-col mt-2'>
                                                <h1 className="text-base font-semibold leading-6 text-gray-900">Whatsapp</h1>
                                                <a className="mt-2 text-sm text-gray-700 hover:text-blue-500" href={`https://api.whatsapp.com/send?phone=55${states.configuracaoCliente.whatsapp}`} target="_blank" rel="noopener noreferrer">
                                                    {formatToPhoneBrl(states.configuracaoCliente.whatsapp)}
                                                </a>
                                            </div>
                                            <div className='flex flex-col mt-2'>
                                                <h1 className="text-base font-semibold leading-6 text-gray-900">Horário de abertura</h1>
                                                <p className="mt-2 text-sm text-gray-700">
                                                    {states.configuracaoCliente.horarioAbertura}
                                                </p>
                                            </div>
                                            <div className='flex flex-col mt-2'>
                                                <h1 className="text-base font-semibold leading-6 text-gray-900">Horário de fechamento</h1>
                                                <p className="mt-2 text-sm text-gray-700">
                                                    {states.configuracaoCliente.horarioFechamento}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </>
                    ) : (
                        <div className="flex-1 p-12 border  hover:shadow-md mt-5">
                            <h1 className="text-base font-semibold leading-6 text-gray-900">Sem configuração cadastrada</h1>
                        </div>
                    )
                }
            </div>
        </>
    )

}



export default ConfiguracaoCliente;