import moment from "moment"



const Footer = () => {
    return (
        <footer className="flex min-h-full" aria-labelledby="footer-heading">
            <div className="mx-auto max-w-7xl ">
                <div className="mt-16 border-t border-gray-900/10 pt-8 sm:mt-20 md:flex md:items-center md:justify-between lg:mt-2">
                    <p className="mt-8 text-xs leading-5 text-gray-500 md:order-1 md:mt-0">
                        &copy; {moment().format('YYYY')} SOTER TEC - Todos os direitos reservados.
                    </p>
                </div>
            </div>
        </footer>
    )
}

export default Footer
