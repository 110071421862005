import { Fragment, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import moment from 'moment'

const ModalFaturaShow = ({ states, setters }) => {


    useEffect(() => {
        if (states.modalShowFaturas === false) {
            setters.setFaturas({})
        } else {
            setters.setFaturas(states.Faturas)
        }
    }, [states.modalShowFaturas])

    const formatToCpfOrCnpj = (value) => {
        if (value?.length === 11) {
            return value?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
        } else {
            return value?.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
        }
    }


    return (
        <Transition.Root show={states.modalShowFaturas} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => setters.setModalShowFaturas(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full w-[100%] itens-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            {/* Mexer na altura, só colocar o height na classname abaixo */}
                            <Dialog.Panel className="w-[80%] transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                        onClick={() => setters.setModalShowFaturas(false)}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                                <div className='flex-col border-b border-gray-900/10'>
                                    <h3 className="text-base font-semibold leading-6 text-gray-900 text-center">Fatura</h3>
                                    <p className="flex mt-1 text-sm text-gray-500 justify-center mb-5">
                                        {
                                            `Fatura do  - ${states.Faturas.nomeCliente}`
                                        }
                                    </p>
                                </div>
                                <div className='pt-10 pl-10 pr-10'>
                                    <div className="space-y-12">
                                        {/* 1 parte */}
                                        <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                                            <div>
                                                <h2 className="text-base font-semibold leading-7 text-gray-900">Informações principais</h2>
                                                <p className="mt-1 text-sm leading-6 text-gray-600">Dados principais da fatura.</p>
                                            </div>
                                            <div className="sm:col-span-2">
                                                <div className="grid grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-2">
                                                    <div className="sm:col-span-1">
                                                        <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Nome do Cliente</label>
                                                        <p className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                                                            {states.Faturas.nomeCliente}
                                                        </p>
                                                    </div>
                                                    <div className="sm:col-span-1">
                                                        <label htmlFor="descricao" className="block text-sm font-semibold leading-5 text-gray-700">Cpf/Cnpj</label>
                                                        <p className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                                                            {
                                                                formatToCpfOrCnpj(states.Faturas.cpfCnpjCliente)
                                                            }
                                                        </p>
                                                    </div>
                                                    <div className="sm:col-span-2">
                                                        <label htmlFor="descricao" className="block text-sm font-semibold leading-5 text-gray-700">E-mail</label>
                                                        <p className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                                                            {
                                                                states.Faturas.emailCliente
                                                            }
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                                            <div>
                                                <h2 className="text-base font-semibold leading-7 text-gray-900">Informações das características</h2>
                                                <p className="mt-1 text-sm leading-6 text-gray-600">Dados da Fatura.</p>
                                            </div>
                                            <div className="sm:col-span-2">

                                                <div className="grid grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-3">
                                                    <div className="sm:col-span-1">
                                                        <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Método de pagamento</label>
                                                        <p className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                                                            {
                                                                (states.Faturas.status === 'pendente' || states.Faturas.status === 'expirado' || states.Faturas.status === 'cancelado' || states.Faturas.status === 'reembolsado') ? <span className="text-green-500">Fatura não paga</span> : states.Faturas.metodo_pagamento === 'pix' ? <span className="text-green-500">Pix</span> : states.Faturas.metodo_pagamento === 'boleto' ? <span className="text-yellow-500">Boleto</span> : states.Faturas.metodo_pagamento === 'cartao_credito' ? <span className="text-blue-500">Cartão de Crédito</span> : <span className="text-red-500">Dinheiro</span>
                                                            }
                                                        </p>
                                                    </div>
                                                    <div className="sm:col-span-1">
                                                        <label htmlFor="descricao" className="block text-sm font-semibold leading-5 text-gray-700">Status da Fatura</label>
                                                        <p className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                                                            {
                                                                states.Faturas.status === 'pendente' ? <span className="text-yellow-500">Pendente</span> : states.Faturas.status === 'expirado' ? <span className="text-red-500">Expirado</span> : states.Faturas.status === 'pago' ? <span className="text-green-500">Pago</span> : states.Faturas.status === 'cancelado' ? <span className="text-red-500">Cancelado</span> : states.Faturas.status === 'reembolsado' ? <span className="text-blue-500">Reembolsado</span> : <span className="text-gray-500">Sem status</span>
                                                            }
                                                        </p>
                                                    </div>
                                                    <div className="sm:col-span-1">
                                                        <label htmlFor="descricao" className="block text-sm font-semibold leading-5 text-gray-700">Data de vencimento</label>
                                                        <p className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                                                            {
                                                                moment(states.Faturas.data_vencimento).format('DD/MM/YYYY')
                                                            }
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="mt-8 grid grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-3">
                                                    <div className="sm:col-span-3">
                                                        <label htmlFor="descricao" className="block text-sm font-semibold leading-5 text-gray-700">Descrição</label>
                                                        <p className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                                                            {
                                                                states.Faturas.descricao
                                                            }
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="mt-8 grid grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-3">
                                                    <div className="sm:col-span-1">
                                                        <label htmlFor="descricao" className="block text-sm font-semibold leading-5 text-gray-700">Multa</label>
                                                        <p className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                                                            {
                                                                states.Faturas.multa
                                                            }%
                                                        </p>
                                                    </div>
                                                    <div className="sm:col-span-1">
                                                        <label htmlFor="descricao" className="block text-sm font-semibold leading-5 text-gray-700">Desconto</label>
                                                        <p className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                                                            {
                                                                states.Faturas.multa
                                                            }%
                                                        </p>
                                                    </div>
                                                    <div className="sm:col-span-1">
                                                        <label htmlFor="descricao" className="block text-sm font-semibold leading-5 text-gray-700">Data de Criação</label>
                                                        <p className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                                                            {moment(states.Faturas.data_criacao).format('DD/MM/YYYY')}
                                                        </p>
                                                    </div>
                                                </div>





                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-6 flex items-center justify-end gap-x-6">
                                        <button type="button" className="text-sm font-semibold leading-5 text-gray-900 bg-gray-100 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-100"
                                            onClick={() => setters.setModalShowFaturas(false)}>
                                            Cancelar
                                        </button>
                                    </div>
                                </div>


                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default ModalFaturaShow
