import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../Context';
import { AuthContextApi } from '../../Context/api';
import { InformationCircleIcon } from '@heroicons/react/20/solid'
import Table from '../../Components/Table/Planos';
import Modal from '../../Components/Modal/Planos/Planos';
import ModalShow from '../../Components/Modal/Planos/PlanosShow';


const Planos = () => {

    const { states, setters } = useContext(AuthContext)
    const { checkLogin, getPlanosDataSistema, getPlanos, getPlanosQuery, postPlano, putPlano, deletePlano } = useContext(AuthContextApi)
    const [query, setQuery] = useState('')


    /*
        const [dataEstatisticaPlanos, setDataEstatisticaPlanos] = useState([])
        const [dataPlanos, setDataPlanos] = useState([])
        const [Planos, setPlanos] = useState([])
        const [offsetPlanos, setOffsetPlanos] = useState(0)
        const [limitePlanos, setLimitePlanos] = useState(10)
        const [limiteBuscaPlanos, setLimiteBuscaPlanos] = useState(false)
        
        */


    const primeiroLoad = async () => {
        await checkLogin()
        await getPlanosDataSistema()
        await getPlanos()
    }

    const loadPlanos = async () => {
        await getPlanos()
    }

    const next = async () => {
        if (states.limiteBuscaPlanos) {
            if (states.offsetPlanos + states.limitePlanos > 0) return
            setters.setLimiteBuscaPlanos(true)
        }
        setters.setOffsetPlanos(states.offsetPlanos + states.limitePlanos)
    }

    const previous = async () => {
        if (states.offsetPlanos - states.limitePlanos < 0) return
        setters.setOffsetPlanos(states.offsetPlanos - states.limitePlanos)
    }

    const search = async () => {
        setters.setOffsetPlanos(0)
        if (query === '') {
            await getPlanos()
            return
        }
        const response = await getPlanosQuery(query)
        if (response === false) {
            setQuery('')
            await getPlanos()
        }
    }

    const clear = async () => {
        setters.setOffsetPlanos(0)
        setQuery('')
        await getPlanos()
    }


    const openModal = async () => {
        setters.setIsEdit(false)
        setters.setModalPlanos(true)
    }

    const openModalEdit = async (data) => {
        setters.setPlanos(data)
        setters.setIsEdit(true)
        setters.setModalPlanos(true)
    }

    const openModalShow = async (data) => {
        setters.setPlanos(data)
        setters.setIsEdit(false)
        setters.setModalPlanosShow(true)
    }

    const actionAddPlano = async (data) => {
        setters.setModalPlanos(false)
        if (states.isEdit) {
            await putPlano(data)
        } else {
            await postPlano(data)
        }
        setters.setIsEdit(false)
    }

    const deletePlanoModal = async (data) => {
        setters.setModalMsg(true)
        setters.setPerigoModalMsg(true)
        setters.setMsgModalMsg(`Deseja mesmo deletar o plano ${data.nome}?`)
        setters.setDescModalMsg('Após a confirmação o plano será deletado permanentemente.')
        setters.setTitleButtonModalMsg('Confirmar')
        setters.setFunctionModalMsg(() => async () => {
            await deletePlano(data.id)
            setters.setModalMsg(false)
        })
    }



    useEffect(() => {
        primeiroLoad()

        return () => {
            setQuery('')
            setters.setDataEstatisticaPlanos([])
            setters.setDataPlanos([])
            setters.setPlanos([])
            setters.setOffsetPlanos(0)
            setters.setLimiteBuscaPlanos(false)
            setters.setIsEdit(false)
            setters.setModalPlanos(false)
        }

    }, [])


    useEffect(() => {
        if (states.dataPlanos.length === 0) return
        loadPlanos()
    }, [states.offsetPlanos])

    const headers = ['', 'Nome', 'Identificador', 'Clínica', 'Área do paciente', 'Conversa Com Paciente', 'Financeiro', 'Agenda', 'Preço', 'Frequência', 'Tolerância', 'Área de clientes', 'Quantidade de clientes', 'Quantidade de Psicologos',
        'Quantidade de categorias de Serviços', 'Quantidade de Serviços', 'Quantidade de Usuários', 'Data de Criação', 'Data de Atualização', 'Usuário que criou']
    return (
        <>
            <div className="flex flex-col">


                <div className="flex-1 p-4 border  hover:shadow-md">
                    <h1 className="text-base font-semibold leading-6 text-gray-900">Planos</h1>
                    <p className="mt-2 text-sm text-gray-700">
                        Informações sobre os planos cadastrados no sistema.
                    </p>
                </div>
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-2 mt-4">
                    {states.dataEstatisticaPlanos.length > 0 && states.dataEstatisticaPlanos.map((card, index) => (
                        <div key={card.title} className={card.value > 0 && index === -1 ? "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md" : "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md"}>
                            <div className="p-5">
                                <div className="flex items-center">
                                    <div className="flex-shrink-0">
                                        {
                                            card.value > 0 && index === -1 ? (<InformationCircleIcon className="w-6 h-6 text-red-400" aria-hidden="true" />) : (<InformationCircleIcon className="w-6 h-6 text-gray-400" aria-hidden="true" />)
                                        }
                                    </div>
                                    <div className="ml-5 w-0 flex-1">
                                        <dl>
                                            {
                                                card.value > 0 && index === -1 ? (<dt className="truncate text-sm font-medium text-red-500">{card.title}</dt>) : (<dt className="truncate text-sm font-medium text-gray-500">{card.title}</dt>)
                                            }

                                            <dd>
                                                {
                                                    card.value > 0 && index === -1 ? (<div className="text-lg font-medium text-red-500">{card.value}</div>) : (<div className="text-lg font-medium text-gray-900">{card.value}</div>)
                                                }
                                            </dd>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="flex-1 p-12 border  hover:shadow-md mt-5">
                    <h1 className="text-base font-semibold leading-6 text-gray-900">Pesquisa por planos</h1>
                    <div className='flex flex-col mt-4'>
                        <p className="text-sm text-gray-700">Pesquise por título ou identificador</p>
                        <div className='flex flex-col sm:flex-row mt-2'>
                            <input type="text" className=" flex w-full sm:w-[80%] mt-1 p-2 border-2 border-gray-200 rounded-md"
                                value={query} onChange={(e) => setQuery(e.target.value)} onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        search()
                                    }
                                }}
                            />
                            <button className="flex mt-2 sm:mt-0 w-full sm:w-[10%]  ml-2 justify-center items-center p-2 bg-blue-500 text-white rounded-md hover:bg-blue-600" onClick={search}>Pesquisar</button>
                            <button className="flex mt-2 sm:mt-0 w-full sm:w-[10%]  ml-2 justify-center items-center p-2 bg-green-600 text-white rounded-md hover:bg-green-500" onClick={clear}>Limpar</button>
                        </div>
                    </div>
                </div>
                <div className=" p-4 border hover:shadow-md mt-5">
                    <Table title='Planos' page={() => console.log()} description='Registro dos planos cadastrados' del={deletePlanoModal} edit={openModalEdit}
                        add={openModal} next={next}
                        previous={previous} states={states} setters={setters} show={openModalShow} headers={headers}
                        infoTable={{ offset: states.offsetPlanos, limit: states.limitePlanos }} dataTable={states.dataPlanos} query={query} />

                </div>
            </div>

            <Modal states={states} setters={setters} action={actionAddPlano} />
            <ModalShow states={states} setters={setters} />
        </>
    )

}



export default Planos;