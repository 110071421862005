import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../../Context';
import { AuthContextApi } from '../../../../Context/api';
import { InformationCircleIcon } from '@heroicons/react/20/solid'
import Table from '../../../../Components/Table/Psicologos';
import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import { useParams, useNavigate } from 'react-router-dom';


const CategoriasServicoCliente = () => {

    const { states, setters } = useContext(AuthContext)
    const { checkLogin,
        getPrestadoresDataSistemaCliente,
        gettPrestadoresCliente,
        gettPrestadoresQueryCliente,
        getClienteById
    } = useContext(AuthContextApi)
    const [query, setQuery] = useState('')
    const navigate = useNavigate()
    const { id } = useParams()



    const primeiroLoad = async () => {
        if (id === undefined) {
            navigate(-1)
            return;
        }
        await getPrestadoresDataSistemaCliente(id)
        await gettPrestadoresCliente(id)
        await checkLogin()
        await getClienteById(id)
    }

    const loadUsuarios = async () => {
        if (query !== '') {
            await gettPrestadoresQueryCliente(id, query)
        } else {
            await gettPrestadoresCliente(id)
        }
    }

    const next = async () => {
        if (states.limiteBuscaPrestadoresCliente) {
            if (states.offsetPrestadoresCliente + states.limitePrestadoresCliente > 0) return
            setters.setLimiteBuscaPrestadoresCliente(true)
        }
        setters.setOffsetPrestadoresCliente(states.offsetPrestadoresCliente + states.limitePrestadoresCliente)
    }

    const previous = async () => {
        if (states.offsetPrestadoresCliente - states.limitePrestadoresCliente < 0) return
        if (states.limiteBuscaPrestadoresCliente) {
            setters.setLimiteBuscaPrestadoresCliente(false)
        }
        setters.setOffsetPrestadoresCliente(states.offsetPrestadoresCliente - states.limitePrestadoresCliente)
    }

    const search = async () => {
        setters.setOffsetPrestadoresCliente(0)
        if (query === '') {
            await gettPrestadoresCliente(id)
            return
        }
        const response = await gettPrestadoresQueryCliente(id, query)
        if (response === false) {
            setQuery('')
            await gettPrestadoresCliente(id)
        }
    }

    const clear = async () => {
        setters.setOffsetServicosCliente(0)
        setQuery('')
        await gettPrestadoresCliente(id)
    }

    const formatToCpfOrCnpj = (value) => {
        if (value?.length === 11) {
            return value?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
        } else {
            return value?.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
        }
    }

    useEffect(() => {
        primeiroLoad()

        return () => {
            setQuery('')
            setters.setDataEstatisticaPrestadoresCliente([])
            setters.setDataPrestadoresCliente([])
            setters.setPrestadoresCliente({})
            setters.setOffsetPrestadoresCliente(0)
            setters.setLimiteBuscaPrestadoresCliente(false)

        }

    }, [])



    useEffect(() => {
        if (states.dataPrestadoresCliente?.length === 0) return
        loadUsuarios()
    }, [states.offsetPrestadoresCliente])

    const headers = ['Nome', 'E-mail', 'Cpf', 'Código de registro', 'Data de Criação', 'Data de Atualização']
    return (
        <>
            <div className="flex flex-col">

                <div className="flex p-4 mb-4  border  hover:shadow-md">
                    <div className='flex flex items-center justify-between'>
                        <button onClick={() => navigate(`/clientes/cliente/${id}`)} className="flex h-[50%] items-center jutify-center text-sm font-medium hover:shadow text-blue-500 hover:text-blue-700 border p-2 rounded-[15px]">
                            <ArrowLeftIcon className="w-5 h-5 mr-2" aria-hidden="true" />
                        </button>
                    </div>
                    <div className='mt-5 ml-5'>
                        <h1 className="text-base font-semibold leading-6 text-gray-900">Cliente</h1>
                        <p className="mt-2 text-sm text-gray-700">
                            {states.Clientes.nome} - {formatToCpfOrCnpj(states.Clientes.cpf_cnpj)} - {states.Clientes.email}
                        </p>
                    </div>
                </div>

                <div className="flex-1 p-4 border  hover:shadow-md">
                    <h1 className="text-base font-semibold leading-6 text-gray-900">Psicólogos</h1>
                    <p className="mt-2 text-sm text-gray-700">
                        Informações sobre psicólogos cadastrados pelo cliente: {states.Clientes.nome}
                    </p>
                </div>
                <div className="grid grid-cols-1 gap-4  mt-4">
                    {states.dataEstatisticaPrestadoresCliente?.length > 0 && states.dataEstatisticaPrestadoresCliente.map((card, index) => (
                        <div key={card.title} className={card.value > 0 && index === -1 ? "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md" : "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md"}>
                            <div className="p-5">
                                <div className="flex items-center">
                                    <div className="flex-shrink-0">
                                        {
                                            card.value > 0 && index === -1 ? (<InformationCircleIcon className="w-6 h-6 text-red-400" aria-hidden="true" />) : (<InformationCircleIcon className="w-6 h-6 text-gray-400" aria-hidden="true" />)
                                        }
                                    </div>
                                    <div className="ml-5 w-0 flex-1">
                                        <dl>
                                            {
                                                card.value > 0 && index === -1 ? (<dt className="truncate text-sm font-medium text-red-500">{card.title}</dt>) : (<dt className="truncate text-sm font-medium text-gray-500">{card.title}</dt>)
                                            }

                                            <dd>
                                                {
                                                    card.value > 0 && index === -1 ? (<div className="text-lg font-medium text-red-500">{card.value}</div>) : (<div className="text-lg font-medium text-gray-900">{card.value}</div>)
                                                }
                                            </dd>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                {
                    states.dataPrestadoresCliente?.length > 0 ? (
                        <>
                            <div className="flex-1 p-12 border  hover:shadow-md mt-5">
                                <h1 className="text-base font-semibold leading-6 text-gray-900">Pesquisa por psicólogo</h1>
                                <div className='flex flex-col mt-4'>
                                    <p className="text-sm text-gray-700">Pesquise por nome, e-mail, cpf ou código de registro</p>
                                    <div className='flex flex-col sm:flex-row mt-2'>
                                        <input type="text" className=" flex w-full sm:w-[80%] mt-1 p-2 border-2 border-gray-200 rounded-md"
                                            value={query} onChange={(e) => setQuery(e.target.value)} onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    search()
                                                }
                                            }}
                                        />
                                        <button className="flex mt-2 sm:mt-0 w-full sm:w-[10%]  ml-2 justify-center items-center p-2 bg-blue-500 text-white rounded-md hover:bg-blue-600" onClick={search}>Pesquisar</button>
                                        <button className="flex mt-2 sm:mt-0 w-full sm:w-[10%]  ml-2 justify-center items-center p-2 bg-green-600 text-white rounded-md hover:bg-green-500" onClick={clear}>Limpar</button>
                                    </div>
                                </div>
                            </div>
                            <div className=" p-4 border hover:shadow-md mt-5">
                                {
                                    states.dataPrestadoresCliente?.length > 0 &&
                                    <Table title='Psicólogo' description='Registro das psicólogos cadastrados'
                                        next={next}
                                        previous={previous} states={states} setters={setters} headers={headers}
                                        infoTable={{ offset: states.offsetPrestadoresCliente, limit: states.limitePrestadoresCliente }} dataTable={states.dataPrestadoresCliente} query={query} />
                                }
                            </div>
                        </>
                    ) : (
                        <div className="flex-1 p-12 border  hover:shadow-md mt-5">
                            <h1 className="text-base font-semibold leading-6 text-gray-900">Sem psicólogos cadastrados</h1>
                        </div>
                    )
                }
            </div>
        </>
    )

}



export default CategoriasServicoCliente;