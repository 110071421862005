import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../Context';
import { AuthContextApi } from '../../Context/api';
import { useNavigate } from 'react-router-dom';
import { InformationCircleIcon } from '@heroicons/react/20/solid'
import Table from '../../Components/Table/Clientes';
import Modal from '../../Components/Modal/Clientes/Clientes';
import ModalShow from '../../Components/Modal/Clientes/ClientesShow';


const Clientes = () => {

    const navigate = useNavigate()
    const { states, setters } = useContext(AuthContext)
    const { checkLogin,
        getPlanosList,
        postCliente,
        getClientesDataSistema,
        getClientes,
        getClientesQuery,
        putCliente
    } = useContext(AuthContextApi)
    const [query, setQuery] = useState('')


    const primeiroLoad = async () => {
        await checkLogin()
        await getClientesDataSistema()
        await getClientes()
    }

    const loadClientes = async () => {
        if (query === '') {
            await getClientes()
            return
        } else {
            await getClientesQuery(query)
        }
    }

    const loadPlanos = async () => {
        await getPlanosList()
    }

    const next = async () => {
        if (states.limiteBuscaClientes) {
            if (states.offsetClientes + states.limiteClientes > 0) return
            setters.setLimiteBuscaClientes(true)
        }
        setters.setOffsetClientes(states.offsetClientes + states.limiteClientes)
    }

    const previous = async () => {
        if (states.offsetClientes - states.limiteClientes < 0) return
        setters.setOffsetClientes(states.offsetClientes - states.limiteClientes)
    }

    const search = async () => {
        setters.setOffsetClientes(0)
        if (query === '') {
            await getClientes()
            return
        }
        const response = await getClientesQuery(query)
        if (response === false) {
            setQuery('')
            await getClientes()
        }
    }

    const clear = async () => {
        setters.setOffsetClientes(0)
        setQuery('')
        await getClientes()
    }


    const openModal = async () => {
        const resp = await getPlanosList()
        if (!resp) return
        setters.setIsEdit(false)
        setters.setModalClientes(true)
    }

    const openModalEdit = async (data) => {
        setters.setClientes(data)
        setters.setIsEdit(true)
        setters.setModalClientes(true)
    }

    const openModalShow = async (data) => {
        setters.setClientes(data)
        setters.setIsEdit(false)
        setters.setModalClientesShow(true)
    }

    const actionAddCliente = async (data) => {
        setters.setModalClientes(false)
        if (states.isEdit) {
            await putCliente(data)
        } else {
            await postCliente(data)
        }
        setters.setIsEdit(false)
    }


    const morePlanos = async () => {
        if (states.limiteBuscaPlanos) {
            setters.setOffsetPlanos(states.offsetPlanos + states.limitePlanos)
            setters.setLimiteBuscaPlanos(false)
            return
        }
        setters.setOffsetPlanos(states.offsetPlanos + states.limitePlanos)
    }

    const clientePainel = async (data) => {
        return navigate(`/clientes/cliente/${data.id}`);
    }


    useEffect(() => {
        primeiroLoad()

        return () => {
            setQuery('')
            setters.setDataEstatisticaClientes([])
            setters.setDataClientes([])
            setters.setDataPlanos([])
            setters.setClientes({})
            setters.setOffsetClientes(0)
            setters.setLimiteBuscaClientes(false)
            setters.setIsEdit(false)
            setters.setModalClientes(false)
        }

    }, [])



    useEffect(() => {
        if (states.dataPlanos.length === 0 || states.limiteBuscaPlanos || states.offsetPlanos === 0) return
        loadPlanos()
    }, [states.offsetPlanos])

    useEffect(() => {
        if (states.dataClientes.length === 0) return
        loadClientes()
    }, [states.offsetClientes])

    const headers = ['', 'Clinica', 'Nome', 'Cpf/Cnpj', 'Email', 'Telefone', 'Endereço', 'Número', 'Complemento', 'Bairro', 'Cidade', 'Estado', 'Cep', 'Data de Criação', 'Data de Atualização', 'Usuário que criou']
    return (
        <>
            <div className="flex flex-col">


                <div className="flex-1 p-4 border  hover:shadow-md">
                    <h1 className="text-base font-semibold leading-6 text-gray-900">Clientes</h1>
                    <p className="mt-2 text-sm text-gray-700">
                        Informações sobre os clientes cadastrados no sistema.
                    </p>
                </div>
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 mt-4">
                    {states.dataEstatisticaClientes.length > 0 && states.dataEstatisticaClientes.map((card, index) => (
                        <div key={card.title} className={card.value > 0 && index === -1 ? "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md" : "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md"}>
                            <div className="p-5">
                                <div className="flex items-center">
                                    <div className="flex-shrink-0">
                                        {
                                            card.value > 0 && index === -1 ? (<InformationCircleIcon className="w-6 h-6 text-red-400" aria-hidden="true" />) : (<InformationCircleIcon className="w-6 h-6 text-gray-400" aria-hidden="true" />)
                                        }
                                    </div>
                                    <div className="ml-5 w-0 flex-1">
                                        <dl>
                                            {
                                                card.value > 0 && index === -1 ? (<dt className="truncate text-sm font-medium text-red-500">{card.title}</dt>) : (<dt className="truncate text-sm font-medium text-gray-500">{card.title}</dt>)
                                            }

                                            <dd>
                                                {
                                                    card.value > 0 && index === -1 ? (<div className="text-lg font-medium text-red-500">{card.value}</div>) : (<div className="text-lg font-medium text-gray-900">{card.value}</div>)
                                                }
                                            </dd>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="flex-1 p-12 border  hover:shadow-md mt-5">
                    <h1 className="text-base font-semibold leading-6 text-gray-900">Pesquisa por Clientes</h1>
                    <div className='flex flex-col mt-4'>
                        <p className="text-sm text-gray-700">Pesquise por título ou identificador</p>
                        <div className='flex flex-col sm:flex-row mt-2'>
                            <input type="text" className=" flex w-full sm:w-[80%] mt-1 p-2 border-2 border-gray-200 rounded-md"
                                value={query} onChange={(e) => setQuery(e.target.value)} onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        search()
                                    }
                                }}
                            />
                            <button className="flex mt-2 sm:mt-0 w-full sm:w-[10%]  ml-2 justify-center items-center p-2 bg-blue-500 text-white rounded-md hover:bg-blue-600" onClick={search}>Pesquisar</button>
                            <button className="flex mt-2 sm:mt-0 w-full sm:w-[10%]  ml-2 justify-center items-center p-2 bg-green-600 text-white rounded-md hover:bg-green-500" onClick={clear}>Limpar</button>
                        </div>
                    </div>
                </div>
                <div className=" p-4 border hover:shadow-md mt-5">
                    <Table title='Clientes' description='Registro dos Clientes cadastrados' edit={openModalEdit}
                        add={openModal} next={next} show={openModalShow} painel={clientePainel}
                        previous={previous} states={states} setters={setters} headers={headers}
                        infoTable={{ offset: states.offsetClientes, limit: states.limiteClientes }} dataTable={states.dataClientes} query={query} />
                </div>
            </div>

            <Modal states={states} setters={setters} more={morePlanos} action={actionAddCliente} />
            <ModalShow states={states} setters={setters} />
        </>
    )

}



export default Clientes;