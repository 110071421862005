import axios from 'axios'
import { jsonConfig } from '../Config'
// import Cookies from 'js-cookie'

const functions = {
    formatCpf: (cpf) => {
        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")

    }
}

const conection = {
    auth: () => {
        const token = `Bearer ${localStorage.getItem('token')}`
        return axios.create(
            {
                baseURL: jsonConfig.urlAPI,
                headers: {
                    "proxyauthorization": jsonConfig.APIKEY,
                    "authorization": token

                }
            }
        )
    },
    noAuth: () => {
        return axios.create(
            {
                baseURL: jsonConfig.urlAPI,
                headers: {
                    "proxyauthorization": jsonConfig.APIKEY
                }
            }
        )
    }
}

const truncate = (str, n) => {
    return (str.length > n) ? str.substr(0, n - 1) + '...' : str;
};

const formatCpf = (cpf) => {
    return cpf?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")

}


export { functions, conection, truncate, formatCpf }