import { AuthContext } from "../../Context"
import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { AuthContextApi } from '../../Context/api'
import { Bars3CenterLeftIcon, ArrowRightOnRectangleIcon} from '@heroicons/react/24/outline'

import moment from 'moment'

export default function Navbar() {
  const hist = useNavigate()

  const { setters } = useContext(AuthContext)
   const { logout } = useContext(AuthContextApi)

  const logOutSys = async () => {
    await logout()
    hist(`/plataforma`)
  }

  const dataInit = moment(new Date()).format('DD/MM/YYYY HH:mm:ss')
  const [date, setDate] = useState(dataInit)

  setInterval(() => {
    machineClock()
  }, 1000)

  const machineClock = () => {
    setDate(moment(new Date()).format('DD/MM/YYYY HH:mm:ss'))
  }

  return (
    <>
      <div className='content'>
        <div className="flex flex-1 flex-col w-full border-gray-200 border-2">
          <div className="flex h-16 flex-shrink-0 border-b border-gray-200 bg-white lg:border-none">
            <nav className='relative w-full flex flex-wrap items-center justify-between py-  text-gray-200 navbar navbar-expand-lg navbar-light'>
              <button
                type="button"
                className="border-r border-gray-200 px-4 text-gray-400 md:hidden"
                onClick={() => setters.setSideBar(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <Bars3CenterLeftIcon className="h-6 w-6" aria-hidden="true" />
              </button>

              <div className="flex flex-1 justify-start ">
                <div className="flex flex-1 justify-start md:pl-64">
                  <div className="flex flex-1 justify-start">
                    <div className="flex flex-1 justify-start lg:ml-12 md:ml-12 xsm: ml-4">
                        <div className='flex items-center justify-start mb-4 w-[90%] text-black mt-3'>
                          {date}
                        </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-1 justify-end ">
                <div className="flex flex-1 justify-end md:pl-64">
                  <div className="flex flex-1 justify-end">
                    <div className="flex flex-1 justify-end lg:mr-12 md:mr-12 mr-4">
                        <div className='flex items-center justify-end mb-4 w-[90%] text-black mt-3'>
                          <span
                              href="#"
                              onClick={logOutSys}
                            >
                              <ArrowRightOnRectangleIcon
                              className="h-6 w-6 flex-shrink-0 border-gray-200 text-gray-400 block items-center justify-center"
                              aria-hidden="true">
                            </ArrowRightOnRectangleIcon>
                          </span>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </>
  )
}