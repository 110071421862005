import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../Context';
import { AuthContextApi } from '../../../Context/api';
import { InformationCircleIcon } from '@heroicons/react/20/solid'
import Table from '../../../Components/Table/Crons';
import NavMenu from '../../../Components/NavMenu/Tecnico';
import Modal from '../../../Components/Modal/Crons';


const Logs = () => {

    const { states, setters } = useContext(AuthContext)
    const { checkLogin,
        getCronsDataSistema,
        getCrons,
    } = useContext(AuthContextApi)
    const [query, setQuery] = useState('')



    const primeiroLoad = async () => {
        await getCronsDataSistema()
        await getCrons()
        await checkLogin()
    }

    const loadCrons = async () => {
        await getCrons()

    }

    const next = async () => {
        if (states.limiteBuscaCrons) {
            if (states.offsetCrons + states.limiteCrons > 0) return
            setters.setLimiteBuscaCrons(true)
        }
        setters.setOffsetCrons(states.offsetCrons + states.limiteCrons)
    }

    const previous = async () => {
        if (states.offsetCrons - states.limiteCrons < 0) return
        if (states.limiteBuscaCrons) {
            setters.setLimiteBuscaCrons(false)
        }
        setters.setOffsetCrons(states.offsetCrons - states.limiteCrons)
    }


    const openModal = async (data) => {
        setters.setCrons(data)
        setters.setModalCrons(true)

    }




    useEffect(() => {
        primeiroLoad()

        return () => {
            setQuery('')
            setters.setDataEstatisticaCrons([])
            setters.setDataCrons([])
            setters.setCrons({})
            setters.setOffsetCrons(0)
            setters.setLimiteBuscaCrons(false)
            setters.setModalCrons(false)
        }

    }, [])



    useEffect(() => {
        if (states.dataCrons?.length === 0) return
        loadCrons()
    }, [states.offsetCrons])

    const headers = ['', 'id', 'Linha do erro', 'Data de Criação', 'Data de Atualização']
    return (
        <>
            <div className="flex flex-col">
                <NavMenu />
                <div className="flex-1 p-4 border  hover:shadow-md">
                    <h1 className="text-base font-semibold leading-6 text-gray-900">Crons do sistema</h1>
                    <p className="mt-2 text-sm text-gray-700">
                        Informações sobre as crons do sistema
                    </p>
                </div>
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-3 lg:grid-cols-3 mt-4">
                    {states.dataEstatisticaCrons?.length > 0 && states.dataEstatisticaCrons.map((card, index) => (
                        <div key={card.title} className={card.value > 0 && index === -1 ? "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md" : "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md"}>
                            <div className="p-5">
                                <div className="flex items-center">
                                    <div className="flex-shrink-0">
                                        {
                                            card.value > 0 && index === -1 ? (<InformationCircleIcon className="w-6 h-6 text-red-400" aria-hidden="true" />) : (<InformationCircleIcon className="w-6 h-6 text-gray-400" aria-hidden="true" />)
                                        }
                                    </div>
                                    <div className="ml-5 w-0 flex-1">
                                        <dl>
                                            {
                                                card.value > 0 && index === -1 ? (<dt className="truncate text-sm font-medium text-red-500">{card.title}</dt>) : (<dt className="truncate text-sm font-medium text-gray-500">{card.title}</dt>)
                                            }

                                            <dd>
                                                {
                                                    card.value > 0 && index === -1 ? (<div className="text-lg font-medium text-red-500">{card.value}</div>) : (<div className="text-lg font-medium text-gray-900">{card.value}</div>)
                                                }
                                            </dd>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                <div className=" p-4 border hover:shadow-md mt-5">
                    <Table title='Crons' description='Registro de Crons gerados no sistema'
                        next={next} add={console.log} show={openModal}
                        previous={previous} states={states} setters={setters} headers={headers} edit={console.log}
                        infoTable={{ offset: states.offsetCrons, limit: states.limiteCrons }} dataTable={states.dataCrons} query={query} />
                </div>
            </div>
            <Modal states={states} setters={setters} />
        </>
    )

}



export default Logs;