import { Fragment, useState, useEffect, useContext } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import moment from 'moment'

const ModalUsuarios = ({ states, setters, action }) => {



    const [email, setEmail] = useState('')
    const [errorEmail, setErrorEmail] = useState(false)
    const [errorEmailMsg, setErrorEmailMsg] = useState('Campo obrigatório')
    const [permissao, setPermissao] = useState('')
    const [errorPermissao, setErrorPermissao] = useState(false)


    const handleSubmit = async () => {
        let error = false

        if (email === '') {
            setErrorEmail(true)
            error = true
        }else setErrorEmail(false)

        if (permissao === '') {
            setErrorPermissao(true)
            error = true
        }else setErrorPermissao(false)

        const regexValidEmail = /^[a-z0-9.]+@[a-z0-9]+\.[a-z]+(\.[a-z]+)?$/i
        if (!regexValidEmail.test(email)) {
            setErrorEmail(true)
            setErrorEmailMsg('E-mail inválido')
            error = true
        }else setErrorEmail(false)



        if (error) {
            error = false
            return
        }

        action({
            email,
            role: permissao
        })

    }



    useEffect(() => {
        if (states.modalUsuarios === false) {
            setEmail('')
            setErrorEmailMsg('Campo obrigatório')
            setErrorEmail(false)
            setPermissao('')
            setErrorPermissao(false)

        } else {
            if (states.isEdit) {
                setEmail(states.Usuarios.email)
                setPermissao(`${states.Usuarios.role}`)
            }
        }
    }, [states.modalUsuarios])






    return (
        <Transition.Root show={states.modalUsuarios} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => setters.setModalUsuarios(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full w-[100%] itens-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            {/* Mexer na altura, só colocar o height na classname abaixo */}
                            <Dialog.Panel className="w-[80%] transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                        onClick={() => setters.setModalUsuarios(false)}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                                <div className='flex-col border-b border-gray-900/10'>
                                    <h3 className="text-base font-semibold leading-6 text-gray-900 text-center">Usuário</h3>
                                    <p className="flex mt-1 text-sm text-gray-500 justify-center mb-5">
                                        {
                                            states.isEdit ? `Edição do usuario - ${states.Usuarios.email}` : 'Cadastro um novo usuário'
                                        }
                                    </p>
                                </div>
                                <div className='pt-10 pl-10 pr-10'>
                                    <div className="space-y-12">
                                        {/* 1 parte */}
                                        <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                                            <div>
                                                <h2 className="text-base font-semibold leading-7 text-gray-900">Informações principais</h2>
                                                <p className="mt-1 text-sm leading-6 text-gray-600">Dados principais do usuário.</p>
                                            </div>
                                            <div className="sm:col-span-2">
                                                <div className="grid grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-2">
                                                    <div className="sm:col-span-1">
                                                        <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">E-mail</label>
                                                        <input
                                                            type="text"
                                                            id="title"
                                                            name="title"
                                                            value={email}
                                                            maxLength={255}
                                                            disabled={states.isEdit}
                                                            onChange={e => {
                                                                if (e.target.value === '') setErrorEmail(true)
                                                                else setErrorEmail(false)
                                                                setEmail(e.target.value)
                                                            }}
                                                            className={states.isEdit ? "mt-1 block w-full px-3 py-2 border bg-gray-100 border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm" : "mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"}
                                                            placeholder="Digite o nome do plnao"
                                                        />
                                                        <span className="text-red-500 text-sm">{errorEmail && errorEmailMsg}</span>
                                                    </div>
                                                    <div className="sm:col-span-1">
                                                        <label htmlFor="descricao" className="block text-sm font-semibold leading-5 text-gray-700">Permissão</label>
                                                        <select
                                                            id="descricao"
                                                            name="descricao"
                                                            value={permissao}
                                                            onChange={e => {
                                                                if (e.target.value === '') setErrorPermissao(true)
                                                                else setErrorPermissao(false)
                                                                setPermissao(e.target.value)
                                                            }}
                                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                        >
                                                            <option value="">Selecione uma permissão</option>
                                                            <option value="0">Administrador</option>
                                                            <option value="1">Financeiro</option>
                                                            <option value="2">Administrativo</option>
                                                            <option value="3">Operacional</option>
                                                        </select>
                                                        <span className="text-red-500 text-sm">{errorPermissao && 'Campo obrigatório'}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="mt-6 flex items-center justify-end gap-x-6">
                                        <button type="button" className="text-sm font-semibold leading-5 text-gray-900 bg-gray-100 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-100"
                                            onClick={() => setters.setModalUsuarios(false)}>
                                            Cancelar
                                        </button>
                                        <button
                                            onClick={handleSubmit}
                                            type="button"
                                            className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                        >
                                            {
                                                states.isEdit ? 'Editar' : 'Cadastrar'
                                            }
                                        </button>
                                    </div>
                                </div>


                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default ModalUsuarios
