import { Route, Routes, useLocation } from 'react-router-dom';
import { useEffect } from "react";
import RouterWrapper from './auth';



//NO AUTH
import Login from '../Views/Login';
import RecuperarSenha from '../Views/RecuperacaoSenha'
import Page404 from '../Views/404'
import TrocaEmail from '../Views/TrocaEmail'
import Autenticar from '../Views/AutenticarEmail'


//AUTH
import Dashboard from '../Views/Dashboard';
import Planos from '../Views/Planos';
import Assinaturas from '../Views/Assinaturas'
import Faturas from '../Views/Faturas'
import Perfil from '../Views/Perfil'

import Clientes from '../Views/Clientes'
import Cliente from '../Views/Clientes/Cliente'


import Usuario from '../Views/Usuario'
import Usuarios from '../Views/Usuario/Usuarios'
import RecuperacaoSenha from '../Views/Usuario/RecuperacaoSenha'

import Tecnico from '../Views/Tecnico'
import Cron from '../Views/Tecnico/Cron'
import Logs from '../Views/Tecnico/Logs'

import UsuariosClientesPainel from '../Views/Clientes/Cliente/Usuarios'
import UsuariosCliente from '../Views/Clientes/Cliente/Usuarios/Usuarios'
import RecuperacaoSenhaCliente from '../Views/Clientes/Cliente/Usuarios/PerdeuSenha'

import ServicosCliente from '../Views/Clientes/Cliente/Servicos'
import CategoriaServicosCliente from '../Views/Clientes/Cliente/CategoriasServico'
import ConfiguracaoCliente from '../Views/Clientes/Cliente/Configuracao'
import PsicologosCliente from '../Views/Clientes/Cliente/Psicologos'
import ComprovantesCliente from '../Views/Clientes/Cliente/Comprovantes'
import ConfiguracaoContabilidadeCliente from '../Views/Clientes/Cliente/ConfiguracaoContabilidade'
import CarneLeaoContabilidadeCliente from '../Views/Clientes/Cliente/CarneLeao'
import FaturasCliente from '../Views/Clientes/Cliente/Faturas'


const Router = () => {

    const { pathname } = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname])


    const RouterUsuarios = () => {
        return (
            <Routes>
                <Route path="*" element={<RouterWrapper component={Usuario} isPrivate={false} />} />
                <Route path="/usuarios" element={<RouterWrapper component={Usuarios} isPrivate={false} />} />
                <Route path="/recuperacaoDeSenha" element={<RouterWrapper component={RecuperacaoSenha} isPrivate={false} />} />
            </Routes>
        )
    }

    const RouterTecnico = () => {
        return (
            <Routes>
                <Route path="*" element={<RouterWrapper component={Tecnico} isPrivate={false} />} />
                <Route path="/logs" element={<RouterWrapper component={Logs} isPrivate={false} />} />
                <Route path="/cron" element={<RouterWrapper component={Cron} isPrivate={false} />} />
            </Routes>
        )
    }

    const RouterClienteUsuarios = () => {
        return (
            <Routes>
                <Route path="*" element={<RouterWrapper component={UsuariosClientesPainel} isPrivate={false} />} />
                <Route path="/usuarios" element={<RouterWrapper component={UsuariosCliente} isPrivate={false} />} />
                <Route path="/recuperacaoDeSenha" element={<RouterWrapper component={RecuperacaoSenhaCliente} isPrivate={false} />} />
            </Routes>
        )
    }

    const RouterCliente = () => {
        return (
            <Routes>
                <Route path="*" element={<RouterWrapper component={Clientes} isPrivate={false} />} />
                <Route path="/cliente/:id" element={<RouterWrapper component={Cliente} isPrivate={false} />} />
                <Route path="/cliente/:id/usuarios/*" element={<RouterWrapper component={RouterClienteUsuarios} isPrivate={false} />} />
                <Route path="/cliente/:id/servicos" element={<RouterWrapper component={ServicosCliente} isPrivate={false} />} />
                <Route path="/cliente/:id/categoriaServicos" element={<RouterWrapper component={CategoriaServicosCliente} isPrivate={false} />} />
                <Route path="/cliente/:id/configuracao" element={<RouterWrapper component={ConfiguracaoCliente} isPrivate={false} />} />
                <Route path="/cliente/:id/psicologos" element={<RouterWrapper component={PsicologosCliente} isPrivate={false} />} />
                <Route path="/cliente/:id/comprovantes" element={<RouterWrapper component={ComprovantesCliente} isPrivate={false} />} />
                <Route path="/cliente/:id/configuracaoContabilidade" element={<RouterWrapper component={ConfiguracaoContabilidadeCliente} isPrivate={false} />} />
                <Route path="/cliente/:id/carneLeao" element={<RouterWrapper component={CarneLeaoContabilidadeCliente} isPrivate={false} />} />
                <Route path="/cliente/:id/faturas" element={<RouterWrapper component={FaturasCliente} isPrivate={false} />} />
            </Routes>
        )
    }


    return (
        <Routes>
            {/* <Route path="/profile" element={<RouterWrapper component={Profile} isPrivate={true} />} /> */}
            <Route path="/dashboard" element={<RouterWrapper component={Dashboard} isPrivate={true} />} />
            <Route path="/planos" element={<RouterWrapper component={Planos} isPrivate={true} />} />
            <Route path="/assinaturas" element={<RouterWrapper component={Assinaturas} isPrivate={true} />} />
            <Route path="/faturas" element={<RouterWrapper component={Faturas} isPrivate={true} />} />
            <Route path="/clientes/*" element={<RouterWrapper component={RouterCliente} isPrivate={true} />} />
            <Route path="/perfil" element={<RouterWrapper component={Perfil} isPrivate={true} />} />
            <Route path="/tecnicoIndex/*" element={<RouterWrapper component={RouterTecnico} isPrivate={true} />} />
            <Route path="/usuariosPainel/*" element={<RouterWrapper component={RouterUsuarios} isPrivate={true} />} />
            <Route path="/recuperarSenha/:token" element={<RouterWrapper component={RecuperarSenha} isPrivate={false} />} />
            <Route path="/trocarEmail/:token" element={<RouterWrapper component={TrocaEmail} isPrivate={false} />} />
            <Route path="/autenticarEmail/:token" element={<RouterWrapper component={Autenticar} isPrivate={false} />} />
            <Route path="/plataforma" element={<RouterWrapper component={Login} isPrivate={false} />} />
            <Route path="*" element={<Page404 />} />
        </Routes>
    )
}

export default Router