import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../Context';
import { AuthContextApi } from '../../Context/api';
import { InformationCircleIcon } from '@heroicons/react/20/solid'
import Table from '../../Components/Table/Assinaturas';
import Modal from '../../Components/Modal/Assinatura/Assinatura';
import ModalShow from '../../Components/Modal/Assinatura/AssinaturaShow';


const Assinatura = () => {

    const { states, setters } = useContext(AuthContext)
    const { checkLogin,
        cancelAssinatura,
        activeAssinatura,
        putAssinatura,
        getPlanosList,
        getAssinaturasDataSistema,
        getAssinaturas,
        getAssinaturasQuery, } = useContext(AuthContextApi)
    const [query, setQuery] = useState('')



    const primeiroLoad = async () => {
        await getAssinaturasDataSistema()
        await checkLogin()
        await getAssinaturas()
    }

    const loadPlanos = async () => {
        await getPlanosList()
    }
    const loadAssinatura = async () => {
        if (query !== '') {
            await getAssinaturasQuery(query)
        } else {
            await getAssinaturas()
        }
    }

    const next = async () => {
        if (states.limiteBuscaAssinaturas) {
            if (states.offsetAssinaturas + states.limiteAssinaturas > 0) return
            setters.setLimiteBuscaAssinaturas(true)
        }
        setters.setOffsetAssinaturas(states.offsetAssinaturas + states.limiteAssinaturas)
    }

    const previous = async () => {
        if (states.offsetAssinaturas - states.limiteAssinaturas < 0) return
        setters.setOffsetAssinaturas(states.offsetAssinaturas - states.limiteAssinaturas)
        if (states.limiteBuscaAssinaturas) {
            setters.setLimiteBuscaAssinaturas(false)
        }
    }

    const search = async () => {
        setters.setOffsetAssinaturas(0)
        if (query === '') {
            await getAssinaturas()
            return
        }
        const response = await getAssinaturasQuery(query)
        if (response === false) {
            setQuery('')
            await getAssinaturas()
        }
    }

    const clear = async () => {
        setters.setOffsetAssinaturas(0)
        setQuery('')
        await getAssinaturas()
    }


    const openModal = async () => {
        setters.setIsEdit(false)
        setters.setModalPlanos(true)
    }

    const openModalEdit = async (data) => {
        await getPlanosList()
        setters.setAssinaturas(data)
        setters.setIsEdit(true)
        setters.setModalAssinaturas(true)
    }

    const openModalShow = async (data) => {
        await getAssinaturasDataSistema()
        setters.setAssinaturas(data)
        setters.setIsEdit(false)
        setters.setModalAssinaturasShow(true)
    }

    const actionEditPlano = async (data) => {
        setters.setModalAssinaturas(false)
        await putAssinatura(data)
        setters.setIsEdit(false)
    }

    const updateStatus = async (data) => {
        if (data.status === 1) {
            setters.setModalMsg(true)
            setters.setPerigoModalMsg(true)
            setters.setMsgModalMsg(`Deseja mesmo cancelar a assinatura do ${data.cliente_nome}?`)
            setters.setDescModalMsg('Após a confirmação a assinatura será cancelada.')
            setters.setTitleButtonModalMsg('Confirmar')
            setters.setFunctionModalMsg(() => async () => {
                await cancelAssinatura(data.id)
                setters.setModalMsg(false)
            })
        } else {
            setters.setModalMsg(true)
            setters.setPerigoModalMsg(false)
            setters.setMsgModalMsg(`Deseja reativar a assinatura do ${data.cliente_nome}?`)
            setters.setDescModalMsg('Após a confirmação a assinatura será reativada.')
            setters.setTitleButtonModalMsg('Confirmar')
            setters.setFunctionModalMsg(() => async () => {
                await activeAssinatura(data.id)
                setters.setModalMsg(false)
            })
        }
    }

    const morePlanos = async () => {
        if (states.limiteBuscaPlanos) {
            setters.setOffsetPlanos(states.offsetPlanos + states.limitePlanos)
            setters.setLimiteBuscaPlanos(false)
            return
        }
        setters.setOffsetPlanos(states.offsetPlanos + states.limitePlanos)
    }



    useEffect(() => {
        primeiroLoad()

        return () => {
            setQuery('')
            setters.setDataEstatisticaAssinaturas([])
            setters.setDataAssinaturas([])
            setters.setAssinaturas({})
            setters.setOffsetAssinaturas(0)
            setters.setOffsetPlanos(0)
            setters.setDataPlanos([])
            setters.setLimiteBuscaAssinaturas(false)
            setters.setModalAssinaturas(false)
            setters.setModalAssinaturasShow(false)
            setters.setIsEdit(false)
        }

    }, [])


    useEffect(() => {
        if (states.dataPlanos.length === 0 || states.limiteBuscaPlanos || states.offsetPlanos === 0) return
        loadPlanos()
    }, [states.offsetPlanos])
    useEffect(() => {
        if (states.dataAssinaturas.length === 0) return
        loadAssinatura()
    }, [states.offsetAssinaturas])

    const headers = ['', 'Nome', 'CPF/CNPJ', 'E-mail', 'Clínica', 'Status da Assinatura', 'Status de Débito', 'Dia Pagamento', 'Método', 'Nome do Plano',
        'Data de Tolerâcia', 'Próximo pagamento', 'Data Início', 'Data de Criação', 'Data de Atualização']
    return (
        <>
            <div className="flex flex-col">


                <div className="flex-1 p-4 border  hover:shadow-md">
                    <h1 className="text-base font-semibold leading-6 text-gray-900">Assinaturas</h1>
                    <p className="mt-2 text-sm text-gray-700">
                        Informações sobre as assinaturas cadastrados no sistema.
                    </p>
                </div>
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-4 lg:grid-cols-4 mt-4">
                    {states.dataEstatisticaAssinaturas.length > 0 && states.dataEstatisticaAssinaturas.map((card, index) => (
                        <div key={card.title} className={card.value > 0 && index === -1 ? "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md" : "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md"}>
                            <div className="p-5">
                                <div className="flex items-center">
                                    <div className="flex-shrink-0">
                                        {
                                            card.value > 0 && index === -1 ? (<InformationCircleIcon className="w-6 h-6 text-red-400" aria-hidden="true" />) : (<InformationCircleIcon className="w-6 h-6 text-gray-400" aria-hidden="true" />)
                                        }
                                    </div>
                                    <div className="ml-5 w-0 flex-1">
                                        <dl>
                                            {
                                                card.value > 0 && index === -1 ? (<dt className="truncate text-sm font-medium text-red-500">{card.title}</dt>) : (<dt className="truncate text-sm font-medium text-gray-500">{card.title}</dt>)
                                            }

                                            <dd>
                                                {
                                                    card.value > 0 && index === -1 ? (<div className="text-lg font-medium text-red-500">{card.value}</div>) : (<div className="text-lg font-medium text-gray-900">{card.value}</div>)
                                                }
                                            </dd>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="flex-1 p-12 border  hover:shadow-md mt-5">
                    <h1 className="text-base font-semibold leading-6 text-gray-900">Pesquisa por assinaturas</h1>
                    <div className='flex flex-col mt-4'>
                        <p className="text-sm text-gray-700">Pesquise por nome, e-mail ou CPF/CNPJ</p>
                        <div className='flex flex-col sm:flex-row mt-2'>
                            <input type="text" className=" flex w-full sm:w-[80%] mt-1 p-2 border-2 border-gray-200 rounded-md"
                                value={query} onChange={(e) => setQuery(e.target.value)} onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        search()
                                    }
                                }}
                            />
                            <button className="flex mt-2 sm:mt-0 w-full sm:w-[10%]  ml-2 justify-center items-center p-2 bg-blue-500 text-white rounded-md hover:bg-blue-600" onClick={search}>Pesquisar</button>
                            <button className="flex mt-2 sm:mt-0 w-full sm:w-[10%]  ml-2 justify-center items-center p-2 bg-green-600 text-white rounded-md hover:bg-green-500" onClick={clear}>Limpar</button>
                        </div>
                    </div>
                </div>
                <div className=" p-4 border hover:shadow-md mt-5">
                    <Table title='Assinaturas' description='Registro dos assinaturas cadastrados' changeStatus={updateStatus} edit={openModalEdit}
                        add={openModal} next={next}
                        previous={previous} states={states} setters={setters} show={openModalShow} headers={headers}
                        infoTable={{ offset: states.offsetAssinaturas, limit: states.limiteAssinaturas }} dataTable={states.dataAssinaturas} query={query} />
                </div>
            </div>
            <Modal states={states} setters={setters} action={actionEditPlano} more={morePlanos} />
            <ModalShow states={states} setters={setters} />
        </>
    )

}



export default Assinatura;