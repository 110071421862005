import { Fragment, useState, useEffect, useContext } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import moment from 'moment'

const ModalPLanos = ({ states, setters, action }) => {


    const [nome, setNome] = useState('')
    const [descricao, setDescricao] = useState('')

    const [errorNome, setErrorNome] = useState(false)
    const [errorDescricao, setErrorDescricao] = useState(false)

    const [empresa, setEmpresa] = useState('')
    const [usuarios, setUsuarios] = useState('')
    const [clientes, setClientes] = useState('')
    const [prestadores, setPrestadores] = useState('')
    const [categoria, setCategoria] = useState('')
    const [servicos, setServicos] = useState('')
    const [preco, setPreco] = useState('')
    const [frequencia, setFrequencia] = useState('')
    const [tolerancia, setTolerancia] = useState('')
    const [areaCliente, setAreaCliente] = useState('')
    const [conversaCliente, setConversaCliente] = useState('')
    const [agenda, setAgenda] = useState('')
    const [financeiro, setFinanceiro] = useState('')

    const [errorEmpresa, setErrorEmpresa] = useState(false)
    const [errorUsuarios, setErrorUsuarios] = useState(false)
    const [errorClientes, setErrorClientes] = useState(false)
    const [errorPrestadores, setErrorPrestadores] = useState(false)
    const [errorCategoria, setErrorCategoria] = useState(false)
    const [errorServicos, setErrorServicos] = useState('')
    const [errorPreco, setErrorPreco] = useState('')
    const [errorFrequencia, setErrorFrequencia] = useState('')
    const [errorTolerancia, setErrorTolerancia] = useState('')
    const [errorAreaCliente, setErrorAreaCliente] = useState('')
    const [errorConversaCliente, setErrorConversaCliente] = useState('')
    const [errorAgenda, setErrorAgenda] = useState('')
    const [errorFinanceiro, setErrorFinanceiro] = useState('')


    const handleSubmit = async () => {
        let error = false
        if (nome === '') {
            setErrorNome(true)
            error = true
        } else setErrorNome(false)
        if (descricao === '') {
            setErrorDescricao(true)
            error = true
        } else setErrorDescricao(false)
        if (empresa === '') {
            setErrorEmpresa(true)
            error = true
        } else setErrorEmpresa(false)
        if (usuarios === '') {
            setErrorUsuarios(true)
            error = true
        } else setErrorUsuarios(false)
        if (clientes === '') {
            setErrorClientes(true)
            error = true
        } else setErrorClientes(false)
        if (prestadores === '') {
            setErrorPrestadores(true)
            error = true
        } else setErrorPrestadores(false)
        if (categoria === '') {
            setErrorCategoria(true)
            error = true
        } else setErrorCategoria(false)
        if (servicos === '') {
            setErrorServicos(true)
            error = true
        } else setErrorServicos(false)
        if (preco === '') {
            setErrorPreco(true)
            error = true
        } else setErrorPreco(false)
        if (frequencia === '') {
            setErrorFrequencia(true)
            error = true
        } else setErrorFrequencia(false)
        if (tolerancia === '') {
            setErrorTolerancia(true)
            error = true
        } else setErrorTolerancia(false)

        if (areaCliente === '') {
            setErrorAreaCliente(true)
            error = true
        } else setErrorAreaCliente(false)

        if (conversaCliente === '') {
            setErrorConversaCliente(true)
            error = true
        } else setErrorConversaCliente(false)

        if (agenda === '') {
            setErrorAgenda(true)
            error = true
        } else setErrorAgenda(false)

        if (financeiro === '') {
            setErrorFinanceiro(true)
            error = true
        } else setErrorFinanceiro(false)

        if (error) {
            error = false
            return
        }

       action({
            nome,
            descricao,
            empresa,
            usuarios,
            clientes,
            prestadores,
            categoria,
            area_cliente: areaCliente,
            servicos,
            preco: preco.replace(/[^0-9]/g, '').replace(',', '').replace('.', ''),
            frequencia,
            tolerancia,
            conversa_cliente: conversaCliente,
            agenda,
            financeiro

        })

    }



    useEffect(() => {
        if (states.modalPlanos === false) {
            setNome('')
            setDescricao('')
            setEmpresa('')
            setUsuarios('')
            setClientes('')
            setPrestadores('')
            setCategoria('')
            setServicos('')
            setPreco('')
            setFrequencia('')
            setTolerancia('')
            setAreaCliente('')
            setConversaCliente('')
            setAgenda('')
            setFinanceiro('')
            setErrorAreaCliente(false)
            setErrorNome(false)
            setErrorDescricao(false)
            setErrorEmpresa(false)
            setErrorUsuarios(false)
            setErrorClientes(false)
            setErrorPrestadores(false)
            setErrorCategoria(false)
            setErrorServicos(false)
            setErrorPreco(false)
            setErrorFrequencia(false)
            setErrorTolerancia(false)
            setErrorConversaCliente(false)
            setErrorAgenda(false)
            setErrorFinanceiro(false)
        } else {
            if (states.isEdit) {
                console.log(states.Planos)
                setNome(states.Planos.nome)
                setDescricao(states.Planos.descricao)
                setEmpresa(states.Planos.empresa === 1 ? 'true' : 'false')
                setAreaCliente(states.Planos.area_cliente === 1 ? 'true' : 'false')
                setUsuarios(states.Planos.usuarios)
                setClientes(states.Planos.clientes)
                setPrestadores(states.Planos.prestadores)
                setCategoria(states.Planos.categoria)
                setServicos(states.Planos.servicos)
                setPreco(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(states.Planos.preco / 100))
                setFrequencia(states.Planos.frequencia)
                setTolerancia(states.Planos.tolerancia)
                setAgenda(states.Planos.agenda === 1 ? 'true' : 'false')
                setConversaCliente(states.Planos.conversa_cliente === 1 ? 'true' : 'false')
                setFinanceiro(states.Planos.financeiro === 1 ? 'true' : 'false')
            }
        }

    }, [states.modalPlanos])


    useEffect(() => {
        if (empresa === "false") {
            setPrestadores('1')
            setCategoria('1')
            setErrorCategoria(false)
            setErrorPrestadores(false)
        } else {
            setErrorCategoria(false)
            setErrorPrestadores(false)
        }
    }, [empresa]);

    useEffect(() => {
        if (areaCliente === "false") {
            setErrorConversaCliente(false)
            setConversaCliente('')
        } 
    }, [areaCliente]);



    return (
        <Transition.Root show={states.modalPlanos} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => setters.setModalPlanos(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full w-[100%] itens-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            {/* Mexer na altura, só colocar o height na classname abaixo */}
                            <Dialog.Panel className="w-[80%] transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                        onClick={() => setters.setModalPlanos(false)}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                                <div className='flex-col border-b border-gray-900/10'>
                                    <h3 className="text-base font-semibold leading-6 text-gray-900 text-center">Plano</h3>
                                    <p className="flex mt-1 text-sm text-gray-500 justify-center mb-5">
                                        {
                                            states.isEdit ? `Edição do plano - ${states.Planos.nome}` : 'Cadastro um novo plano'
                                        }
                                    </p>
                                </div>
                                <div className='pt-10 pl-10 pr-10'>
                                    <div className="space-y-12">
                                        {/* 1 parte */}
                                        <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                                            <div>
                                                <h2 className="text-base font-semibold leading-7 text-gray-900">Informações principais</h2>
                                                <p className="mt-1 text-sm leading-6 text-gray-600">Dados principais do plano.</p>
                                            </div>
                                            <div className="sm:col-span-2">
                                                <div className="grid grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-2">
                                                    <div className="sm:col-span-1">
                                                        <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Nome</label>
                                                        <input
                                                            type="text"
                                                            id="title"
                                                            name="title"
                                                            value={nome}
                                                            maxLength={255}
                                                            onChange={e => {
                                                                if (e.target.value === '') setErrorNome(true)
                                                                else setErrorNome(false)
                                                                setNome(e.target.value)
                                                            }}
                                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                            placeholder="Digite o nome do plnao"
                                                        />
                                                        <span className="text-red-500 text-sm">{errorNome && 'Campo obrigatório'}</span>
                                                    </div>
                                                    <div className="sm:col-span-1">
                                                        <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Possuí Área do cliennte?</label>
                                                        <select
                                                            id="areaCliente"
                                                            name="areaCliente"
                                                            value={areaCliente}
                                                            onChange={e => {
                                                                if (e.target.value === '') setErrorAreaCliente(true)
                                                                else setErrorAreaCliente(false)
                                                                setAreaCliente(e.target.value)
                                                            }}
                                                            className='mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm'
                                                        >
                                                            <option value={''}>Selecione ..</option>
                                                            <option value={true}>Sim</option>
                                                            <option value={false}>Não</option>
                                                        </select>
                                                        <span className="text-red-500 text-sm">{errorAreaCliente && 'Campo obrigatório'}</span>
                                                    </div>
                                                    {
                                                        areaCliente === 'true' &&
                                                        <div className="sm:col-span-1">
                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Possuí conversa com o paciente?</label>
                                                            <select
                                                                id="areaCliente"
                                                                name="areaCliente"
                                                                value={conversaCliente}
                                                                onChange={e => {
                                                                    if (e.target.value === '') setErrorConversaCliente(true)
                                                                    else setErrorConversaCliente(false)
                                                                    setConversaCliente(e.target.value)
                                                                }}
                                                                className='mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm'
                                                            >
                                                                <option value={''}>Selecione ..</option>
                                                                <option value={true}>Sim</option>
                                                                <option value={false}>Não</option>
                                                            </select>
                                                            <span className="text-red-500 text-sm">{errorConversaCliente && 'Campo obrigatório'}</span>
                                                        </div>
                                                    }
                                                    <div className="sm:col-span-1">
                                                        <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Possuí Financeiro?</label>
                                                        <select
                                                            id="areaCliente"
                                                            name="areaCliente"
                                                            value={financeiro}
                                                            onChange={e => {
                                                                if (e.target.value === '') setErrorFinanceiro(true)
                                                                else setErrorFinanceiro(false)
                                                                setFinanceiro(e.target.value)
                                                            }}
                                                            className='mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm'
                                                        >
                                                            <option value={''}>Selecione ..</option>
                                                            <option value={true}>Sim</option>
                                                            <option value={false}>Não</option>
                                                        </select>
                                                        <span className="text-red-500 text-sm">{errorFinanceiro && 'Campo obrigatório'}</span>
                                                    </div>
                                                    <div className="sm:col-span-2">
                                                        <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Possuí agenda?</label>
                                                        <select
                                                            id="areaCliente"
                                                            name="areaCliente"
                                                            value={agenda}
                                                            onChange={e => {
                                                                if (e.target.value === '') setErrorAgenda(true)
                                                                else setErrorAgenda(false)
                                                                setAgenda(e.target.value)
                                                            }}
                                                            className='mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm'
                                                        >
                                                            <option value={''}>Selecione ..</option>
                                                            <option value={true}>Sim</option>
                                                            <option value={false}>Não</option>
                                                        </select>
                                                        <span className="text-red-500 text-sm">{errorAgenda && 'Campo obrigatório'}</span>
                                                    </div>
                                                    <div className="sm:col-span-2">
                                                        <label htmlFor="descricao" className="block text-sm font-semibold leading-5 text-gray-700">Descrição</label>
                                                        <textarea
                                                            id="descricao"
                                                            name="descricao"
                                                            value={descricao}
                                                            onChange={e => {
                                                                if (e.target.value === '') setErrorDescricao(true)
                                                                else setErrorDescricao(false)
                                                                setDescricao(e.target.value)
                                                            }}
                                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                            placeholder="Digite a descrição do plano"
                                                        />
                                                        <span className="text-red-500 text-sm">{errorDescricao && 'Campo obrigatório'}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                                            <div>
                                                <h2 className="text-base font-semibold leading-7 text-gray-900">Informações das características</h2>
                                                <p className="mt-1 text-sm leading-6 text-gray-600">Dados do plano.</p>
                                            </div>
                                            <div className="sm:col-span-2">

                                                <div className="grid grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-3">
                                                    <div className="sm:col-span-1">
                                                        <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Clinica?</label>
                                                        <select
                                                            id="empresa"
                                                            name="empresa"
                                                            value={empresa}
                                                            onChange={e => setEmpresa(e.target.value)}
                                                            className='mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm'
                                                        >
                                                            <option value={''}>Selecione ..</option>
                                                            <option value={true}>Sim</option>
                                                            <option value={false}>Não</option>
                                                        </select>
                                                        <span className="text-red-500 text-sm">{errorEmpresa && 'Campo obrigatório'}</span>
                                                    </div>
                                                    <div className="sm:col-span-1">
                                                        <label htmlFor="descricao" className="block text-sm font-semibold leading-5 text-gray-700">Quantidade de usuários</label>
                                                        <input
                                                            type="text"
                                                            id="descricao"
                                                            name="descricao"
                                                            value={usuarios}
                                                            onChange={e => {
                                                                const onlyNumber = e.target.value.replace(/[^0-9]/g, '');
                                                                if (onlyNumber === '') setErrorUsuarios(true)
                                                                setUsuarios(onlyNumber)
                                                            }}
                                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                            placeholder="Digite a quantidade de usuários"
                                                        />
                                                        <span className="text-red-500 text-sm">{errorUsuarios && 'Campo obrigatório'}</span>
                                                    </div>
                                                    <div className="sm:col-span-1">
                                                        <label htmlFor="descricao" className="block text-sm font-semibold leading-5 text-gray-700">Quantidade de clientes</label>
                                                        <input
                                                            type="text"
                                                            id="descricao"
                                                            name="descricao"
                                                            value={clientes}
                                                            onChange={e => {
                                                                const onlyNumber = e.target.value.replace(/[^0-9]/g, '');
                                                                if (onlyNumber === '') setErrorClientes(true)
                                                                setClientes(onlyNumber)
                                                            }}
                                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                            placeholder="Digite a quantidade de clientes"
                                                        />
                                                        <span className="text-red-500 text-sm">{errorClientes && 'Campo obrigatório'}</span>
                                                    </div>
                                                </div>

                                                <div className="mt-8 grid grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-3">
                                                    <div className="sm:col-span-1">
                                                        <label htmlFor="descricao" className="block text-sm font-semibold leading-5 text-gray-700">Quantidade de psicologos</label>
                                                        <input
                                                            type="text"
                                                            id="descricao"
                                                            name="descricao"
                                                            value={prestadores}
                                                            onChange={e => {
                                                                if (empresa === 'false') {
                                                                    setErrorPrestadores(false)
                                                                    setPrestadores('1')
                                                                    return
                                                                }
                                                                const onlyNumber = e.target.value.replace(/[^0-9]/g, '');
                                                                if (onlyNumber === '') setErrorPrestadores(true)
                                                                setPrestadores(onlyNumber)
                                                            }}
                                                            disabled={empresa === 'false'}
                                                            className={empresa === 'false' ? "mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-gray-300 sm:text-sm" : "mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"}
                                                            placeholder="Digite a quantidade de psiologos"
                                                        />
                                                        <span className="text-red-500 text-sm">{empresa === 'true' && errorPrestadores && 'Campo obrigatório'}</span>
                                                    </div>
                                                    <div className="sm:col-span-1">
                                                        <label htmlFor="descricao" className="block text-sm font-semibold leading-5 text-gray-700">Quantidade de categorias</label>
                                                        <input
                                                            type="text"
                                                            id="descricao"
                                                            name="descricao"
                                                            value={categoria}
                                                            onChange={e => {
                                                                if (empresa === 'false') {
                                                                    setErrorCategoria(false)
                                                                    setCategoria('1')
                                                                    return
                                                                }
                                                                const onlyNumber = e.target.value.replace(/[^0-9]/g, '');
                                                                if (onlyNumber === '') setErrorCategoria(true)
                                                                setCategoria(onlyNumber)
                                                            }}
                                                            disabled={empresa === 'false'}
                                                            className={empresa === 'false' ? "mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-gray-300 sm:text-sm" : "mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"}
                                                            placeholder="Digite a quantidade de categorias"
                                                        />
                                                        <span className="text-red-500 text-sm">{empresa === 'true' && errorCategoria && 'Campo obrigatório'}</span>
                                                    </div>
                                                    <div className="sm:col-span-1">
                                                        <label htmlFor="descricao" className="block text-sm font-semibold leading-5 text-gray-700">Quantidade de serviços</label>
                                                        <input
                                                            type="text"
                                                            id="descricao"
                                                            name="descricao"
                                                            value={servicos}
                                                            onChange={e => {
                                                                const onlyNumber = e.target.value.replace(/[^0-9]/g, '');
                                                                if (onlyNumber === '') setErrorServicos(true)
                                                                setServicos(onlyNumber)
                                                            }}
                                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                            placeholder="Digite a quantidade de serviços"
                                                        />
                                                        <span className="text-red-500 text-sm">{errorServicos && 'Campo obrigatório'}</span>
                                                    </div>
                                                </div>

                                                <div className="mt-8 grid grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-3">
                                                    <div className="sm:col-span-1">
                                                        <label htmlFor="descricao" className="block text-sm font-semibold leading-5 text-gray-700">Preço</label>
                                                        <input
                                                            type="text"
                                                            id="descricao"
                                                            name="descricao"
                                                            value={preco}
                                                            onChange={e => {
                                                                const onlyNumber = e.target.value.replace(/[^0-9]/g, '');
                                                                if (onlyNumber === '') {
                                                                    setErrorPreco(true)
                                                                    setPreco('')
                                                                    return;
                                                                }
                                                                const formatToMoneyWithBrl = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(onlyNumber / 100)
                                                                setPreco(formatToMoneyWithBrl)
                                                            }}
                                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                            placeholder="Digite o preço do plano"
                                                        />
                                                        <span className="text-red-500 text-sm">{errorPreco && 'Campo obrigatório'}</span>
                                                    </div>
                                                    <div className="sm:col-span-1">
                                                        <label htmlFor="descricao" className="block text-sm font-semibold leading-5 text-gray-700">Frequência do plano (Mensal) </label>
                                                        <input
                                                            type="text"
                                                            id="descricao"
                                                            name="descricao"
                                                            value={frequencia}
                                                            onChange={e => {
                                                                const onlyNumber = e.target.value.replace(/[^0-9]/g, '');
                                                                if (onlyNumber === '') setErrorFrequencia(true)
                                                                setFrequencia(onlyNumber)
                                                            }}
                                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                            placeholder="Digite a quantidade de meses"
                                                        />
                                                        <span className="text-red-500 text-sm">{errorFrequencia && 'Campo obrigatório'}</span>
                                                    </div>
                                                    <div className="sm:col-span-1">
                                                        <label htmlFor="descricao" className="block text-sm font-semibold leading-5 text-gray-700">Meses com faturas em aberto</label>
                                                        <input
                                                            type="text"
                                                            id="descricao"
                                                            name="descricao"
                                                            value={tolerancia}
                                                            onChange={e => {
                                                                const onlyNumber = e.target.value.replace(/[^0-9]/g, '');
                                                                if (onlyNumber === '') setErrorTolerancia(true)
                                                                setTolerancia(onlyNumber)
                                                            }}
                                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                            placeholder="Digite a quantidade de meses"
                                                        />
                                                        <span className="text-red-500 text-sm">{errorTolerancia && 'Campo obrigatório'}</span>
                                                    </div>
                                                </div>





                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-6 flex items-center justify-end gap-x-6">
                                        <button type="button" className="text-sm font-semibold leading-5 text-gray-900 bg-gray-100 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-100"
                                            onClick={() => setters.setModalPlanos(false)}>
                                            Cancelar
                                        </button>
                                        <button
                                            onClick={handleSubmit}
                                            type="button"
                                            className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                        >
                                            {
                                                states.isEdit ? 'Editar' : 'Cadastrar'
                                            }
                                        </button>
                                    </div>
                                </div>


                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default ModalPLanos
