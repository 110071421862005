import { Fragment, useState, useEffect, useContext } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import moment from 'moment'

const ModalClientes = ({ states, setters, action, more }) => {




    /*
              
                data_inicio, metodo, plano_id,   compania,

                //nomeCliente, cpfCliente, numeroCliente, cepCliente, telefoneCliente, complementoCliente
    */

    const [planos, setPlanos] = useState([])

    const [nome, setNome] = useState('')
    const [cpf_cnpj, setCpfCnpj] = useState('')
    const [cep, setCep] = useState('')
    const [telefone, setTelefone] = useState('')
    const [email, setEmail] = useState('')
    const [complemento, setComplemento] = useState('')
    const [numero, setNumero] = useState('')
    const [data_inicio, setDataInicio] = useState('')
    const [metodo, setMetodo] = useState('')
    const [plano_id, setPlanoId] = useState('')
    const [compania, setCompania] = useState('')

    const [nomeCliente, setNomeCliente] = useState('')
    const [cpfCliente, setCpfCliente] = useState('')
    const [cepCliente, setCepCliente] = useState('')
    const [telefoneCliente, setTelefoneCliente] = useState('')
    const [complementoCliente, setComplementoCliente] = useState('')
    const [numeroCliente, setNumeroCliente] = useState('')

    const [errorNomeCliente, setErrorNomeCliente] = useState(false)
    const [errorCpfCliente, setErrorCpfCliente] = useState(false)
    const [errorCepCliente, setErrorCepCliente] = useState(false)
    const [errorTelefoneCliente, setErrorTelefoneCliente] = useState(false)
    const [errorComplementoCliente, setErrorComplementoCliente] = useState(false)
    const [errorNumeroCliente, setErrorNumeroCliente] = useState(false)

    const [errorNome, setErrorNome] = useState(false)
    const [errorCpfCnpj, setErrorCpfCnpj] = useState(false)
    const [errorCep, setErrorCep] = useState(false)
    const [errorTelefone, setErrorTelefone] = useState(false)
    const [errorEmail, setErrorEmail] = useState(false)
    const [errorComplemento, setErrorComplemento] = useState(false)
    const [errorNumero, setErrorNumero] = useState(false)
    const [errorDataInicio, setErrorDataInicio] = useState(false)
    const [errorDataInicioMsg, setErrorDataInicioMsg] = useState('Campo obrigatório')
    const [errorMetodo, setErrorMetodo] = useState(false)
    const [errorPlanoId, setErrorPlanoId] = useState(false)
    const [errorCompania, setErrorCompania] = useState(false)


    const handleSubmit = async () => {
        let error = false

        if (nome === '') {
            setErrorNome(true)
            error = true
        } else setErrorNome(false)

        if (cpf_cnpj === '') {
            setErrorCpfCnpj(true)
            error = true
        } else setErrorCpfCnpj(false)

        if (cep === '') {
            setErrorCep(true)
            error = true
        } else setErrorCep(false)

        if (telefone === '') {
            setErrorTelefone(true)
            error = true
        } else setErrorTelefone(false)

        if (email === '') {
            setErrorEmail(true)
            error = true
        } else setErrorEmail(false)

        if (complemento === '') {
            setComplemento('N/A')
        }

        if (numero === '') {
            setErrorNumero(true)
            error = true
        } else setErrorNumero(false)


        if (!states.isEdit) {
            if (data_inicio === '') {
                setErrorDataInicio(true)
                setErrorDataInicioMsg('Campo obrigatório')
                error = true
            } else if (moment(data_inicio).isBefore(moment())) {
                setErrorDataInicio(true)
                setErrorDataInicioMsg('Data não pode ser menor que a data atual')
                error = true
            } else {
                setErrorDataInicio(false)
                setErrorDataInicioMsg('Campo obrigatório')
            }

            if (metodo === '') {
                setErrorMetodo(true)
                error = true
            } else setErrorMetodo(false)

            if (plano_id === '') {
                setErrorPlanoId(true)
                error = true
            } else setErrorPlanoId(false)
        }

        if (compania === '') {
            setErrorCompania(true)
            error = true
        } else setErrorCompania(false)

        if (compania === 'true' && !states.isEdit) {
            if (nomeCliente === '') {
                setErrorNomeCliente(true)
                error = true
            } else setErrorNomeCliente(false)
            if (cpfCliente === '') {
                setErrorCpfCliente(true)
                error = true
            } else setErrorCpfCliente(false)
            if (cepCliente === '') {
                setErrorCepCliente(true)
                error = true
            } else setErrorCepCliente(false)
            if (telefoneCliente === '') {
                setErrorTelefoneCliente(true)
                error = true
            } else setErrorTelefoneCliente(false)
            if (complementoCliente === '') {
                setErrorComplementoCliente(true)
                error = true
            } else setErrorComplementoCliente(false)
            if (numeroCliente === '') {
                setErrorNumeroCliente(true)
                error = true
            } else setErrorNumeroCliente(false)
        } else {
            setNomeCliente('')
            setCpfCliente('')
            setCepCliente('')
            setTelefoneCliente('')
            setComplementoCliente('')
            setNumeroCliente('')
            setErrorNomeCliente(false)
            setErrorCpfCliente(false)
            setErrorCepCliente(false)
            setErrorTelefoneCliente(false)
            setErrorComplementoCliente(false)
            setErrorNumeroCliente(false)
        }

        if (error) {
            error = false
            return
        }
        let obj = {};
        if (compania === 'true' && !states.isEdit) {
            obj = {
                nome, cpf_cnpj, cep, telefone, email, complemento, numero, data_inicio, metodo, plano_id, compania,
                nomeCliente, cpfCliente, cepCliente, telefoneCliente, complementoCliente, numeroCliente
            }
        }
        if (compania === 'false' && !states.isEdit) {
            obj = {
                nome, cpf_cnpj, cep, telefone, email, complemento, numero, data_inicio, metodo, plano_id, compania
            };
        }
        if (states.isEdit) {
            obj = {
                nome, cpf_cnpj, cep, telefone, email, complemento, numero, compania
            }
        }

        action(obj)




    }


    const formatTelefone = (value) => {
        if (value?.length === 11) {
            return value?.replace(/^(\d{2})(\d{5})(\d{4})/, '($1) $2-$3')
        } else {
            return value?.replace(/^(\d{2})(\d{4})(\d{4})/, '($1) $2-$3')
        }
    }

    const formatToCpfOrCnpj = (value) => {
        if (value?.length === 11) {
            return value?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
        } else {
            return value?.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
        }
    }

    const formatCep = (value) => {
        return value?.replace(/^(\d{5})(\d{3})/, '$1-$2')
    }


    useEffect(() => {
        if (states.modalClientes === false) {
            setNome('')
            setCpfCnpj('')
            setCep('')
            setTelefone('')
            setEmail('')
            setComplemento('')
            setNumero('')
            setDataInicio('')
            setMetodo('')
            setPlanoId('')
            setCompania('')
            setNomeCliente('')
            setCpfCliente('')
            setCepCliente('')
            setTelefoneCliente('')
            setComplementoCliente('')
            setNumeroCliente('')
            setErrorDataInicioMsg('Campo obrigatório')
            setErrorNomeCliente(false)
            setErrorCpfCliente(false)
            setErrorCepCliente(false)
            setErrorTelefoneCliente(false)
            setErrorComplementoCliente(false)
            setErrorNumeroCliente(false)
            setErrorNome(false)
            setErrorCpfCnpj(false)
            setErrorCep(false)
            setErrorTelefone(false)
            setErrorEmail(false)
            setErrorComplemento(false)
            setErrorNumero(false)
            setErrorDataInicio(false)
            setErrorMetodo(false)
            setErrorPlanoId(false)
            setErrorCompania(false)
        } else {
            setPlanos(states.dataPlanos)
            if (states.isEdit) {
                setNome(states.Clientes.nome)
                setCpfCnpj(states.Clientes.cpf_cnpj)
                setCep(states.Clientes.cep)
                setTelefone(states.Clientes.telefone)
                setEmail(states.Clientes.email)
                setComplemento(states.Clientes.complemento)
                setNumero(states.Clientes.numero)
                setCompania(states.Clientes.compania === 1 ? 'true' : 'false')
            }
        }

    }, [states.modalClientes])


    useEffect(() => {
        if (compania === "true") {
            const planosFiltrados = states.dataPlanos.filter(item => item.empresa === 1)
            setPlanos(planosFiltrados)
        } else {
            const planosFiltrados = states.dataPlanos.filter(item => item.empresa === 0)
            setPlanos(planosFiltrados)
        }
    }, [compania]);



    return (
        <Transition.Root show={states.modalClientes} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => setters.setModalClientes(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full w-[100%] itens-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            {/* Mexer na altura, só colocar o height na classname abaixo */}
                            <Dialog.Panel className="w-[80%] transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                        onClick={() => setters.setModalClientes(false)}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                                <div className='flex-col border-b border-gray-900/10'>
                                    <h3 className="text-base font-semibold leading-6 text-gray-900 text-center">Clientes</h3>
                                    <p className="flex mt-1 text-sm text-gray-500 justify-center mb-5">
                                        Adicionar um novo cliente
                                    </p>
                                </div>
                                <div className='pt-10 pl-10 pr-10'>
                                    <div className="space-y-12">
                                        <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                                            <div>
                                                <h2 className="text-base font-semibold leading-7 text-gray-900">Informações principais</h2>
                                                <p className="mt-1 text-sm leading-6 text-gray-600">Dados principais do cliente.</p>
                                            </div>
                                            <div className="sm:col-span-2">
                                                <div className="grid grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-4">
                                                    <div className="sm:col-span-1">
                                                        <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Nome</label>
                                                        <input
                                                            type="text"
                                                            id="title"
                                                            name="title"
                                                            value={nome}
                                                            maxLength={255}
                                                            onChange={e => {
                                                                if (e.target.value === '') setErrorNome(true)
                                                                else setErrorNome(false)
                                                                setNome(e.target.value)
                                                            }}
                                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                            placeholder="Digite o nome da entidade"
                                                        />
                                                        <span className="text-red-500 text-sm">{errorNome && 'Campo obrigatório'}</span>
                                                    </div>
                                                    <div className="sm:col-span-1">
                                                        <label htmlFor="descricao" className="block text-sm font-semibold leading-5 text-gray-700">Cpf/Cnpj</label>
                                                        <input
                                                            type="text"
                                                            id="descricao"
                                                            name="descricao"
                                                            value={formatToCpfOrCnpj(cpf_cnpj)}
                                                            maxLength={255}
                                                            onChange={e => {
                                                                if (e.target.value === '') setErrorCpfCnpj(true)
                                                                else setErrorCpfCnpj(false)
                                                                const onlyNumber = e.target.value.replace(/[^0-9]/g, '');
                                                                setCpfCnpj(onlyNumber)
                                                            }}
                                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                            placeholder="Digite o cpf/cnpj"
                                                        />
                                                        <span className="text-red-500 text-sm">{errorCpfCnpj && 'Campo obrigatório'}</span>
                                                    </div>
                                                    <div className="sm:col-span-1">
                                                        <label htmlFor="descricao" className="block text-sm font-semibold leading-5 text-gray-700">Cep</label>
                                                        <input
                                                            type="text"
                                                            id="descricao"
                                                            name="descricao"
                                                            value={formatCep(cep)}
                                                            maxLength={255}
                                                            onChange={e => {
                                                                if (e.target.value === '') setErrorCep(true)
                                                                else setErrorCep(false)
                                                                const onlyNumber = e.target.value.replace(/[^0-9]/g, '');
                                                                setCep(onlyNumber)
                                                            }}
                                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                            placeholder="Digite o cep"
                                                        />
                                                        <span className="text-red-500 text-sm">{errorCep && 'Campo obrigatório'}</span>
                                                    </div>
                                                    <div className="sm:col-span-1">
                                                        <label htmlFor="descricao" className="block text-sm font-semibold leading-5 text-gray-700">Telefone</label>
                                                        <input
                                                            type="text"
                                                            id="descricao"
                                                            name="descricao"
                                                            value={formatTelefone(telefone)}
                                                            maxLength={255}
                                                            onChange={e => {
                                                                if (e.target.value === '') setErrorTelefone(true)
                                                                else setErrorTelefone(false)
                                                                const onlyNumber = e.target.value.replace(/[^0-9]/g, '');
                                                                setTelefone(onlyNumber)
                                                            }}
                                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                            placeholder="Digite o telefone"
                                                        />
                                                        <span className="text-red-500 text-sm">{errorTelefone && 'Campo obrigatório'}</span>
                                                    </div>
                                                </div>
                                                <div className="grid grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-3 mt-8">
                                                    <div className="sm:col-span-1">
                                                        <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">E-mail</label>
                                                        <input
                                                            type="text"
                                                            id="title"
                                                            name="title"
                                                            value={email}
                                                            maxLength={255}
                                                            onChange={e => {
                                                                if (e.target.value === '') setErrorEmail(true)
                                                                else setErrorEmail(false)
                                                                setEmail(e.target.value)
                                                            }}
                                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                            placeholder="Digite o e-mail do responsável"
                                                        />
                                                        <span className="text-red-500 text-sm">{errorEmail && 'Campo obrigatório'}</span>
                                                    </div>
                                                    <div className="sm:col-span-1">
                                                        <label htmlFor="descricao" className="block text-sm font-semibold leading-5 text-gray-700">Complemento</label>
                                                        <input
                                                            type="text"
                                                            id="descricao"
                                                            name="descricao"
                                                            value={complemento}
                                                            maxLength={255}
                                                            onChange={e => {
                                                                setComplemento(e.target.value)
                                                            }}
                                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                            placeholder="Digite o complemento"
                                                        />
                                                        <span className="text-red-500 text-sm">{errorComplemento && 'Campo obrigatório'}</span>
                                                    </div>
                                                    <div className="sm:col-span-1">
                                                        <label htmlFor="descricao" className="block text-sm font-semibold leading-5 text-gray-700">Número do local</label>
                                                        <input
                                                            type="text"
                                                            id="descricao"
                                                            name="descricao"
                                                            value={numero}
                                                            maxLength={255}
                                                            onChange={e => {
                                                                if (e.target.value === '') setErrorNumero(true)
                                                                else setErrorNumero(false)
                                                                const onlyNumber = e.target.value.replace(/[^0-9]/g, '');
                                                                setNumero(onlyNumber)
                                                            }}
                                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                            placeholder="Digite o número do local"
                                                        />
                                                        <span className="text-red-500 text-sm">{errorNumero && 'Campo obrigatório'}</span>
                                                    </div>
                                                </div>
                                                <div className="grid grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-4 mt-8">
                                                    <div className="sm:col-span-1">
                                                        <label htmlFor="descricao" className="block text-sm font-semibold leading-5 text-gray-700">Clínica?</label>
                                                        <select
                                                            id="empresa"
                                                            name="empresa"
                                                            value={compania}
                                                            onChange={e => {
                                                                if (e.target.value === '') setErrorCompania(true)
                                                                else setErrorCompania(false)
                                                                setCompania(e.target.value)
                                                            }}
                                                            className='mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm'
                                                        >
                                                            <option value={''}>Selecione ..</option>
                                                            <option value={true}>Sim</option>
                                                            <option value={false}>Não</option>
                                                        </select>
                                                        <span className="text-red-500 text-sm">{errorCompania && 'Campo obrigatório'}</span>
                                                    </div>
                                                    {
                                                        !states.isEdit && (
                                                            <>
                                                                <div className="sm:col-span-1">
                                                                    <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Data de início</label>
                                                                    <input
                                                                        type="date"
                                                                        id="title"
                                                                        name="title"
                                                                        value={data_inicio}
                                                                        maxLength={255}
                                                                        onChange={e => {
                                                                            if (e.target.value === '') {
                                                                                setErrorDataInicio(true)
                                                                                setErrorDataInicioMsg('Campo obrigatório')
                                                                            } else {
                                                                                setErrorDataInicio(false)
                                                                                setErrorDataInicioMsg('Campo obrigatório')
                                                                            }
                                                                            setDataInicio(e.target.value)
                                                                        }}
                                                                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                                        placeholder="Digite o nome da entidade"
                                                                    />
                                                                    <span className="text-red-500 text-sm">{errorDataInicio && errorDataInicioMsg}</span>
                                                                </div>
                                                                <div className="sm:col-span-1">
                                                                    <label htmlFor="descricao" className="block text-sm font-semibold leading-5 text-gray-700">Método</label>
                                                                    <select
                                                                        id="descricao"
                                                                        name="descricao"
                                                                        value={metodo}
                                                                        onChange={e => {
                                                                            if (e.target.value === '') setErrorMetodo(true)
                                                                            else setErrorMetodo(false)
                                                                            setMetodo(e.target.value)
                                                                        }}
                                                                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                                    >
                                                                        <option value={''}>Selecione ..</option>
                                                                        <option value={'pix'}>Pix</option>
                                                                    </select>
                                                                    <span className="text-red-500 text-sm">{errorMetodo && 'Campo obrigatório'}</span>
                                                                </div>
                                                                {
                                                                    compania !== '' && (
                                                                        <div className="sm:col-span-1">
                                                                            <label htmlFor="descricao" className="block text-sm font-semibold leading-5 text-gray-700">Plano</label>
                                                                            <select
                                                                                id="descricao"
                                                                                name="descricao"
                                                                                value={plano_id}
                                                                                onChange={e => {
                                                                                    if (e.target.value === '') setErrorPlanoId(true)
                                                                                    else setErrorPlanoId(false)
                                                                                    setPlanoId(e.target.value)
                                                                                }}
                                                                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                                            >
                                                                                <option value={''}>Selecione ..</option>
                                                                                {
                                                                                    planos.map((item, index) => {
                                                                                        if (compania === 'true' && item.empresa === 0) {
                                                                                            return null
                                                                                        }
                                                                                        return (
                                                                                            <option key={index} value={item.id}>{item.nome}</option>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </select>
                                                                            <span className="text-red-500 text-sm">{errorPlanoId && 'Campo obrigatório'}</span>
                                                                        </div>
                                                                    )
                                                                }
                                                            </>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            (compania === 'true' && !states.isEdit) && (
                                                <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                                                    <div>
                                                        <h2 className="text-base font-semibold leading-7 text-gray-900">Dados do cliente responsável pela clínica</h2>
                                                        <p className="mt-1 text-sm leading-6 text-gray-600">Dados principais do cliente.</p>
                                                    </div>
                                                    <div className="sm:col-span-2">
                                                        <div className="grid grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-3">
                                                            <div className="sm:col-span-1">
                                                                <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Nome</label>
                                                                <input
                                                                    type="text"
                                                                    id="title"
                                                                    name="title"
                                                                    value={nomeCliente}
                                                                    maxLength={255}
                                                                    onChange={e => {
                                                                        if (e.target.value === '') setErrorNomeCliente(true)
                                                                        else setErrorNomeCliente(false)
                                                                        setNomeCliente(e.target.value)
                                                                    }}
                                                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                                    placeholder="Digite o nome da entidade"
                                                                />
                                                                <span className="text-red-500 text-sm">{errorNomeCliente && 'Campo obrigatório'}</span>
                                                            </div>
                                                            <div className="sm:col-span-1">
                                                                <label htmlFor="descricao" className="block text-sm font-semibold leading-5 text-gray-700">Cpf</label>
                                                                <input
                                                                    type="text"
                                                                    id="descricao"
                                                                    name="descricao"
                                                                    value={formatToCpfOrCnpj(cpfCliente)}
                                                                    maxLength={255}
                                                                    onChange={e => {
                                                                        if (e.target.value === '') setErrorCpfCliente(true)
                                                                        else setErrorCpfCliente(false)
                                                                        const onlyNumber = e.target.value.replace(/[^0-9]/g, '');
                                                                        setCpfCliente(onlyNumber)
                                                                    }}
                                                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                                    placeholder="Digite o cpf/cnpj"
                                                                />
                                                                <span className="text-red-500 text-sm">{errorCpfCliente && 'Campo obrigatório'}</span>
                                                            </div>
                                                            <div className="sm:col-span-1">
                                                                <label htmlFor="descricao" className="block text-sm font-semibold leading-5 text-gray-700">Cep</label>
                                                                <input
                                                                    type="text"
                                                                    id="descricao"
                                                                    name="descricao"
                                                                    value={formatCep(cepCliente)}
                                                                    maxLength={255}
                                                                    onChange={e => {
                                                                        if (e.target.value === '') setErrorCepCliente(true)
                                                                        else setErrorCepCliente(false)
                                                                        const onlyNumber = e.target.value.replace(/[^0-9]/g, '');
                                                                        setCepCliente(onlyNumber)
                                                                    }}
                                                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                                    placeholder="Digite o cep"
                                                                />
                                                                <span className="text-red-500 text-sm">{errorCepCliente && 'Campo obrigatório'}</span>
                                                            </div>
                                                        </div>

                                                        <div className="grid grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-3 mt-8">
                                                            <div className="sm:col-span-1">
                                                                <label htmlFor="descricao" className="block text-sm font-semibold leading-5 text-gray-700">Telefone</label>
                                                                <input
                                                                    type="text"
                                                                    id="descricao"
                                                                    name="descricao"
                                                                    value={formatTelefone(telefoneCliente)}
                                                                    maxLength={255}
                                                                    onChange={e => {
                                                                        if (e.target.value === '') setErrorTelefoneCliente(true)
                                                                        else setErrorTelefoneCliente(false)
                                                                        const onlyNumber = e.target.value.replace(/[^0-9]/g, '');
                                                                        setTelefoneCliente(onlyNumber)
                                                                    }}
                                                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                                    placeholder="Digite o telefone"
                                                                />
                                                                <span className="text-red-500 text-sm">{errorTelefoneCliente && 'Campo obrigatório'}</span>
                                                            </div>
                                                            <div className="sm:col-span-1">
                                                                <label htmlFor="descricao" className="block text-sm font-semibold leading-5 text-gray-700">Complemento</label>
                                                                <input
                                                                    type="text"
                                                                    id="descricao"
                                                                    name="descricao"
                                                                    value={complementoCliente}
                                                                    maxLength={255}
                                                                    onChange={e => {
                                                                        if (e.target.value === '') setErrorComplementoCliente(true)
                                                                        else setErrorComplementoCliente(false)
                                                                        setComplementoCliente(e.target.value)
                                                                    }}
                                                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                                    placeholder="Digite o complemento"
                                                                />
                                                                <span className="text-red-500 text-sm">{errorComplementoCliente && 'Campo obrigatório'}</span>
                                                            </div>
                                                            <div className="sm:col-span-1">
                                                                <label htmlFor="descricao" className="block text-sm font-semibold leading-5 text-gray-700">Número do local</label>
                                                                <input
                                                                    type="text"
                                                                    id="descricao"
                                                                    name="descricao"
                                                                    value={numeroCliente}
                                                                    maxLength={255}
                                                                    onChange={e => {
                                                                        if (e.target.value === '') setErrorNumeroCliente(true)
                                                                        else setErrorNumeroCliente(false)
                                                                        const onlyNumber = e.target.value.replace(/[^0-9]/g, '');
                                                                        setNumeroCliente(onlyNumber)
                                                                    }}
                                                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                                    placeholder="Digite o número do local"
                                                                />
                                                                <span className="text-red-500 text-sm">{errorNumeroCliente && 'Campo obrigatório'}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                    <div className="mt-6 flex items-center justify-end gap-x-6">
                                        <button type="button" className="text-sm font-semibold leading-5 text-gray-900 bg-gray-100 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-100"
                                            onClick={() => setters.setModalClientes(false)}>
                                            Cancelar
                                        </button>
                                        <button
                                            onClick={handleSubmit}
                                            type="button"
                                            className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                        >
                                            {
                                                states.isEdit ? 'Editar' : 'Cadastrar'
                                            }
                                        </button>
                                    </div>
                                </div>


                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default ModalClientes
