import { createContext, useContext } from "react";
import { AuthContext } from ".";
import { toast } from 'react-toastify';
import { conection } from '../utils/index'
import moment from "moment";


export const AuthContextApi = createContext();


const AuthProvider = ({ children }) => {

    const { states, setters } = useContext(AuthContext);
    const axiosAuth = conection.auth();
    const axiosNoAuth = conection.noAuth();


    const login = async (data) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosNoAuth.post('/user/login', data)
            localStorage.setItem('token', response.data.data.token)
            localStorage.setItem('permisssion', response.data.data.permissao)
            localStorage.setItem('cliente', response.data.data.client)
            localStorage.setItem('usuario', response.data.data.user)
            setters.setSigned(true)
            setters.setIsLoading(false)
            toast.success(response.data.msg)
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false;
        }
    }

    const checkLogin = async () => {
        try {
            await axiosAuth.get('/user/check')
            return true;
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error(err.response.data.msg)
            } else {
                toast.error(err.response.data.msg)
            }
        }
    }

    const forgotPass = async (data) => {
        setters.setIsLoading(true)
        try {
            await axiosNoAuth.post('/user/forgotPassword', data)
            setters.setIsLoading(false)
            return true;
        } catch (err) {
            setters.setIsLoading(false)
            return false;
        }
    }

    const forgotChangePass = async (data) => {
        setters.setIsLoading(true)
        try {
            await axiosNoAuth.post('/user/forgotChangePass', data)
            setters.setIsLoading(false)
            return true;
        } catch (err) {
            setters.setIsLoading(false)
            return false;
        }
    }

    const logout = async () => {
        setters.setIsLoading(true)
        try {
            await axiosAuth.get('/user/logOut')
            setters.setIsLoading(false)
            localStorage.removeItem('token')
            localStorage.removeItem('permisssion')
            localStorage.removeItem('cliente')
            localStorage.removeItem('usuario')
            setters.setSigned(false)
            return true;
        } catch (err) {
            setters.setIsLoading(false)
            return false;
        }
    }


    //#region Planos 

    const getPlanosDataSistema = async () => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get('/dadosSistema/planos')
            setters.setIsLoading(false)
            setters.setDataEstatisticaPlanos(response.data.data)
        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                toast.error(err.response.data.msg)
            }
        }
    }

    const getPlanos = async () => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/planos/getWithLimit/${states.limitePlanos}&${states.offsetPlanos}`)
            setters.setIsLoading(false)
            setters.setDataPlanos(response.data.data)
            if (states.dataPlanos.length === 0) toast.success('Planos carregados com sucesso')
        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                setters.setLimiteBuscaPlanos(true)
                if (states.offsetPlanos > 0) {
                    setters.setOffsetPlanos(states.offsetPlanos - states.limitePlanos)
                }
                if (states.offsetPlanos < 0) {
                    setters.setOffsetPlanos(0)
                }
                toast.error(err.response.data.msg)
            }
        }
    }

    const getPlanosList = async () => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/planos/getWithLimit/${states.limitePlanos}&${states.offsetPlanos}`)
            setters.setIsLoading(false)
            if (states.dataPlanos.length > 0) toast.success('Planos carregados com sucesso')
            if (states.dataPlanos.length === 0) {
                setters.setDataPlanos(response.data.data)
            } else {
                const getOutPlans = response.data.data.filter((item) => {
                    return states.dataPlanos.find((item2) => item2.id === item.id) === undefined
                })
                setters.setDataPlanos([...states.dataPlanos, ...getOutPlans])
            }
            return true
        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                setters.setLimiteBuscaPlanos(true)
                if (states.offsetPlanos > 0) {
                    setters.setOffsetPlanos(states.offsetPlanos - states.limitePlanos)
                }
                if (states.offsetPlanos < 0) {
                    setters.setOffsetPlanos(0)
                }
                toast.error(err.response.data.msg)
            }
            return false;
        }
    }

    const getPlanosQuery = async (query) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/planos/query/${query}`)
            setters.setIsLoading(false)
            setters.setDataPlanos(response.data.data)
            if (states.dataPlanos.length === 0) toast.success('Planos carregados com sucesso')
            return true;
        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                toast.error(err.response.data.msg)
            }
            return false;
        }
    }

    const postPlano = async (data) => {
        setters.setIsLoading(true)
        try {
            await axiosAuth.post('/planos', data)
            setters.setIsLoading(false)
            toast.success('Plano cadastrado com sucesso')
            await getPlanos()
            return true;
        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            }
            toast.error(err.response.data.msg)
            return false;
        }
    }

    const putPlano = async (data) => {
        setters.setIsLoading(true)
        try {
            await axiosAuth.put(`/planos/${states.Planos.id}`, data)
            setters.setIsLoading(false)
            setters.setPlanos({})
            toast.success('Plano editado com sucesso')
            await getPlanos()
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            }
            toast.error(err.response.data.msg)
            return false;
        }
    }

    const deletePlano = async (id) => {
        setters.setIsLoading(true)
        try {
            await axiosAuth.delete(`/planos/${id}`)
            setters.setIsLoading(false)
            setters.setPlanos({})
            toast.success('Plano deletado com sucesso')
            await getPlanos()
            return true;
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false;
        }
    }

    //#endregion

    //#region Assinaturas

    const getAssinaturasDataSistema = async () => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get('/dadosSistema/assinaturas')
            setters.setIsLoading(false)
            setters.setDataEstatisticaAssinaturas(response.data.data)
        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                toast.error(err.response.data.msg)
            }
        }
    }

    const getAssinaturas = async () => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/assinaturas/getWithLimit/${states.limiteAssinaturas}&${states.offsetAssinaturas}`)
            setters.setIsLoading(false)
            setters.setDataAssinaturas(response.data.data)
            if (states.dataAssinaturas.length === 0) toast.success('Assinaturas carregados com sucesso')
        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                setters.setLimiteBuscaAssinaturas(true)
                if (states.offsetAssinaturas > 0) {
                    setters.setOffsetAssinaturas(states.offsetAssinaturas - states.limiteAssinaturas)
                }
                if (states.offsetAssinaturas < 0) {
                    setters.setOffsetAssinaturas(0)
                }
                toast.error(err.response.data.msg)
            }
        }
    }

    const getAssinaturasQuery = async (query) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/assinaturas/${query}/getWithLimit/${states.limiteAssinaturas}&${states.offsetAssinaturas}`)
            setters.setIsLoading(false)
            setters.setDataAssinaturas(response.data.data)
            if (states.dataAssinaturas.length === 0) toast.success('Assinaturas carregados com sucesso')
            return true;
        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                setters.setLimiteBuscaAssinaturas(true)
                if (states.offsetAssinaturas > 0) {
                    setters.setOffsetAssinaturas(states.offsetAssinaturas - states.limiteAssinaturas)
                }
                if (states.offsetAssinaturas < 0) {
                    setters.setOffsetAssinaturas(0)
                }
                toast.error(err.response.data.msg)
            }
            return false;
        }
    }


    const putAssinatura = async (data) => {
        setters.setIsLoading(true)
        try {
            await axiosAuth.put(`/assinaturas/${states.Assinaturas.id}`, data)
            setters.setIsLoading(false)
            setters.setAssinaturas({})
            setters.setOffsetAssinaturas(0)
            toast.success('Plano editado com sucesso')
            await getAssinaturas()
            return true;
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false;
        }
    }

    const cancelAssinatura = async (id) => {
        setters.setIsLoading(true)
        try {
            await axiosAuth.put(`/cancelamento/assinaturas/${id}`, {})
            setters.setIsLoading(false)
            setters.setAssinaturas({})
            setters.setOffsetAssinaturas(0)
            toast.success('Assinatura cancelada com sucesso')
            await getAssinaturas()
            return true;
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false;
        }
    }

    const activeAssinatura = async (id) => {
        setters.setIsLoading(true)
        try {
            await axiosAuth.put(`/ativar/assinaturas/${id}`, {})
            setters.setIsLoading(false)
            setters.setAssinaturas({})
            setters.setOffsetAssinaturas(0)
            toast.success('Assinatura ativada com sucesso')
            await getAssinaturas()
            return true;
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false;
        }
    }

    //#endregion


    //#region Recuperação de Senha

    const getRecuperacaoDeSenhaDataSistema = async () => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get('/dadosSistema/recuperacaoSenha')
            setters.setIsLoading(false)
            setters.setDataEstatisticaRecuperacaoSenha(response.data.data)
        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                toast.error(err.response.data.msg)
            }
        }
    }

    const getRecuperacaoSenha = async () => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/userForgot/getWithLimit/${states.limiteRecuperacaoSenha}&${states.offsetRecuperacaoSenha}`)
            setters.setIsLoading(false)
            setters.setDataRecuperacaoSenha(response.data.data)
            if (states.dataRecuperacaoSenha.length === 0) toast.success('Recuperações de senhas carregados com sucesso')
        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                setters.setLimiteBuscaRecuperacaoSenha(true)
                if (states.offsetRecuperacaoSenha > 0) {
                    setters.setOffsetRecuperacaoSenha(states.offsetRecuperacaoSenha - states.limiteRecuperacaoSenha)
                }
                if (states.offsetRecuperacaoSenha < 0) {
                    setters.setOffsetRecuperacaoSenha(0)
                }
                toast.error(err.response.data.msg)
            }
        }
    }

    const getRecuperacaoSenhaQuery = async (query) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/userForgot/${query}/getWithLimit/${states.limiteRecuperacaoSenha}&${states.offsetRecuperacaoSenha}`)
            setters.setIsLoading(false)
            setters.setDataRecuperacaoSenha(response.data.data)
            if (states.dataRecuperacaoSenha.length === 0) toast.success('Recuperações de senhas carregados com sucesso')
            return true;
        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                setters.setLimiteBuscaRecuperacaoSenha(true)
                if (states.offsetRecuperacaoSenha > 0) {
                    setters.setOffsetRecuperacaoSenha(states.offsetRecuperacaoSenha - states.limiteRecuperacaoSenha)
                }
                if (states.offsetRecuperacaoSenha < 0) {
                    setters.setOffsetRecuperacaoSenha(0)
                }
                toast.error(err.response.data.msg)
            }
            return false;
        }
    }

    //#endregion

    //#region Usuários

    const getUsuariosDataSistema = async () => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get('/dadosSistema/usuarios')
            setters.setIsLoading(false)
            setters.setDataEstatisticaUsuarios(response.data.data)
        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                toast.error(err.response.data.msg)
            }
        }
    }

    const getUsuarios = async () => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/user/getWithLimit/${states.limiteUsuarios}&${states.offsetUsuarios}`)
            setters.setIsLoading(false)
            setters.setDataUsuarios(response.data.data)
            if (states.dataUsuarios.length === 0) toast.success('Usuarios carregados com sucesso')
        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                setters.setLimiteBuscaUsuarios(true)
                if (states.offsetUsuarios > 0) {
                    setters.setOffsetUsuarios(states.offsetUsuarios - states.limiteUsuarios)
                }
                if (states.offsetUsuarios < 0) {
                    setters.setOffsetUsuarios(0)
                }
                toast.error(err.response.data.msg)
            }
        }
    }

    const getUsuariosQuery = async (query) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/user/${query}/getWithLimit/${states.limiteUsuarios}&${states.offsetUsuarios}`)
            setters.setIsLoading(false)
            setters.setDataUsuarios(response.data.data)
            if (states.dataUsuarios.length === 0) toast.success('Usuarios carregados com sucesso')
            return true;
        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                setters.setLimiteBuscaUsuarios(true)
                if (states.offsetUsuarios > 0) {
                    setters.setOffsetUsuarios(states.offsetUsuarios - states.limiteUsuarios)
                }
                if (states.offsetUsuarios < 0) {
                    setters.setOffsetUsuarios(0)
                }
                toast.error(err.response.data.msg)
            }
            return false;
        }
    }

    const postUsuarios = async (data) => {
        setters.setIsLoading(true)
        try {
            await axiosAuth.post('/user/create', data)
            setters.setIsLoading(false)
            toast.success('Usuário cadastrado com sucesso')
            await getUsuarios()
            return true;
        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            }
            toast.error(err.response.data.msg)
            return false;
        }
    }

    const putRoleUsuarios = async (data) => {
        setters.setIsLoading(true)
        try {
            await axiosAuth.put(`/user/role/${states.Usuarios.id}`, data)
            setters.setIsLoading(false)
            setters.setUsuarios({})
            toast.success('Usuário editado com sucesso')
            await getUsuarios()
            return true;
        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            }
            toast.error(err.response.data.msg)
            return false;
        }
    }

    const blockUnlockUsuarios = async (id, status) => {
        setters.setIsLoading(true)
        try {
            await axiosAuth.put(`/user/blockUnlock/${id}`, {})
            setters.setIsLoading(false)
            setters.setUsuarios({})
            const msg = status ? 'Usuário desbloqueado com sucesso' : 'Usuário bloqueado com sucesso'
            toast.success(msg)
            await getUsuarios()
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            }
            toast.error(err.response.data.msg)
            return false;
        }
    }

    const deleteUsuarios = async (id, status) => {
        setters.setIsLoading(true)
        try {
            await axiosAuth.delete(`/user/${id}`)
            setters.setIsLoading(false)
            setters.setUsuarios({})
            toast.success('Usuário deletado com sucesso')
            await getUsuarios()
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            }
            toast.error(err.response.data.msg)
            return false;
        }
    }

    //#endregion

    //#region Logs

    const getLogsDataSistema = async () => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get('/dadosSistema/logs')
            setters.setIsLoading(false)
            setters.setDataEstatisticaLogs(response.data.data)
        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                toast.error(err.response.data.msg)
            }
        }
    }

    const getLogs = async () => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/logs/getWithLimit/${states.limiteLogs}&${states.offsetLogs}`)
            setters.setIsLoading(false)
            setters.setDataLogs(response.data.data)
            if (states.dataLogs.length === 0) toast.success('Logs carregados com sucesso')
        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                setters.setLimiteBuscaLogs(true)
                if (states.offsetLogs > 0) {
                    setters.setOffsetLogs(states.offsetLogs - states.limiteLogs)
                }
                if (states.offsetLogs < 0) {
                    setters.setOffsetLogs(0)
                }
                toast.error(err.response.data.msg)
            }
        }
    }

    //#endregion

    //#region Crons

    const getCronsDataSistema = async () => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get('/dadosSistema/cron')
            setters.setIsLoading(false)
            setters.setDataEstatisticaCrons(response.data.data)
        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                toast.error(err.response.data.msg)
            }
        }
    }

    const getCrons = async () => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/cron/getWithLimit/${states.limiteCrons}&${states.offsetCrons}`)
            setters.setIsLoading(false)
            setters.setDataCrons(response.data.data)
            if (states.dataCrons.length === 0) toast.success('Crons carregados com sucesso')
        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                setters.setLimiteBuscaCrons(true)
                if (states.offsetCrons > 0) {
                    setters.setOffsetCrons(states.offsetCrons - states.limiteCrons)
                }
                if (states.offsetCrons < 0) {
                    setters.setOffsetCrons(0)
                }
                toast.error(err.response.data.msg)
            }
        }
    }

    //#endregion


    //#region Faturas


    const getFaturasDataSistema = async () => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get('/dadosSistema/faturas')
            setters.setIsLoading(false)
            setters.setDataEstatisticaFaturas(response.data.data)
        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                toast.error(err.response.data.msg)
            }
        }
    }

    const getFaturas = async () => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/faturas/getWithLimit/${states.limiteFaturas}&${states.offsetFaturas}`)
            setters.setIsLoading(false)
            setters.setDataFaturas(response.data.data)
            if (states.dataFaturas.length === 0) toast.success('Faturas carregados com sucesso')
        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                setters.setLimiteBuscaFaturas(true)
                if (states.offsetFaturas > 0) {
                    setters.setOffsetFaturas(states.offsetFaturas - states.limiteFaturas)
                }
                if (states.offsetFaturas < 0) {
                    setters.setOffsetFaturas(0)
                }
                toast.error(err.response.data.msg)
            }
        }
    }

    const getFaturasQuery = async (query) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/faturas/${query}/getWithLimit/${states.limiteFaturas}&${states.offsetFaturas}`)
            setters.setIsLoading(false)
            setters.setDataFaturas(response.data.data)
            if (states.dataFaturas.length === 0) toast.success('Faturas carregados com sucesso')
            return true;
        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                setters.setLimiteBuscaFaturas(true)
                if (states.offsetFaturas > 0) {
                    setters.setOffsetFaturas(states.offsetFaturas - states.limiteFaturas)
                }
                if (states.offsetFaturas < 0) {
                    setters.setOffsetFaturas(0)
                }
                toast.error(err.response.data.msg)
            }
            return false;
        }
    }

    const genereteFatura = async (data) => {
        setters.setIsLoading(true)
        try {
            await axiosAuth.post('/faturas', data)
            setters.setIsLoading(false)
            toast.success('Fatura gerada com sucesso')
            await getFaturas()
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return false;
            }
            toast.error(err.response.data.msg)
            return false;
        }
    }

    const editFatura = async (data) => {
        setters.setIsLoading(true)
        try {
            await axiosAuth.put(`/faturas/${data.id}`, data)
            setters.setIsLoading(false)
            toast.success('Fatura editada com sucesso')
            await getFaturas()
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return false;
            }
            toast.error(err.response.data.msg)
            return false;
        }
    }

    const putFaturaBaixa = async (data, id) => {
        setters.setIsLoading(true)
        try {
            const header = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
            await axiosAuth.put(`/baixa/faturas/${id}`, data, header)
            setters.setIsLoading(false)
            setters.setFaturas({})
            toast.success('Baixa da fatura realizada com sucesso')
            await getFaturas()
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return false;
            }
            toast.error(err.response.data.msg)
            return false;
        }
    }


    const cancelarFatura = async (id) => {
        setters.setIsLoading(true)
        try {
            await axiosAuth.put(`/cancel/faturas/${id}`, {})
            setters.setIsLoading(false)
            toast.success('Fatura cancelada com sucesso')
            await getFaturas()
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return false;
            }
            toast.error(err.response.data.msg)
            return false;
        }
    }

    const reembolsoFatura = async (id) => {
        setters.setIsLoading(true)
        try {
            await axiosAuth.put(`/reembolso/faturas/${id}`, {})
            setters.setIsLoading(false)
            toast.success('Fatura reembolsada com sucesso')
            await getFaturas()
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return false;
            }
            toast.error(err.response.data.msg)
            return false;
        }
    }


    //#endregion


    //#region clientes 

    const getClientesByCpfCnpj = async (cpfCnpj) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/clientes/codigoRegistro/${cpfCnpj}`)
            setters.setIsLoading(false)
            setters.setClientes(response.data.data)
            if (states.dataFaturas.length === 0) toast.success('Faturas carregados com sucesso')
            return { status: true, data: response.data.data }
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                toast.error(err.response.data.msg)
            }
            return { status: false }
        }
    }

    const getClientesDataSistema = async () => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get('/dadosSistema/clientes')
            setters.setIsLoading(false)
            setters.setDataEstatisticaClientes(response.data.data)
        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                toast.error(err.response.data.msg)
            }
        }
    }

    const getClientes = async () => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/clientes/getWithLimit/${states.limiteClientes}&${states.offsetClientes}`)
            setters.setIsLoading(false)
            setters.setDataClientes(response.data.data)
            if (states.dataClientes.length === 0) toast.success('Clientes carregados com sucesso')
        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                setters.setLimiteBuscaClientes(true)
                if (states.offsetClientes > 0) {
                    setters.setOffsetClientes(states.offsetClientes - states.limiteClientes)
                }
                if (states.offsetClientes < 0) {
                    setters.setOffsetClientes(0)
                }
                toast.error(err.response.data.msg)
            }
        }
    }

    const getClientesQuery = async (query) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/clientes/${query}/getWithLimit/${states.limiteClientes}&${states.offsetClientes}`)
            setters.setIsLoading(false)
            setters.setDataClientes(response.data.data)
            if (states.dataClientes.length === 0) toast.success('Clientes carregados com sucesso')
            return true;
        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                setters.setLimiteBuscaClientes(true)
                if (states.offsetClientes > 0) {
                    setters.setOffsetClientes(states.offsetClientes - states.limiteClientes)
                }
                if (states.offsetClientes < 0) {
                    setters.setOffsetClientes(0)
                }
                toast.error(err.response.data.msg)
            }
            return false;
        }
    }

    const postCliente = async (data) => {
        setters.setIsLoading(true)
        try {
            await axiosAuth.post('/clientes', data)
            setters.setIsLoading(false)
            toast.success('Cliente cadastrado com sucesso')
            await getClientes()
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return false;
            }
            toast.error(err.response.data.msg)
            return false;
        }
    }

    const putCliente = async (data) => {
        setters.setIsLoading(true)
        try {
            await axiosAuth.put(`/clientes/${states.Clientes.id}`, data)
            setters.setIsLoading(false)
            toast.success('Cliente cadastrado com sucesso')
            setters.setClientes({})
            await getClientes()
            return true;
        } catch (err) {
            setters.setClientes({})
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return false;
            }
            toast.error(err.response.data.msg)
            return false;
        }
    }


    const getClienteById = async (id) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/clientes/${id}`)
            setters.setClientes(response.data.data)
            setters.setIsLoading(false)
            toast.success('Cliente carregado com sucesso')
            return true;
        } catch (err) {
            setters.setClientes({})
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return false;
            }
            toast.error(err.response.data.msg)
            return false;
        }
    }


    const getClientePainelDataSistema = async (id) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/dadosSistema/cliente/${id}`)
            setters.setIsLoading(false)
            setters.setDataEstatisticaPainelCliente(response.data.data)
        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                toast.error(err.response.data.msg)
            }
        }
    }


    //#endregion


    //#region Usuarios clientes 


    const getUsuariosDataSistemaCliente = async (id) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/dadosSistema/cliente/usuarios/${id}`)
            setters.setIsLoading(false)
            setters.setDataEstatisticaUsuariosCliente(response.data.data)
        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                toast.error(err.response.data.msg)
            }
        }
    }

    const getUsuariosCliente = async (id) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/user/cliente/${id}/getWithLimit/${states.limiteUsuariosCliente}&${states.offsetUsuariosCliente}`)
            setters.setIsLoading(false)
            setters.setDataUsuariosCliente(response.data.data)
            if (states.dataUsuariosCliente.length === 0) toast.success('UsuariosCliente carregados com sucesso')
        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                setters.setLimiteBuscaUsuariosCliente(true)
                if (states.offsetUsuariosCliente > 0) {
                    setters.setOffsetUsuariosCliente(states.offsetUsuariosCliente - states.limiteUsuariosCliente)
                }
                if (states.offsetUsuariosCliente < 0) {
                    setters.setOffsetUsuariosCliente(0)
                }
                toast.error(err.response.data.msg)
            }
        }
    }

    const getUsuariosQueryCliente = async (id, query) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/user/cliente/${id}/${query}/getWithLimit/${states.limiteUsuariosCliente}&${states.offsetUsuariosCliente}`)
            setters.setIsLoading(false)
            setters.setDataUsuariosCliente(response.data.data)
            if (states.dataUsuariosCliente.length === 0) toast.success('UsuariosCliente carregados com sucesso')
            return true;
        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                setters.setLimiteBuscaUsuariosCliente(true)
                if (states.offsetUsuariosCliente > 0) {
                    setters.setOffsetUsuariosCliente(states.offsetUsuariosCliente - states.limiteUsuariosCliente)
                }
                if (states.offsetUsuariosCliente < 0) {
                    setters.setOffsetUsuariosCliente(0)
                }
                toast.error(err.response.data.msg)
            }
            return false;
        }
    }


    //#endregion


    //#region recuperacao de senha cliente 

    const getRecuperacaoDeSenhaDataSistemaCliente = async (id) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/dadosSistema/cliente/recuperacaoSenha/${id}`)
            setters.setIsLoading(false)
            setters.setDataEstatisticaRecuperacaoSenhaCliente(response.data.data)
        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                toast.error(err.response.data.msg)
            }
        }
    }

    const getRecuperacaoSenhaCliente = async (id) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/cliente/userForgot/${id}/getWithLimit/${states.limiteRecuperacaoSenhaCliente}&${states.offsetRecuperacaoSenhaCliente}`)
            setters.setIsLoading(false)
            setters.setDataRecuperacaoSenhaCliente(response.data.data)
            if (states.dataRecuperacaoSenhaCliente.length === 0) toast.success('Recuperações de senhas carregados com sucesso')
        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                setters.setLimiteBuscaRecuperacaoSenhaCliente(true)
                if (states.offsetRecuperacaoSenhaCliente > 0) {
                    setters.setOffsetRecuperacaoSenhaCliente(states.offsetRecuperacaoSenhaCliente - states.limiteRecuperacaoSenhaCliente)
                }
                if (states.offsetRecuperacaoSenhaCliente < 0) {
                    setters.setOffsetRecuperacaoSenhaCliente(0)
                }
                toast.error(err.response.data.msg)
            }
        }
    }

    const getRecuperacaoSenhaQueryCliente = async (id, query) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/cliente/userForgot/${id}/${query}/getWithLimit/${states.limiteRecuperacaoSenhaCliente}&${states.offsetRecuperacaoSenhaCliente}`)
            setters.setIsLoading(false)
            setters.setDataRecuperacaoSenhaCliente(response.data.data)
            if (states.dataRecuperacaoSenhaCliente.length === 0) toast.success('Recuperações de senhas carregados com sucesso')
            return true;
        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                setters.setLimiteBuscaRecuperacaoSenhaCliente(true)
                if (states.offsetRecuperacaoSenhaCliente > 0) {
                    setters.setOffsetRecuperacaoSenhaCliente(states.offsetRecuperacaoSenhaCliente - states.limiteRecuperacaoSenhaCliente)
                }
                if (states.offsetRecuperacaoSenhaCliente < 0) {
                    setters.setOffsetRecuperacaoSenhaCliente(0)
                }
                toast.error(err.response.data.msg)
            }
            return false;
        }
    }


    //#endregion

    //#region Servico cliente

    const getServicosDataSistemaCliente = async (id) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/dadosSistema/cliente/servico/${id}`)
            setters.setIsLoading(false)
            setters.setDataEstatisticaServicosCliente(response.data.data)
        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                toast.error(err.response.data.msg)
            }
        }
    }

    const getServicosCliente = async (id) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/cliente/${id}/servicos/getWithLimit/${states.limiteServicosCliente}&${states.offsetServicosCliente}`)
            setters.setIsLoading(false)
            setters.setDataServicosCliente(response.data.data)
            if (states.dataServicosCliente.length === 0) toast.success('Serviços carregados com sucesso')
        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                setters.setLimiteBuscaServicosCliente(true)
                if (states.offsetServicosCliente > 0) {
                    setters.setOffsetServicosCliente(states.offsetServicosCliente - states.limiteServicosCliente)
                }
                if (states.offsetServicosCliente < 0) {
                    setters.setOffsetServicosCliente(0)
                }
                toast.error(err.response.data.msg)
            }
        }
    }

    const getServicosQueryCliente = async (id, query) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/cliente/${id}/servicos/getWithLimit/${states.limiteServicosCliente}&${states.offsetServicosCliente}/${query}`)
            setters.setIsLoading(false)
            setters.setDataServicosCliente(response.data.data)
            if (states.dataServicosCliente.length === 0) toast.success('Serviços carregados com sucesso')
            return true;
        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                setters.setLimiteBuscaServicosCliente(true)
                if (states.offsetServicosCliente > 0) {
                    setters.setOffsetServicosCliente(states.offsetServicosCliente - states.limiteServicosCliente)
                }
                if (states.offsetServicosCliente < 0) {
                    setters.setOffsetServicosCliente(0)
                }
                toast.error(err.response.data.msg)
            }
            return false;
        }
    }


    //#endregion

    //#region CategoriasServico cliente

    const getCategoriasServicosDataSistemaCliente = async (id) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/dadosSistema/cliente/categoria/${id}`)
            setters.setIsLoading(false)
            setters.setDataEstatisticaCategoriasServicosCliente(response.data.data)
        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                toast.error(err.response.data.msg)
            }
        }
    }

    const getCategoriasServicosCliente = async (id) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/categoria/cliente/${id}/getWithLimit/${states.limiteCategoriasServicosCliente}&${states.offsetCategoriasServicosCliente}`)
            setters.setIsLoading(false)
            setters.setDataCategoriasServicosCliente(response.data.data)
            if (states.dataCategoriasServicosCliente.length === 0) toast.success('Categorias de serviços carregados com sucesso')
        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                setters.setLimiteBuscaCategoriasServicosCliente(true)
                if (states.offsetCategoriasServicosCliente > 0) {
                    setters.setOffsetCategoriasServicosCliente(states.offsetCategoriasServicosCliente - states.limiteCategoriasServicosCliente)
                }
                if (states.offsetCategoriasServicosCliente < 0) {
                    setters.setOffsetCategoriasServicosCliente(0)
                }
                toast.error(err.response.data.msg)
            }
        }
    }

    const getCategoriasServicosQueryCliente = async (id, query) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/categoria/cliente/${id}/getWithLimit/${states.limiteCategoriasServicosCliente}&${states.offsetCategoriasServicosCliente}/${query}`)
            setters.setIsLoading(false)
            setters.setDataCategoriasServicosCliente(response.data.data)
            if (states.dataCategoriasServicosCliente.length === 0) toast.success('Categorias de serviço carregados com sucesso')
            return true;
        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                setters.setLimiteBuscaCategoriasServicosCliente(true)
                if (states.offsetCategoriasServicosCliente > 0) {
                    setters.setOffsetCategoriasServicosCliente(states.offsetCategoriasServicosCliente - states.limiteCategoriasServicosCliente)
                }
                if (states.offsetCategoriasServicosCliente < 0) {
                    setters.setOffsetCategoriasServicosCliente(0)
                }
                toast.error(err.response.data.msg)
            }
            return false;
        }
    }


    //#endregion

    //#region Configuracao do cliente

    const getConfiguracaoCliente = async (id) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/configuracoes/cliente/${id}`)
            setters.setIsLoading(false)
            setters.setConfiguracaoCliente(response.data.data)
            toast.success('Configuração carregada com sucesso')
            return true;
        } catch (err) {
            setters.setConfiguracaoCliente({})
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return false;
            }
            toast.error(err.response.data.msg)
            return false;
        }
    }


    //#endregion

    //#region Psicologos


    const getPrestadoresDataSistemaCliente = async (id) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/dadosSistema/cliente/prestador/${id}`)
            setters.setIsLoading(false)
            setters.setDataEstatisticaPrestadoresCliente(response.data.data)
        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                toast.error(err.response.data.msg)
            }
        }
    }

    const gettPrestadoresCliente = async (id) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/prestador/cliente/${id}/getWithLimit/${states.limitePrestadoresCliente}&${states.offsetPrestadoresCliente}`)
            setters.setIsLoading(false)
            setters.setDataPrestadoresCliente(response.data.data)
            if (states.dataPrestadoresCliente.length === 0) toast.success('Psicólogos carregados com sucesso')
        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                setters.setLimiteBuscaPrestadoresCliente(true)
                if (states.offsetPrestadoresCliente > 0) {
                    setters.setOffsetPrestadoresCliente(states.offsetPrestadoresCliente - states.limitePrestadoresCliente)
                }
                if (states.offsetPrestadoresCliente < 0) {
                    setters.setOffsetPrestadoresCliente(0)
                }
                toast.error(err.response.data.msg)
            }
        }
    }

    const gettPrestadoresQueryCliente = async (id, query) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/prestador/cliente/${id}/getWithLimit/${states.limitePrestadoresCliente}&${states.offsetPrestadoresCliente}/${query}`)
            setters.setIsLoading(false)
            setters.setDataPrestadoresCliente(response.data.data)
            if (states.dataPrestadoresCliente.length === 0) toast.success('Psicólogos carregados com sucesso')
            return true;
        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                setters.setLimiteBuscaPrestadoresCliente(true)
                if (states.offsetPrestadoresCliente > 0) {
                    setters.setOffsetPrestadoresCliente(states.offsetPrestadoresCliente - states.limitePrestadoresCliente)
                }
                if (states.offsetPrestadoresCliente < 0) {
                    setters.setOffsetPrestadoresCliente(0)
                }
                toast.error(err.response.data.msg)
            }
            return false;
        }
    }


    //#endregion

    //#region comprovantes


    const getComprovantesDataSistemaCliente = async (id) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/dadosSistema/cliente/comprovante/${id}`)
            setters.setIsLoading(false)
            setters.setDataEstatisticaComprovantesClientes(response.data.data)
        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                toast.error(err.response.data.msg)
            }
        }
    }

    const getComprovantesCliente = async (id) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/comprovante/cliente/${id}/getWithLimit/${states.limiteComprovantesClientes}&${states.offsetComprovantesClientes}`)
            setters.setIsLoading(false)
            setters.setDataComprovantesClientes(response.data.data)
            if (states.dataComprovantesClientes.length === 0) toast.success('Comprovantes carregados com sucesso')
        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                setters.setLimiteBuscaComprovantesClientes(true)
                if (states.offsetComprovantesClientes > 0) {
                    setters.setOffsetComprovantesClientes(states.offsetComprovantesClientes - states.limiteComprovantesClientes)
                }
                if (states.offsetComprovantesClientes < 0) {
                    setters.setOffsetComprovantesClientes(0)
                }
                toast.error(err.response.data.msg)
            }
        }
    }

    const getComprovantesQueryCliente = async (id, query) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/comprovante/cliente/${id}/getWithLimit/${states.limiteComprovantesClientes}&${states.offsetComprovantesClientes}/${query}`)
            setters.setIsLoading(false)
            setters.setDataComprovantesClientes(response.data.data)
            if (states.dataComprovantesClientes.length === 0) toast.success('Comprovantes carregados com sucesso')
            return true;
        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                setters.setLimiteBuscaComprovantesClientes(true)
                if (states.offsetComprovantesClientes > 0) {
                    setters.setOffsetComprovantesClientes(states.offsetComprovantesClientes - states.limiteComprovantesClientes)
                }
                if (states.offsetComprovantesClientes < 0) {
                    setters.setOffsetComprovantesClientes(0)
                }
                toast.error(err.response.data.msg)
            }
            return false;
        }
    }

    //#endregion

    //#region Configuracao de contabilidade do cliente

    const getConfiguracaoContabilidadeCliente = async (id) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/cliente/configuracaoContabilidade/${id}`)
            setters.setIsLoading(false)
            setters.setConfiguracaoContabilidadeCliente(response.data.data[0])
            toast.success('Configuração de contabilidade carregada com sucesso')
            return true;
        } catch (err) {
            console.log(err)
            setters.setConfiguracaoContabilidadeCliente({})
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return false;
            }
            toast.error(err.response.data.msg)
            return false;
        }
    }

    //#endregion

    //#region comprovantes

    const getCarneLeaoClienteDataSistemaCliente = async (id) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/dadosSistema/cliente/contabilidadeCarneLeao/${id}`)
            setters.setIsLoading(false)
            setters.setDataEstatisticaCarneLeaoCliente(response.data.data)
        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                toast.error(err.response.data.msg)
            }
        }
    }

    const getCarneLeaoCliente = async (id) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/cliente/contabilidadeCarneLeao/${id}/getWithLimit/${states.limiteCarneLeaoCliente}&${states.offsetCarneLeaoCliente}`)
            setters.setIsLoading(false)
            setters.setDataCarneLeaoCliente(response.data.data)
            if (states.dataCarneLeaoCliente.length === 0) toast.success('Comprovantes carregados com sucesso')
        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                setters.setLimiteBuscaCarneLeaoCliente(true)
                if (states.offsetCarneLeaoCliente > 0) {
                    setters.setOffsetCarneLeaoCliente(states.offsetCarneLeaoCliente - states.limiteCarneLeaoCliente)
                }
                if (states.offsetCarneLeaoCliente < 0) {
                    setters.setOffsetCarneLeaoCliente(0)
                }
                toast.error(err.response.data.msg)
            }
        }
    }

    //#endregion

    //#region faturasClientes



    const getFaturasClienteDataSistemaCliente = async (id) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/dadosSistema/cliente/faturas/${id}`)
            setters.setIsLoading(false)
            setters.setDataEstatisticaFaturasCliente(response.data.data)
        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                toast.error(err.response.data.msg)
            }
        }
    }

    const getFaturasCliente = async (id) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/faturas/cliente/${id}/getWithLimit/${states.limiteFaturasCliente}&${states.offsetFaturasCliente}`)
            setters.setIsLoading(false)
            setters.setDataFaturasCliente(response.data.data)
            if (states.dataFaturasCliente.length === 0) toast.success('Faturas carregados com sucesso')
        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                setters.setLimiteBuscaFaturasCliente(true)
                if (states.offsetFaturasCliente > 0) {
                    setters.setOffsetFaturasCliente(states.offsetFaturasCliente - states.limiteFaturasCliente)
                }
                if (states.offsetFaturasCliente < 0) {
                    setters.setOffsetFaturasCliente(0)
                }
                toast.error(err.response.data.msg)
            }
        }
    }

    //#endregion

    //#region dashBoard



    const getDashBoardataSistema = async () => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/dadosSistema/dashBoard`)
            setters.setIsLoading(false)
            setters.setDataEstatisticaDashBoard(response.data.data)
        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                toast.error(err.response.data.msg)
            }
        }
    }

    //#endregion

    //#region profile



    const profileChangeEmail = async (data) => {
        setters.setIsLoading(true)
        try {
            await axiosAuth.post(`/user/changeemail`, data)
            setters.setIsLoading(false)
            const saveEmail = localStorage.getItem('userEmail')
            if (saveEmail) {
                localStorage.setItem('userEmail', data.email)
            }
            toast.success('Email alterado com sucesso. Confirme a alteração no seu e-mail atual')
            return true;
        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                toast.error(err.response.data.msg)
            }
            return false;
        }
    }


    const profileChangeSenha = async (data) => {
        setters.setIsLoading(true)
        try {
            await axiosAuth.post(`/user/changePassword`, data)
            const saveSenha = localStorage.getItem('userPassword')
            if (saveSenha) {
                localStorage.setItem('userPassword', data.novaSenha)
            }
            setters.setIsLoading(false)
            toast.success('Senha alterado com sucesso.')
            return true;
        } catch (err) {
            console.log(err)
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                toast.error(err.response.data.msg)
            }
            return false;
        }
    }

    const profileChangeEmailConfirm = async (token) => {
        setters.setIsLoading(true)
        try {
            await axiosAuth.put(`/user/changeemail/${token}`)
            setters.setIsLoading(false)
            toast.success('Email alterado com sucesso.')
            return true;
        } catch (err) {
            console.log(err.response)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                toast.error(err.response.data.msg)
            }
            return false;
        }
    }

    const profileAutenticationEmail = async (token) => {
        setters.setIsLoading(true)
        try {
            await axiosAuth.put(`/user/authEmail/${token}`)
            setters.setIsLoading(false)
            toast.success('Email autenticado com sucesso.')
            return true;
        } catch (err) {
            console.log(err.response)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                toast.error(err.response.data.msg)
            }
            return false;
        }
    }

    const profileChangeEmailConfirmCheckLink = async (token) => {
        setters.setIsLoading(true)
        try {
            await axiosAuth.get(`/user/checkLink/${token}`)
            setters.setIsLoading(false)
            return true;
        } catch (err) {
            console.log(err.response)
            setters.setIsLoading(false)
            localStorage.removeItem('token')
            localStorage.removeItem('permisssion')
            localStorage.removeItem('cliente')
            localStorage.removeItem('usuario')
            setters.setSigned(false)
            toast.error(err.response.data.msg)

            return false;
        }
    }



    //#endregion

    return (
        <AuthContextApi.Provider
            value={{
                deleteUsuarios,
                profileAutenticationEmail,
                profileChangeEmailConfirmCheckLink,
                profileChangeEmailConfirm,
                profileChangeEmail,
                profileChangeSenha,
                getDashBoardataSistema,
                getFaturasClienteDataSistemaCliente,
                getFaturasCliente,
                getCarneLeaoClienteDataSistemaCliente,
                getCarneLeaoCliente,
                getConfiguracaoContabilidadeCliente,
                getComprovantesDataSistemaCliente,
                getComprovantesCliente,
                getComprovantesQueryCliente,
                getPrestadoresDataSistemaCliente,
                gettPrestadoresCliente,
                gettPrestadoresQueryCliente,
                getConfiguracaoCliente,
                getCategoriasServicosDataSistemaCliente,
                getCategoriasServicosCliente,
                getCategoriasServicosQueryCliente,
                getServicosDataSistemaCliente,
                getServicosCliente,
                getServicosQueryCliente,
                getRecuperacaoDeSenhaDataSistemaCliente,
                getRecuperacaoSenhaCliente,
                getRecuperacaoSenhaQueryCliente,
                getUsuariosDataSistemaCliente,
                getUsuariosCliente,
                getUsuariosQueryCliente,
                getClientePainelDataSistema,
                getClienteById,
                putCliente,
                postCliente,
                getClientesDataSistema,
                getClientes,
                getClientesQuery,
                reembolsoFatura,
                cancelarFatura,
                putFaturaBaixa,
                editFatura,
                genereteFatura,
                getClientesByCpfCnpj,
                getFaturasDataSistema,
                getFaturas,
                getFaturasQuery,
                getLogsDataSistema,
                getLogs,
                getCronsDataSistema,
                getCrons,
                blockUnlockUsuarios,
                putRoleUsuarios,
                postUsuarios,
                getUsuariosDataSistema,
                getUsuarios,
                getUsuariosQuery,
                getRecuperacaoDeSenhaDataSistema,
                getRecuperacaoSenha,
                getRecuperacaoSenhaQuery,
                cancelAssinatura,
                activeAssinatura,
                putAssinatura,
                getPlanosList,
                getAssinaturasDataSistema,
                getAssinaturas,
                getAssinaturasQuery,
                deletePlano,
                putPlano,
                postPlano,
                getPlanosQuery,
                getPlanosDataSistema,
                getPlanos,
                login,
                checkLogin,
                forgotPass,
                forgotChangePass,
                logout

            }}>
            {children}
        </AuthContextApi.Provider>
    )
}

export default AuthProvider;
