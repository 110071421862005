import { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon, MagnifyingGlassCircleIcon } from '@heroicons/react/24/outline'
import moment from 'moment'

const ModalFatura = ({ states, setters, get, action }) => {

    const [data_vencimento, setDataVencimento] = useState('')
    const [multa, setMulta] = useState('')
    const [valor, setValor] = useState('')
    const [descricao, setDescricao] = useState('')
    const [quantidade, setQuantidade] = useState('')
    const [desconto, setDesconto] = useState('')
    const [metodo_pagamento, setMetodoPagamento] = useState('')
    const [cliente_id, setClienteId] = useState('')
    const [cnpf_cnpj, setCpfCnpj] = useState('')

    const [errorDataVencimento, setErrorDataVencimento] = useState(false)
    const [errorDescricao, setErrorDescricao] = useState(false)
    const [errorMulta, setErrorMulta] = useState(false)
    const [errorValor, setErrorValor] = useState(false)
    const [errorQuantidade, setErrorQuantidade] = useState(false)
    const [errorDesconto, setErrorDesconto] = useState(false)
    const [errorMetodoPagamento, setErrorMetodoPagamento] = useState(false)
    const [errorClienteId, setErrorClienteId] = useState(false)
    const [errorClienteIdMsg, setErrorClienteIdMsg] = useState('Campo obrigatório')
    const [errorDataVencimentoMsg, setErrorDataVencimentoMsg] = useState('Campo obrigatório')

    const checkCliente = async () => {
        if (cnpf_cnpj === '') {
            setErrorClienteIdMsg('Campo obrigatório')
            setErrorClienteId(true)
            return
        }
        const data = await get(cnpf_cnpj)
        if (data.status === false) {
            setErrorClienteIdMsg('Cliente não encontrado')
            setErrorClienteId(true)
            return
        } else {
            setErrorClienteId(false)
            setErrorClienteIdMsg('Campo obrigatório')
        }
        setClienteId(data.data.id)
        setCpfCnpj(data.data.cpf_cnpj)

    }

    const deleteCliente = () => {
        setClienteId('')
        setCpfCnpj('')
        setErrorClienteIdMsg('Campo obrigatório')
        setters.setClientes({})
    }

    const formatToCpfOrCnpj = (value) => {
        if (value?.length === 11) {
            return value?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
        } else {
            return value?.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
        }
    }

    const formatTelefoneBrl = (value) => {
        return value?.replace(/^(\d{2})(\d{5})(\d{4})/, '($1) $2-$3')
    }

    const formatCep = (value) => {
        return value?.replace(/^(\d{5})(\d{3})/, '$1-$2')
    }

    const generate = async () => {
        let error = false;
        if (data_vencimento === '') {
            setErrorDataVencimento(true)
            error = true
        }else if (moment(data_vencimento).isBefore(moment().format('YYYY-MM-DD'))) {
            setErrorDataVencimentoMsg('Data de vencimento não pode ser menor que a data atual')
            setErrorDataVencimento(true)
            error = true
        } else{
            setErrorDataVencimento(false)
            setErrorDataVencimentoMsg('Campo obrigatório')
        }
        if (multa === '') {
            setErrorMulta(true)
            error = true
        } else setErrorMulta(false)

        if (valor === '') {
            setErrorValor(true)
            error = true
        } else setErrorValor(false)

        if (descricao === '') {
            setErrorDescricao(true)
            error = true
        } else setErrorDescricao(false)

        if (quantidade === '') {
            setErrorQuantidade(true)
            error = true
        } else setErrorQuantidade(false)

        if (desconto === '') {
            setErrorDesconto(true)
            error = true
        } else setErrorDesconto(false)

        if (metodo_pagamento === '') {
            setErrorMetodoPagamento(true)
            error = true
        } else setErrorMetodoPagamento(false)       


        if(error){
            error = false
            return;
        }

        action({
            data_vencimento,
            multa: multa.replace(/[^\d]/g, '').replace(',', '.').replace('%', ''),
            valor: valor.replace(/[^\d]/g, '').replace(',', '.').replace('R$', ''),
            descricao,
            quantidade,
            desconto: desconto.replace(/[^\d]/g, '').replace(',', '.').replace('%', ''),
            metodo_pagamento,
            cliente_id
        })


    }


    useEffect(() => {
        if (states.modalFaturas === false) {
            setDataVencimento('')
            setDescricao('')
            setMulta('')
            setValor('')
            setQuantidade('')
            setDesconto('')
            setMetodoPagamento('')
            setClienteId('')
            setCpfCnpj('')
            setErrorClienteIdMsg('Campo obrigatório')
            setErrorDataVencimentoMsg('Campo obrigatório')
            setErrorDataVencimento(false)
            setErrorDescricao(false)
            setErrorMulta(false)
            setErrorValor(false)
            setErrorQuantidade(false)
            setErrorDesconto(false)
            setErrorMetodoPagamento(false)
            setErrorClienteId(false)
            setters.setClientes({})
        }
    }, [states.modalFaturas])


    return (
        <Transition.Root show={states.modalFaturas} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => setters.setModalFaturas(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full w-[100%] itens-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            {/* Mexer na altura, só colocar o height na classname abaixo */}
                            <Dialog.Panel className="w-[80%] transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                        onClick={() => setters.setModalFaturas(false)}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                                <div className='flex-col border-b border-gray-900/10'>
                                    <h3 className="text-base font-semibold leading-6 text-gray-900 text-center">Fatura</h3>
                                    <p className="flex mt-1 text-sm text-gray-500 justify-center mb-5">
                                        Cadastro de fatura
                                    </p>
                                </div>
                                <div className='pt-10 pl-10 pr-10'>
                                    <div className="space-y-12">
                                        {/* 1 parte */}
                                        <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                                            <div>
                                                <h2 className="text-base font-semibold leading-7 text-gray-900">Informações do cliente</h2>
                                                <p className="mt-1 text-sm leading-6 text-gray-600">Dados principais do cliente.</p>
                                            </div>
                                            <div className="sm:col-span-2">
                                                <div className="grid grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-2">

                                                    <div className="sm:col-span-2">
                                                        <div className='flex flex-col md:flex-row item-center justify-start w-full'>
                                                            <div className='flex flex-col item-center justify-start w-[80%]'>
                                                                <label htmlFor="descricao" className="block text-sm font-semibold leading-5 text-gray-700">Digite o cpf ou cnpj do cliente</label>
                                                                <input
                                                                    type="text"
                                                                    value={formatToCpfOrCnpj(cnpf_cnpj)}
                                                                    onChange={(e) => {
                                                                        const onlyNumber = e.target.value.replace(/[^\d]/g, '')
                                                                        setCpfCnpj(onlyNumber)
                                                                    }}
                                                                    onKeyDown={(e) => {
                                                                        if (e.key === 'Enter') {
                                                                            checkCliente()
                                                                        }
                                                                    }}
                                                                    onBlur={checkCliente}
                                                                    disabled={Object.keys(states.Clientes).length > 0}
                                                                    className={
                                                                        Object.keys(states.Clientes).length === 0 ? "mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm" : "mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm bg-gray-100"
                                                                    }
                                                                />
                                                                {
                                                                    errorClienteId && <p className="mt-1 text-xs text-red-500">{errorClienteIdMsg}</p>
                                                                }
                                                            </div>
                                                            <div className="flex item-center justify-start ml-4 w-[20%]">
                                                                {
                                                                    Object.keys(states.Clientes).length === 0 &&
                                                                    <button
                                                                        onClick={checkCliente}
                                                                        type="button"
                                                                        className="border mt-4 h-[70%] p-2 border-transparent text-sm font-semibold rounded-md text-white bg-blue-500 hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
                                                                    >
                                                                        <MagnifyingGlassCircleIcon className="h-7 w-7 " aria-hidden="true" />
                                                                    </button>
                                                                }
                                                                {
                                                                    Object.keys(states.Clientes).length > 0 &&
                                                                    <button
                                                                        onClick={deleteCliente}
                                                                        type="button"
                                                                        className="border ml-3 mt-4 h-[70%] p-2 border-transparent text-sm font-semibold rounded-md text-white bg-red-500 hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500"
                                                                    >
                                                                        <XMarkIcon className="h-7 w-7 " aria-hidden="true" />
                                                                    </button>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        Object.keys(states.Clientes).length > 0 &&
                                                        <div className="sm:col-span-2">
                                                            <label htmlFor="descricao" className="block text-sm font-semibold leading-5 text-gray-700">Cliente</label>
                                                            <div className='flex flex-col md:flex-row item-center justify-start w-full'>
                                                                <p className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                                                                    {states.Clientes.nome}
                                                                </p>
                                                                <p className="mt-1 sm:ml-3 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                                                                    {formatTelefoneBrl(states.Clientes.telefone)}
                                                                </p>
                                                            </div>
                                                            <div className='flex flex-col md:flex-row item-center justify-start w-full mt-3'>
                                                                <p className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                                                                    {formatCep(states.Clientes.cep)}
                                                                </p>
                                                                <p className="mt-1 sm:ml-3 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                                                                    {states.Clientes.estado}
                                                                </p>
                                                                <p className="mt-1 sm:ml-3 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                                                                    {states.Clientes.cidade}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>


                                        <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                                            <div>
                                                <h2 className="text-base font-semibold leading-7 text-gray-900">Informações das características</h2>
                                                <p className="mt-1 text-sm leading-6 text-gray-600">Dados da Fatura.</p>
                                            </div>
                                            <div className="sm:col-span-2">

                                                <div className="grid grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-3">
                                                    <div className="sm:col-span-1">
                                                        <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Data de vencimento</label>
                                                        <input
                                                            type="date"
                                                            value={data_vencimento}
                                                            onChange={(e) => setDataVencimento(e.target.value)}
                                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                        />
                                                        {
                                                            errorDataVencimento && <p className="mt-1 text-xs text-red-500">{errorDataVencimentoMsg}</p>
                                                        }
                                                    </div>
                                                    <div className="sm:col-span-1">
                                                        <label htmlFor="descricao" className="block text-sm font-semibold leading-5 text-gray-700">Multa</label>
                                                        <input
                                                            type="text"
                                                            value={multa}
                                                            onChange={(e) => {
                                                                const onlyNumber = e.target.value.replace(/[^\d]/g, '')
                                                                if (onlyNumber === '') {
                                                                    setMulta(onlyNumber)
                                                                } else {
                                                                    setMulta(`${onlyNumber}%`)
                                                                }
                                                            }}
                                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                        />
                                                        {
                                                            errorMulta && <p className="mt-1 text-xs text-red-500">Campo obrigatório</p>
                                                        }
                                                    </div>
                                                    <div className="sm:col-span-1">
                                                        <label htmlFor="descricao" className="block text-sm font-semibold leading-5 text-gray-700">Valor</label>
                                                        <input
                                                            type="text"
                                                            value={valor}
                                                            onChange={(e) => {
                                                                const onlyNumber = e.target.value.replace(/[^\d]/g, '')
                                                                if (onlyNumber === '') {
                                                                    setValor(onlyNumber)
                                                                } else {
                                                                    const formatToMoney = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(onlyNumber / 100)
                                                                    setValor(formatToMoney)
                                                                }
                                                            }}
                                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                        />
                                                        {
                                                            errorValor && <p className="mt-1 text-xs text-red-500">Campo obrigatório</p>
                                                        }
                                                    </div>
                                                </div>

                                                <div className="mt-8 grid grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-3">
                                                    <div className="sm:col-span-3">
                                                        <label htmlFor="descricao" className="block text-sm font-semibold leading-5 text-gray-700">Descrição</label>
                                                        <textarea
                                                            value={descricao}
                                                            onChange={(e) => setDescricao(e.target.value)}
                                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                        />
                                                        {
                                                            errorDescricao && <p className="mt-1 text-xs text-red-500">Campo obrigatório</p>
                                                        }
                                                    </div>
                                                </div>

                                                <div className="mt-8 grid grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-3">
                                                    <div className="sm:col-span-1">
                                                        <label htmlFor="descricao" className="block text-sm font-semibold leading-5 text-gray-700">Quantidade</label>
                                                        <input
                                                            type="text"
                                                            value={quantidade}
                                                            onChange={(e) => {
                                                                const onlyNumber = e.target.value.replace(/[^\d]/g, '')
                                                                setQuantidade(onlyNumber)
                                                            }}
                                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                        />
                                                        {
                                                            errorQuantidade && <p className="mt-1 text-xs text-red-500">Campo obrigatório</p>
                                                        }
                                                    </div>
                                                    <div className="sm:col-span-1">
                                                        <label htmlFor="descricao" className="block text-sm font-semibold leading-5 text-gray-700">Desconto</label>
                                                        <input
                                                            type="text"
                                                            value={desconto}
                                                            onChange={(e) => {
                                                                const onlyNumber = e.target.value.replace(/[^\d]/g, '')
                                                                if (onlyNumber === '') {
                                                                    setDesconto(onlyNumber)
                                                                } else {
                                                                    setDesconto(`${onlyNumber}%`)
                                                                }
                                                            }}
                                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                        />
                                                        {
                                                            errorDesconto && <p className="mt-1 text-xs text-red-500">Campo obrigatório</p>
                                                        }
                                                    </div>
                                                    <div className="sm:col-span-1">
                                                        <label htmlFor="descricao" className="block text-sm font-semibold leading-5 text-gray-700">Método de pagamento</label>
                                                        <select value={metodo_pagamento} onChange={(e) => setMetodoPagamento(e.target.value)} className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                                                            <option value="">Selecione</option>
                                                            <option value="pix">Pix</option>
                                                        </select>
                                                        {
                                                            errorMetodoPagamento && <p className="mt-1 text-xs text-red-500">Campo obrigatório</p>
                                                        }
                                                    </div>
                                                </div>





                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-6 flex items-center justify-end gap-x-6">
                                        <button type="button" className="text-sm font-semibold leading-5 text-gray-900 bg-gray-100 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-100"
                                            onClick={() => setters.setModalFaturas(false)}>
                                            Cancelar
                                        </button>
                                        <button type="button" className="text-sm font-semibold leading-5 text-white bg-blue-500 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-100"
                                            onClick={generate}>
                                            Gerar fatura
                                        </button>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default ModalFatura
