import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../Context';
import { AuthContextApi } from '../../../Context/api';
import {
    InformationCircleIcon, ArrowLeftIcon, ArrowUpIcon, ArrowDownIcon, UserCircleIcon,
    ArchiveBoxIcon, Cog6ToothIcon, BriefcaseIcon, BookOpenIcon, CalculatorIcon, ClipboardDocumentCheckIcon,
    BanknotesIcon, FolderIcon
} from '@heroicons/react/20/solid'
import { useNavigate, useParams, Link } from 'react-router-dom';
import moment from 'moment';

const Cliente = () => {


    const { states, setters } = useContext(AuthContext)
    const { checkLogin,
        getClientesDataSistema,
        getClienteById,
        getClientePainelDataSistema
    } = useContext(AuthContextApi)

    const navigate = useNavigate()
    const { id } = useParams()
    const [chvron, setChvron] = useState(true)

    const primeiroLoad = async () => {
        if (id === undefined) {
            navigate(-1)
            return;
        }
        await checkLogin()
        await getClientesDataSistema()
        await getClienteById(id)
        await getClientePainelDataSistema(id)
    }

    const formatToCpfOrCnpj = (value) => {
        if (value?.length === 11) {
            return value?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
        } else {
            return value?.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
        }
    }







    useEffect(() => {
        primeiroLoad()

        return () => {
            setters.setDataEstatisticaPainelCliente([])
            setters.setClientes({})
            //setChvron(false)
        }

    }, [])


    return (
        <>
            <div className="flex flex-col">


                <div className="flex p-4  border  hover:shadow-md">
                    <div className='flex flex items-center justify-between'>
                        <button onClick={() => navigate('/clientes')} className="flex h-[50%] items-center jutify-center text-sm font-medium hover:shadow text-blue-500 hover:text-blue-700 border p-2 rounded-[15px]">
                            <ArrowLeftIcon className="w-5 h-5 mr-2" aria-hidden="true" />
                        </button>
                    </div>
                    <div className='mt-5 ml-5'>
                        <h1 className="text-base font-semibold leading-6 text-gray-900">Cliente</h1>
                        <p className="mt-2 text-sm text-gray-700">
                            {states.Clientes.nome} - {formatToCpfOrCnpj(states.Clientes.cpf_cnpj)} - {states.Clientes.email}
                        </p>
                    </div>
                </div>
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-4 lg:grid-cols-4 mt-4">
                    {states.dataEstatisticaPainelCliente.length > 0 && states.dataEstatisticaPainelCliente.map((card, index) => (
                        <div key={card.title} className={card.value > 0 && index === -1 ? "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md" : "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md"}>
                            <div className="p-5">
                                <div className="flex items-center">
                                    <div className="flex-shrink-0">
                                        {
                                            card.value > 0 && index === -1 ? (<InformationCircleIcon className="w-6 h-6 text-red-400" aria-hidden="true" />) : (<InformationCircleIcon className="w-6 h-6 text-gray-400" aria-hidden="true" />)
                                        }
                                    </div>
                                    <div className="ml-5 w-0 flex-1">
                                        <dl>
                                            {
                                                card.value > 0 && index === -1 ? (<dt className="truncate text-sm font-medium text-red-500">{card.title}</dt>) : (<dt className="truncate text-sm font-medium text-gray-500">{card.title}</dt>)
                                            }

                                            <dd>
                                                {
                                                    card.value > 0 && index === -1 ? (<div className="text-lg font-medium text-red-500">{card.value}</div>) : (<div className="text-lg font-medium text-gray-900">{card.value}</div>)
                                                }
                                            </dd>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                {
                    states.Clientes.assinatura !== null &&
                    <div className="flex-1 p-6 border  hover:shadow-md mt-5">
                        <h1 className="text-base font-semibold leading-6 text-gray-900">Dados de assinatura do cliente</h1>
                        <div className='flex flex-col sm:flex-row  items-between justify-between p-1'>
                            <div className='flex flex-col sm:flex-row mt-2 p-1'>
                                <p className="text-md text-gray-700">Status: </p>
                                <p className="text-md text-gray-700">{states.Clientes?.assinatura?.status === 1 ? <span className="text-green-500 sm:ml-3">Ativa</span> : <span className="text-red-500 sm:ml-3">Inativa</span>}</p>
                            </div>
                            <div className='flex flex-col sm:flex-row mt-2 p-1'>
                                <p className="text-md text-gray-700">Plano:</p>
                                <p className="text-md text-gray-700 sm:ml-3">{states.Clientes?.assinatura?.plano_nome}</p>
                            </div>
                            <div className='flex flex-col sm:flex-row mt-2 p-1'>
                                <p className="text-md text-gray-700">Próximo pagamento:</p>
                                <p className="text-md text-gray-700 sm:ml-3">{moment(states.Clientes?.assinatura?.proximo_pagamento).format('DD/MM/YYYY')}</p>
                            </div>
                            <div className='flex flex-col sm:flex-row mt-2 p-1'>
                                <p className="text-md text-gray-700">Método de pagamento:</p>
                                <p className="text-md text-gray-700 sm:ml-3">{states.Clientes?.assinatura?.metodo === 'pix' ? 'Pix' : states.Clientes?.assinatura?.metodo === 'boleto' ? 'Boleto' : 'Cartão de crédito'}</p>
                            </div>
                        </div>
                    </div>
                }

                {
                    localStorage.getItem('permisssion') !== 'OPERACIONAL' &&
                    < div className="lex-1 p-6 border  hover:shadow-md mt-5">
                        <div className='flex items-between justify-between'>
                            <h1 className="text-base font-semibold leading-6 text-gray-900">Painel de controle</h1>
                            <button className='flex items-center justify-center border p-1 rounded-[10px] hover:shadow' onClick={() => setChvron(!chvron)}>
                                {chvron ? <ArrowDownIcon className="w-5 h-5 " aria-hidden="true" /> : <ArrowUpIcon className="w-5 h-5 " aria-hidden="true" />}
                            </button>
                        </div>
                        {
                            chvron && (
                                <>
                                    {/** linha pontilhada separando */}
                                    <div className="border-t border-1 border-gray-300 border-dashed my-4"></div>
                                    <div className='grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-5 mt-4'>
                                        <div className='flex flex-col items-center'>
                                            <Link className='flex items-center justify-center border p-1 rounded-[10px] hover:shadow'
                                                to={`/clientes/cliente/${id}/usuarios`}
                                            >
                                                <UserCircleIcon className="w-8 h-8 text-blue-500" aria-hidden="true" />
                                            </Link>
                                            <p className="text-md text-gray-700">Usuários</p>
                                        </div>
                                        <div className='flex flex-col items-center'>
                                            <Link className='flex items-center justify-center border p-1 rounded-[10px] hover:shadow' to={`/clientes/cliente/${id}/servicos`}>
                                                <FolderIcon className="w-8 h-8 text-blue-500" aria-hidden="true" />
                                            </Link>
                                            <p className="text-md text-gray-700">Serviço</p>

                                        </div>
                                        <div className='flex flex-col items-center'>
                                            <Link className='flex items-center justify-center border p-1 rounded-[10px] hover:shadow' to={`/clientes/cliente/${id}/categoriaServicos`}>
                                                <ArchiveBoxIcon className="w-8 h-8 text-blue-500" aria-hidden="true" />
                                            </Link>
                                            <p className="text-md text-gray-700">Categorias de serviço</p>

                                        </div>
                                        <div className='flex flex-col items-center'>
                                            <Link className='flex items-center justify-center border p-1 rounded-[10px] hover:shadow' to={`/clientes/cliente/${id}/configuracao`}>
                                                <Cog6ToothIcon className="w-8 h-8 text-blue-500" aria-hidden="true" />
                                            </Link>
                                            <p className="text-md text-gray-700">Configuração</p>
                                        </div>
                                        <div className='flex flex-col items-center'>
                                            <Link className='flex items-center justify-center border p-1 rounded-[10px] hover:shadow' to={`/clientes/cliente/${id}/psicologos`}>
                                                <BriefcaseIcon className="w-8 h-8 text-blue-500" aria-hidden="true" />
                                            </Link>
                                            <p className="text-md text-gray-700">Psicologos</p>
                                        </div>
                                    </div>

                                    <div className="border-t border-1 border-gray-300 border-dashed my-4"></div>

                                    <div className='mt-8 grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 mt-4'>
                                        <div className='flex flex-col items-center' >
                                            <Link className='flex items-center justify-center border p-1 rounded-[10px] hover:shadow' to={`/clientes/cliente/${id}/comprovantes`}>
                                                <BookOpenIcon className="w-8 h-8 text-blue-500" aria-hidden="true" />
                                            </Link>
                                            <p className="text-md text-gray-700">Comprovantes</p>

                                        </div>
                                        <div className='flex flex-col items-center'>
                                            <Link className='flex items-center justify-center border p-1 rounded-[10px] hover:shadow' to={`/clientes/cliente/${id}/configuracaoContabilidade`}>
                                                <CalculatorIcon className="w-8 h-8 text-blue-500" aria-hidden="true" />
                                            </Link>
                                            <p className="text-md text-gray-700">Configuração de contabilidade</p>
                                        </div>
                                        <div className='flex flex-col items-center'>
                                            <Link className='flex items-center justify-center border p-1 rounded-[10px] hover:shadow' to={`/clientes/cliente/${id}/carneLeao`}>
                                                <ClipboardDocumentCheckIcon className="w-8 h-8 text-blue-500" aria-hidden="true" />
                                            </Link>
                                            <p className="text-md text-gray-700">Carnê Leão</p>
                                        </div>
                                        <div className='flex flex-col items-center'>
                                            <Link className='flex items-center justify-center border p-1 rounded-[10px] hover:shadow' to={`/clientes/cliente/${id}/faturas`}>
                                                <BanknotesIcon className="w-8 h-8 text-blue-500" aria-hidden="true" />
                                            </Link>
                                            <p className="text-md text-gray-700">Faturas</p>
                                        </div>
                                    </div>
                                </>
                            )
                        }

                    </div>
                }
            </div >
        </>
    )

}



export default Cliente;