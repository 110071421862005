import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../Context';
import { AuthContextApi } from '../../Context/api';
import { InformationCircleIcon } from '@heroicons/react/20/solid'
import Modal from '../../Components/Modal/Faturas/Fatura';
import ModalShow from '../../Components/Modal/Faturas/FaturaShow';
import ModalDate from '../../Components/Modal/Faturas/FaturaEditData';
import ModalBaixa from '../../Components/Modal/Faturas/FaturaBaixa/index';
import Table from '../../Components/Table/Faturas';
import moment from 'moment';




const Faturas = () => {

    const { states, setters } = useContext(AuthContext)
    const { checkLogin,
        getFaturasDataSistema,
        getFaturas,
        getFaturasQuery,
        getClientesByCpfCnpj,
        genereteFatura,
        editFatura,
        putFaturaBaixa,
        cancelarFatura,
        reembolsoFatura
    } = useContext(AuthContextApi)
    const [query, setQuery] = useState('')



    const primeiroLoad = async () => {
        await checkLogin()
        await getFaturasDataSistema()
        await getFaturas()
    }

    const loadFaturas = async () => {
        if (query !== '') {
            await getFaturasQuery(query)
        } else {
            await getFaturas()
        }
    }

    const next = async () => {
        if (states.limiteBuscaFaturas) {
            if (states.offsetFaturas + states.limiteFaturas > 0) return
            setters.setLimiteBuscaFaturas(true)
        }
        setters.setOffsetFaturas(states.offsetFaturas + states.limiteFaturas)
    }

    const previous = async () => {
        if (states.offsetFaturas - states.limiteFaturas < 0) return
        setters.setOffsetFaturas(states.offsetFaturas - states.limiteFaturas)
        if (states.limiteBuscaFaturas) {
            setters.setLimiteBuscaFaturas(false)
        }
    }

    const search = async () => {
        setters.setOffsetFaturas(0)
        if (query === '') {
            await getFaturas()
            return
        }
        const response = await getFaturasQuery(query)
        if (response === false) {
            setQuery('')
            await getFaturas()
        }
    }

    const clear = async () => {
        setters.setOffsetFaturas(0)
        setQuery('')
        await getFaturas()
    }


    const openModal = async () => {
        setters.setIsEdit(false)
        setters.setModalFaturas(true)
    }

    const openModalEdit = async (data) => {
        setters.setFaturas(data)
        setters.setIsEdit(true)
        setters.setModalDateFaturas(true)
    }

    const openModalBaixa = async (data) => {
        setters.setFaturas(data)
        setters.setModalBaixaFaturas(true)
    }

    const openModalShow = async (data) => {
        setters.setFaturas(data)
        setters.setIsEdit(false)
        setters.setModalShowFaturas(true)
    }

    const actionModal = async (data) => {
        setters.setModalFaturas(false)
        await genereteFatura(data)
    }


    const cancelarFaturaFunction = async (data) => {
        const valorReais = (data?.valor / 100).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
        setters.setModalMsg(true)
        setters.setPerigoModalMsg(true)
        setters.setMsgModalMsg(`Deseja mesmo cancelar a fatura do cliente ${data.nomeCliente} no valor de ${valorReais} com o vencimento para ${moment(data.data_vencimento).format('DD/MM/YYYY')}?`)
        setters.setDescModalMsg('Após a confirmação a fatura será cancelada e o cliente será notificado.')
        setters.setTitleButtonModalMsg('Confirmar')
        setters.setFunctionModalMsg(() => async () => {
            await cancelarFatura(data.id)
            setters.setModalMsg(false)
        })
    }

    const reembolsoFaturaFunction = async (data) => {
        const valorReais = (data?.valor / 100).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
        setters.setModalMsg(true)
        setters.setPerigoModalMsg(true)
        setters.setMsgModalMsg(`Deseja mesmo reembolsar a fatura do cliente ${data.nomeCliente} no valor de ${valorReais} com o vencimento para ${moment(data.data_vencimento).format('DD/MM/YYYY')}?`)
        setters.setDescModalMsg('Após a confirmação a fatura será reembolsada e o cliente será notificado.')
        setters.setTitleButtonModalMsg('Confirmar')
        setters.setFunctionModalMsg(() => async () => {
            await reembolsoFatura(data.id)
            setters.setModalMsg(false)
        })
    }

    const dowloadArquivoFaturaFunction = async (data) => {
        const valorReais = (data?.valor / 100).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
        setters.setModalMsg(true)
        setters.setPerigoModalMsg(false)
        setters.setMsgModalMsg(`Deseja mesmo realizar o dowload da fatura do cliente ${data.nomeCliente} no valor de ${valorReais} com o vencimento para ${moment(data.data_vencimento).format('DD/MM/YYYY')}?`)
        setters.setDescModalMsg('Após a confirmação o arquivo será baixado.')
        setters.setTitleButtonModalMsg('Confirmar dowload')
        setters.setFunctionModalMsg(() => async () => {
            const link = document.createElement("a");
            link.href = `data:application/pdf;base64,${data.pathArquivo}`;
            link.download = data.nomeArquivo;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setters.setModalMsg(false)
        })
    }

    const dowloadArquivoPagamentoFaturaFunction = async (data) => {
        const valorReais = (data?.valor / 100).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
        setters.setModalMsg(true)
        setters.setPerigoModalMsg(false)
        setters.setMsgModalMsg(`Deseja mesmo realizar o dowload do comprovante da fatura do cliente ${data.nomeCliente} no valor de ${valorReais} com o vencimento para ${moment(data.data_vencimento).format('DD/MM/YYYY')}?`)
        setters.setDescModalMsg('Após a confirmação o arquivo será baixado.')
        setters.setTitleButtonModalMsg('Confirmar dowload')
        setters.setFunctionModalMsg(() => async () => {
            const link = document.createElement("a");
            link.href = `data:application/pdf;base64,${data.pathArquivoPagamento}`;
            link.download = data.nomeArquivoPagamento;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setters.setModalMsg(false)
        })
    }




    useEffect(() => {
        primeiroLoad()

        return () => {
            setQuery('')
            setters.setOffsetFaturas(0)
            setters.setLimiteBuscaFaturas(false)
            setters.setModalFaturas(false)
            setters.setFaturas({})
            setters.setDataFaturas([])
            setters.setDataEstatisticaFaturas([])
            setters.setIsEdit(false)
        }

    }, [])



    useEffect(() => {
        if (states.dataFaturas.length === 0) return
        loadFaturas()
    }, [states.offsetFaturas])

    const headers = ['', 'Nome', 'CPF/CNPJ', 'E-mail', 'Data de Vencimento', 'Método de pagamento', 'Desconto', 'Multa', 'Valor', 'Status',
        'Data de Criação', 'Data de Atualização']



    return (
        <>
            <div className="flex flex-col">


                <div className="flex-1 p-4 border  hover:shadow-md">
                    <h1 className="text-base font-semibold leading-6 text-gray-900">Faturas</h1>
                    <p className="mt-2 text-sm text-gray-700">
                        Informações sobre as faturas cadastrados no sistema.
                    </p>
                </div>
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-4 lg:grid-cols-4 mt-4">
                    {states.dataEstatisticaFaturas.length > 0 && states.dataEstatisticaFaturas.map((card, index) => (
                        <div key={card.title} className={card.value > 0 && index === -1 ? "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md" : "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md"}>
                            <div className="p-5">
                                <div className="flex items-center">
                                    <div className="flex-shrink-0">
                                        {
                                            card.value > 0 && index === -1 ? (<InformationCircleIcon className="w-6 h-6 text-red-400" aria-hidden="true" />) : (<InformationCircleIcon className="w-6 h-6 text-gray-400" aria-hidden="true" />)
                                        }
                                    </div>
                                    <div className="ml-5 w-0 flex-1">
                                        <dl>
                                            {
                                                card.value > 0 && index === -1 ? (<dt className="truncate text-sm font-medium text-red-500">{card.title}</dt>) : (<dt className="truncate text-sm font-medium text-gray-500">{card.title}</dt>)
                                            }

                                            <dd>
                                                {
                                                    card.value > 0 && index === -1 ? (<div className="text-lg font-medium text-red-500">{card.value}</div>) : (<div className="text-lg font-medium text-gray-900">{card.value}</div>)
                                                }
                                            </dd>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="flex-1 p-12 border  hover:shadow-md mt-5">
                    <h1 className="text-base font-semibold leading-6 text-gray-900">Pesquisa por faturas</h1>
                    <div className='flex flex-col mt-4'>
                        <p className="text-sm text-gray-700">Pesquise por nome, e-mail ou CPF/CNPJ</p>
                        <div className='flex flex-col sm:flex-row mt-2'>
                            <input type="text" className=" flex w-full sm:w-[80%] mt-1 p-2 border-2 border-gray-200 rounded-md"
                                value={query} onChange={(e) => setQuery(e.target.value)} onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        search()
                                    }
                                }}
                            />
                            <button className="flex mt-2 sm:mt-0 w-full sm:w-[10%]  ml-2 justify-center items-center p-2 bg-blue-500 text-white rounded-md hover:bg-blue-600" onClick={search}>Pesquisar</button>
                            <button className="flex mt-2 sm:mt-0 w-full sm:w-[10%]  ml-2 justify-center items-center p-2 bg-green-600 text-white rounded-md hover:bg-green-500" onClick={clear}>Limpar</button>
                        </div>
                    </div>
                </div>
                <div className=" p-4 border hover:shadow-md mt-5">
                    <Table title='Faturas' description='Registro dos Faturas cadastrados' cancel={cancelarFaturaFunction} edit={openModalEdit}
                        add={openModal} next={next} baixa={openModalBaixa} reembolso={reembolsoFaturaFunction} dowload={dowloadArquivoFaturaFunction} dowloadPagamento={dowloadArquivoPagamentoFaturaFunction}
                        previous={previous} states={states} setters={setters} show={openModalShow} headers={headers}
                        infoTable={{ offset: states.offsetFaturas, limit: states.limiteFaturas }} dataTable={states.dataFaturas} query={query} />
                </div>
            </div>
            <Modal states={states} setters={setters} action={actionModal} get={getClientesByCpfCnpj} />
            <ModalShow states={states} setters={setters} />
            <ModalDate states={states} setters={setters} action={editFatura} />
            <ModalBaixa states={states} setters={setters} action={putFaturaBaixa} />
        </>
    )

}



export default Faturas;