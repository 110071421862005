
import { AuthContext } from "../../Context"
import { useNavigate } from 'react-router-dom'
import { AuthContextApi } from "../../Context/api"
import { useContext, useEffect, useState } from "react"
import Footer from "../../Components/Footer"
import { jsonConfig } from "../../Config"
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid'
import './index.css'
// import Cookies from 'js-cookie'


const Login = () => {
    const { states, setters } = useContext(AuthContext)
    const { login, forgotPass } = useContext(AuthContextApi)
    const navigate = useNavigate()

    const [errorEmail, setErrorEmail] = useState(false)
    const [msgErrorEmail, setMsgErrorEmail] = useState('O campo email é obrigatório')
    const [errorSenha, setErrorSenha] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [remainSigned, setRemainSigned] = useState(false)
    const [perdeuSenha, setPerdeuSenha] = useState(false)

    const isValidEmail = (email) => {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    }


    const actionLogin = async () => {
        if (!perdeuSenha) {
            let error;
            if (states.email === '') {
                setErrorEmail(true)
                error = true;
            } else {
                if (!isValidEmail(states.email)) {
                    setErrorEmail(true)
                    setMsgErrorEmail('Insira um e-mail válido')
                    error = true;
                } else {
                    setMsgErrorEmail('O campo email é obrigatório')
                    setErrorEmail(false)
                }
            }
            if (states.senha === '') {
                setErrorSenha(true)
                error = true;
            } else {
                setErrorSenha(false)
            }
            if (error) {
                error = false;
                return
            }
            if (remainSigned) {
                localStorage.setItem('userEmail', states.email)
                localStorage.setItem('userPassword', states.senha)
            }

            const response = await login({ email: states.email, senha: states.senha })
            if (response) {
                navigate('/dashboard')
            }
        } else {
            let error;
            if (states.email === '') {
                setErrorEmail(true)
                error = true;
            } else {
                if (!isValidEmail(states.email)) {
                    setErrorEmail(true)
                    setMsgErrorEmail('Insira um e-mail válido')
                    error = true;
                } else {
                    setMsgErrorEmail('O campo email é obrigatório')
                    setErrorEmail(false)
                }
            }
            if (error) {
                error = false;
                return
            }
            const resp = await forgotPass({ email: states.email })
            if (resp) {
                setters.setModalMsg(true)
                setters.setMsgModalMsg('Recuperação de senha')
                setters.setDescModalMsg('Recuperação de senha enviada com sucesso! Verifique seu e-mail.')
                setters.setPerigoModalMsg(false)
                setters.setTitleButtonModalMsg('Retornar para login')
                setters.setFunctionModalMsg(() => () => {
                    setters.setModalMsg(false)
                    setErrorEmail(false)
                    setErrorSenha(false)
                    setters.setEmail('')
                    setters.setSenha('')
                    localStorage.removeItem('userEmail')
                    localStorage.removeItem('userPassword')
                    setPerdeuSenha(false)
                })
            } else {
                setters.setModalMsg(true)
                setters.setMsgModalMsg('Recuperação de senha')
                setters.setDescModalMsg('Parece que ocorreu um erro! Tente novamente mais tarde.')
                setters.setPerigoModalMsg(true)
                setters.setTitleButtonModalMsg('Retornar')
                setters.setFunctionModalMsg(() => () => {
                    setters.setModalMsg(false)
                    setErrorEmail(false)
                    setErrorSenha(false)
                    setters.setEmail('')
                    setters.setSenha('')
                    localStorage.removeItem('userEmail')
                    localStorage.removeItem('userPassword')
                    setPerdeuSenha(false)
                })
            }

        }
    }

    const remindSignedAction = (data) => {
        if (data === false) {
            setRemainSigned(data)
            localStorage.removeItem('userEmail')
            localStorage.removeItem('userPassword')
        } else {
            setRemainSigned(data)
            localStorage.setItem('userEmail', states.email)
            localStorage.setItem('userPassword', states.senha)
        }
    }
    
    

    const perdeuSenhaAction = () => {
        setPerdeuSenha(true)
        setErrorEmail(false)
        setErrorSenha(false)
        setters.setEmail('')
        setters.setSenha('')
        localStorage.removeItem('userEmail')
        localStorage.removeItem('userPassword')
    }


    useEffect(() => {
        localStorage.removeItem('token')
        setters.setSigned(false)
        const email = localStorage.getItem('userEmail')
        const password = localStorage.getItem('userPassword')
        if (email && password) {
            setters.setEmail(email)
            setters.setSenha(password)
            setRemainSigned(true)
        }


    }, [])



    useEffect(() => {
        if (remainSigned) {
            localStorage.getItem('userEmail')
            localStorage.getItem('userPassword')
        }
    }, [states.email, states.senha])






    return (
        <>
            <div className="h-screen">
                <div className="flex min-h-full">
                    <div
                        className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24 ">
                        {/* border-2 border-inherit */}
                        <div className="mx-auto w-full max-w-sm lg:w-96 items-center jutify-center">
                            <div className="flex flex-col justify-center items-center">
                                <img
                                    className="h-[5em] w-auto"
                                    src={`${jsonConfig.urlReact}/logo.png`}
                                    alt="Soter Tec Logo"
                                />
                                <h3 className="mt-6 text-2xl text-center font-bold tracking-tight text-gray-900">
                                    {perdeuSenha ? 'Recuperar senha do psicólogo ERP' : 'Seja bem-vindo ao psicólogo ERP'}
                                </h3>
                            </div>


                            <div className="mt-8">
                                <div className="mt-6">
                                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                        Email
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            type="email"
                                            name="email"
                                            id="email"
                                            disabled={states.isLoading}
                                            value={states.email}
                                            onChange={(e) => {
                                                const trimString = e.target.value.trim()
                                                if (trimString !== '') {
                                                    setters.setEmail(trimString)
                                                    setErrorEmail(false)
                                                } else {
                                                    setters.setEmail(trimString)
                                                    setMsgErrorEmail('O campo email é obrigatório')
                                                    setErrorEmail(true)
                                                }
                                            }}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    actionLogin()
                                                }
                                            }}
                                            autoComplete="email"
                                            placeholder="Email"
                                            className={states.isLoading ? "flex flex-row items-between justify-between bg-gray-200 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm" :
                                                errorEmail ? "flex flex-row items-between justify-between bg-red-100 appearance-none block w-full px-3 py-2 border border-red-300 rounded-md shadow-sm placeholder-red-400 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm" : "flex flex-row items-between justify-between appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400  focus-within:ring-blue-500 focus-within:border-blue-500 sm:text-sm"
                                            } />
                                        {
                                            errorEmail && <p className="text-red-500 text-xs mt-1">{msgErrorEmail}</p>
                                        }
                                    </div>
                                </div>

                                {
                                    perdeuSenha === false &&
                                    <div className="mt-6">
                                        <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                            Senha
                                        </label>
                                        <div className="mt-1">
                                            <div
                                                className={states.isLoading ? "flex flex-row items-between justify-between bg-gray-200 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm" :
                                                    errorSenha ? "flex flex-row items-between justify-between bg-red-100 appearance-none block w-full px-3 py-2 border border-red-300 rounded-md shadow-sm placeholder-red-400 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm" : "flex flex-row items-between justify-between appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400  focus-within:ring-blue-500 focus-within:border-blue-500 sm:text-sm"
                                                }
                                            >
                                                <input
                                                    type={showPassword ? 'text' : 'password'}
                                                    name="password"
                                                    id="password"
                                                    value={states.senha}
                                                    onChange={(e) => {
                                                        const trimString = e.target.value.trim()
                                                        if (trimString !== '') {
                                                            setters.setSenha(trimString)
                                                            setErrorSenha(false)
                                                        } else {
                                                            setters.setSenha(trimString)
                                                            setErrorSenha(true)
                                                        }
                                                    }}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            actionLogin()
                                                        }
                                                    }}
                                                    className={`${states.isLoading ? "bg-gray-200 text-gray-400" :
                                                        errorSenha ? "bg-red-100 text-red-400" : "text-gray-600 bg-transparent"
                                                        } w-[90%] border-none focus:ring-0  focus:border-0 input-no-focus`}
                                                    autoComplete="current-password"
                                                    placeholder="Senha"
                                                />
                                                <div className=" inset-y-0 right-0 flex items-center">
                                                    <button type="button" onClick={() => setShowPassword(!showPassword)} className=''>
                                                        {
                                                            showPassword === true ? (
                                                                <EyeIcon className="h-5 w-5 text-gray-400 " aria-hidden="true" />
                                                            ) : (
                                                                <EyeSlashIcon className="h-5 w-5 text-gray-400 " aria-hidden="true" />
                                                            )
                                                        }
                                                    </button>
                                                </div>
                                            </div>
                                            {
                                                errorSenha && <p className="text-red-500 text-xs mt-1">O campo senha é obrigatório</p>
                                            }
                                        </div>

                                        <div className="flex flex-row items-between justify-between mt-2">

                                            <p className="mt-2 text-sm text-gray-600">
                                                <button
                                                    type="button"
                                                    onClick={perdeuSenhaAction}
                                                    disabled={states.isLoading}
                                                    className={states.isLoading ? "font-medium text-blue-400 " : "font-medium text-blue-600 hover:text-blue-500"}
                                                >
                                                    Esqueceu sua senha?
                                                </button>
                                            </p>
                                            <div className="flex items-center">
                                                <input
                                                    id="remember-me"
                                                    name="remember-me"
                                                    type="checkbox"
                                                    checked={remainSigned}
                                                    onChange={() => remindSignedAction(!remainSigned)}
                                                    className="h-4 w-4 rounded border-gray-300 text-blue focus:ring-blue-600" />
                                                <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                                                    Lembrar-me
                                                </label>
                                            </div>

                                        </div>

                                    </div>
                                }

                                <div className="mt-6">
                                    <div className="flex flex-row">
                                        <button
                                            onClick={actionLogin}
                                            type="button"
                                            disabled={states.isLoading}
                                            className={states.isLoading ? "w-full flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600   focus:ring-2 focus:ring-offset-2 focus:ring-blue-500" : "w-full flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"}>
                                            {
                                                perdeuSenha ? 'Recuperar senha' : 'Entrar'
                                            }
                                        </button>
                                        {
                                            states.isLoading &&
                                            <div className="ml-2">
                                                <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-blue-500 border-solid"></div>
                                            </div>
                                        }
                                    </div>
                                    {
                                        perdeuSenha === true &&
                                        <div className="flex flex-row mt-4">
                                            <button
                                                onClick={() => {
                                                    setPerdeuSenha(false)
                                                    setErrorEmail(false)
                                                    setErrorSenha(false)
                                                    setters.setEmail('')
                                                    setters.setSenha('')
                                                    localStorage.removeItem('userEmail')
                                                    localStorage.removeItem('userPassword')
                                                }}
                                                type="button"
                                                disabled={states.isLoading}
                                                className={states.isLoading ? "w-full flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600   focus:ring-2 focus:ring-offset-2 focus:ring-blue-500" : "w-full flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"}>
                                                Voltar para login
                                            </button>
                                            {
                                                states.isLoading &&
                                                <div className="ml-2">
                                                    <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-blue-500 border-solid"></div>
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className="mt-8 flex items-center justify-center">
                                <Footer />
                            </div>
                        </div>
                    </div>
                    <div className="relative hidden w-0 flex-1 lg:block">
                        <img
                            className="absolute inset-0 h-full w-full object-cover"
                            src={jsonConfig.urlReact + '/background.jpeg'}
                            alt="" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login